import MultipleResolution from "../../../../framework/components/taskValidation/MultipleResolution";
import { FLOW } from "../../../flow/flow";

export const OnboardingTask = {
    desc: 'Onboarding',
    isMultipleResolution: true,
    validation: (participation) => {return participation.status.isActive() && !participation.hasLegacyEnrollmentForm()},
    removeValidation: (participation) => {
        const onbrdEvent = participation.events.find(ev => ev.code === 'onbrd');
        return onbrdEvent && onbrdEvent.config.canShow({instance: participation, event: onbrdEvent});
    },
    form: <MultipleResolution code='onbrd' class='overflow-scroll' title="Onboarding"/>
}


