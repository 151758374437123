import FinancialSummary from "../../../../entities/pension/FinancialSummary";
import ReportLayout from "../../../../framework/components/page/ReportLayout";
import { Definition } from "../../../../framework/infra";
import { Excel, Period } from "../../../../framework/utils";
import { renderAmountEmptyFor0 } from "../../../../framework/utils/renders";
import { RemittanceDetailService } from "../../../../services";
import { getEarningColumns } from "../DeductionsEarningsTable";
import ReportsMenu from "../ReportsMenu";

export default class YTDReport extends ReportLayout {
    reportDesc = 'Financial Summary';
    entity=FinancialSummary;

    headers = [...basicHeaders,'contributions', 'nonPensionable', 'oneTime', 'overtime', 'regular'];
    headerProps = initializeEarningsHeaders( this.props.params.employer);    
    tableSort = 'name';
    filters= {}
    params = {
        from: {
            definition: {
                type: Definition.types.PERIOD, 
                text: "From Period", 
                options: Period.getPeriods(
                    Period.getLaunchPeriod().dec(1), 
                    Period.getCurrentPeriod(), 
                    true
                ),
            }
        },
        to: {
            definition: {
                type: Definition.types.PERIOD, 
                text: "To Period", 
                options: Period.getPeriods(
                    Period.getLaunchPeriod().dec(1), 
                    Period.getCurrentPeriod(), 
                    true
                )
            }
        }
    }

    execQuery(queryParams) {
        return RemittanceDetailService.getFinancialSummaryForPeriods(queryParams.from, queryParams.to, this.props.params.employer.keyValue)
    }

    handleExport(data){
        var excelHeaders = new Excel.Headers(FinancialSummary, basicHeaders)
        excelHeaders['contributions'].name = '';
        excelHeaders['contributions'].title = 'Contributions Types';
        excelHeaders['contributions'].headers = new Excel.Headers(FinancialSummary, contributionHeaders.map(type => type.name));
        Object.assign(excelHeaders, ReportsMenu.getEarningTypesExcelHeaders(this.props.params.employer.getActiveEarningTypes()));
        const excel = new Excel(this.reportDesc)
        const ws = excel.addSheet(excelHeaders, []);
        if(data.length > 0){
            const sumRow = ws.addRow();
            sumRow.fill = {
                bgColor: {indexed: 64},
                fgColor: {theme: 0, tint: -0.1499984740745262},
                pattern: "solid",
                type :"pattern",
            };

            this.addSumRow(sumRow, excelHeaders, ws, data);
            excel.addDataRows(ws, excelHeaders, data);
        }
        
        excel.download();
    }

    addSumRow(sumRow, headers, ws, data){
        var index = headers.list.findIndex(header => header.title === 'Contributions Types');
        for(var i = index; i < ws.columns.length; i++ ){
            ws.getCell(Excel.toAddress(sumRow.number - 1, i)).value = {formula : `SUM(${Excel.toAddress(sumRow.number, i )} : ${Excel.toAddress(sumRow.number + data.length - 1, i)})`};
        }
    }
}

const basicHeaders = ['sin', 'name', 'employment.isCQ', 'ppJnDt', 'participation.status.desc', 'contributions', 'priorYearAdjustment' , 'earnings.pensionable', 'earnings.pensionableHours'];
const contributionHeaders = [{name: 'sumContributions.reg', title: 'Regular', width: 100, format: renderAmountEmptyFor0}, {name: 'sumContributions.mat', title: 'Maternity', width: 100,  format: renderAmountEmptyFor0}, 
                            {name: 'sumContributions.ltd', title: 'Long Term', width: 100,  format: renderAmountEmptyFor0}, {name: 'sumContributions.slf', title: 'Self', width: 100,  format: renderAmountEmptyFor0}, 
                            {name: 'sumContributions.vol', title: 'Voluntary', width: 100,  format: renderAmountEmptyFor0}, {name: 'sumContributions.total', title: 'Total', width: 100,  format: renderAmountEmptyFor0}]
function initializeEarningsHeaders(employer) {
    const headerProps = {
        'sin' : {width: 150},
        'name': {width: 175},
        'ppJnDt': {width: 150},
        'employment.isCQ': {width: 100},
        'priorYearAdjustment': {width: 100},
        'contributions': {
            title: 'Contributions',
            columns: contributionHeaders,
        }

    }
    Object.values(getEarningColumns(employer.getActiveEarningTypes(), false)).forEach(earningCol => headerProps[earningCol.name] = earningCol);
    return headerProps
}