import EmailResolution from "../../framework/components/taskValidation/EmailResolution";
import TaskItemCalendar from "../../framework/components/taskValidation/TaskItemCalendar";
import TaskYesNoResolution from "../../framework/components/taskValidation/TaskYesNoResolution";
import TaskMultipleChoice from "../../framework/components/taskValidation/TaskMultipleChoice";
import { FLOW } from "./flow"; 

//getDisplayConditions: pre-condition for the task to appear in the flow
const JOIN_ISSUES = [
    {value: 'issue1', label: 'Date member transferred in from another plan employer'},
    {value: 'issue2', label: 'First day of work since meeting the eligibility requirements'},
    {value: 'issue3', label: 'Employer wishes to start early'},
]

const flowHasEvent = (flow, codes) => {
    return flow.subEvent.find(x=>{
        return codes.find(y => y === x.code)
    });
}

const subEventHasReason = (flow, code, reason) => {
    return flow.subEvent.find(x=> x.code === code && x.metadata?.reason === reason);
}

const DEFAULT_DISPLAY = {
    enabled: false, //wether or not it should be disabled
    include: true, //wether or not it should appear in the list of tasks
}

export const SubEventConfig = {
    default: { 
        group: [],  
        children: [],
        getDisplayConditions: (instance, flowEvent) => {
            return {...DEFAULT_DISPLAY };
        },
    },
    reqEnrlFrm: {
        rootEvent: true,
        group: [FLOW.ONBOARDING],
        task: 'Request enrolment form.',
        saveLabel: 'Requested',
        getDisplayConditions: (instance, flowEvent) => {
            return {...DEFAULT_DISPLAY, enabled: true };
        },
        form: <TaskItemCalendar 
            message={'Has enrolment been requested?'}
            resolutionMessage={(instance, event) => {
                return 'Enrolment form has been requested.'
            }}
        />,
        children: ['rcvEnrlFrm'],
    },
    rcvEnrlFrm: {
        formReceived: true,
        group: [FLOW.ONBOARDING],
        task: 'Member Onboarded. Employer/Employee to submit enrolment form.',
        getDisplayConditions: (instance, flowEvent) => {
            return {...DEFAULT_DISPLAY, enabled: flowHasEvent(flowEvent, ['reqEnrlFrm'])};
        },
        saveLabel: 'Received',
        form: <TaskItemCalendar 
            message={'Has enrolment been received?'}
            resolutionMessage={(instance, event) => {
                return 'Enrolment form has been received.'
            }}
        />,
        children: ['issJoinEnrlFrm', 'issEnrlFrm'],
    },
    issJoinEnrlFrm: {
        group: [FLOW.ONBOARDING],
        task: 'Verify join date.',
        getDisplayConditions: (instance, flowEvent) => {
            return {...DEFAULT_DISPLAY, enabled: flowHasEvent(flowEvent, ['rcvEnrlFrm'])};
        },
        form: <TaskYesNoResolution
            getNoChoices={({instance}) => {
                return JOIN_ISSUES;
            }}
            message={<p className="info-panel">Is the Join Date = January 1st?</p>}
            resolutionMessage={(instance, event) => {
                const answer = event?.metadata?.answer ? '' : 'not';
                return `Join date is ${answer} January 1st. ${(!event?.metadata?.answer ? JOIN_ISSUES.find(x=>x.value === event?.metadata?.reason)?.label +'.': '')}`;
            }}
        />,
        children: ['waivSent'],
    },
    waivSent: {
        group: [FLOW.ONBOARDING],
        task: 'Request waiver letter.',
        getDisplayConditions: (instance, flowEvent) => {
            return {...DEFAULT_DISPLAY, 
                include: flowHasEvent(flowEvent, ['issJoinEnrlFrm']) && subEventHasReason(flowEvent, 'issJoinEnrlFrm', 'issue3'),  
                enabled: flowHasEvent(flowEvent, ['issJoinEnrlFrm']) && subEventHasReason(flowEvent, 'issJoinEnrlFrm', 'issue3'),
            };
        },
        saveLabel: 'Requested',
        form: <TaskItemCalendar
            message={'Has waiver letter been requested?'}
            resolutionMessage={(instance, event) => {
                return 'The enrolment information has been sent to the actuary.';
            }}
        />,
        children: ['waivRecv'],
    },
    waivRecv: {
        group: [FLOW.ONBOARDING],
        task: 'Waiting for waiver letter from employee.',
        getDisplayConditions: (instance, flowEvent) => {
            return {...DEFAULT_DISPLAY, 
                include: flowHasEvent(flowEvent, ['issJoinEnrlFrm']) && subEventHasReason(flowEvent, 'issJoinEnrlFrm', 'issue3'), 
                enabled: flowHasEvent(flowEvent, ['waivSent'])
            };
        },
        saveLabel: 'Received',
        form: <TaskItemCalendar
            message={'Has waiver letter been received?'}
            resolutionMessage={(instance, event) => {
                return 'The enrolment information has been sent to the actuary.';
            }}
        />,
        children: [],
    },
    issEnrlFrm: {
        group: [FLOW.ONBOARDING],
        task: 'Any critical issues preventing completion of onboarding?',
        getDisplayConditions: (instance, flowEvent) => {
            return {...DEFAULT_DISPLAY, enabled: flowHasEvent(flowEvent, ['rcvEnrlFrm'])};
        },
        form: <TaskYesNoResolution
            message={<p className="info-panel">
                Are there critical issues to resolve?
                <ul>
                    <li>Different key data: Hired Date, DOB, CQPP, Eligibility date, Join Date, Reason for Join date not Jan 1</li>
                    <li>Missing member signature on Enrolment Form</li>
                    <li>Missing member signature and Beneficiary Signature on Beneficiary Designation Form</li>
                </ul>
            </p>}
            resolutionMessage={(instance, event) => {
                const answer = event?.metadata?.answer ? 'issues ' : 'no issues ';
                return 'There are ' + answer + ' to resolve.';
            }}
        />,
        children: ['enrlInfUpd'],
    },
    enrlInfUpd: {
        group: [FLOW.ONBOARDING],
        task: 'Has the enrolment information been entered in the APP?',
        getDisplayConditions: (instance, flowEvent) => {
            return {...DEFAULT_DISPLAY, enabled: flowHasEvent(flowEvent, ['issEnrlFrm'])};
        },
        form: <TaskItemCalendar
            resolutionMessage={(instance, event) => {
                return 'The enrolment information been entered in the APP';
            }}
        /> ,
        saveLabel: 'Yes',
        children: ['enrlInfSent'],
    },
    enrlInfSent: {
        group: [FLOW.ONBOARDING],
        task: 'Send enrolment information confirmation to employee.',
        getDisplayConditions: (instance, flowEvent) => {
            return {...DEFAULT_DISPLAY, enabled: flowHasEvent(flowEvent, ['enrlInfUpd'])};
        },
        form: <EmailResolution
            emailTemplate='sendEnrollInfo' 
            templateInfo={(instance, flowEvent)=> {
                let issueEvent = flowEvent.subEvent.find(x=> x.code === 'issJoinEnrlFrm');
                return {
                    to: instance.person.defaultEmail,
                    participation: instance,
                    employments: instance.employments.all,
                    admin: instance.employments.last.employer.contacts.find(contact => contact.isAdministrator()),
                    reason: issueEvent ? JOIN_ISSUES.find(x=>x.value === issueEvent.metadata.reason)?.label : ''
                }
            }}
            resolutionMessage={(instance, event) => {
                return 'An email has been sent to the employee.';
            }}
        />,
        saveLabel: 'Sent',
        children: ['enrlInfRcv'],
    },
    enrlInfRcv: {
        group: [FLOW.ONBOARDING],
        task: 'Waiting for enrolment information confirmation from employee.', 
        getDisplayConditions: (instance, flowEvent) => {
            return {...DEFAULT_DISPLAY, enabled: flowHasEvent(flowEvent, ['enrlInfSent'])};
        },
        form: <TaskMultipleChoice
            getChoices={({instance}) => {
                return [
                    {value: 'received', label: 'Received'}, 
                    {value: 'expired', label: 'Expired after 30 days'}
                ]
            }}
            message={'Has the enrolment information confirmation been received?'}
            resolutionMessage={(instance, event) => {
                return 'The enrolment information confirmation has been received.';
            }}
        /> ,  
        saveLabel: 'Confirmation Received',
        children: ['enrlFrmAct'],
    },
    enrlFrmAct: {
        group: [FLOW.ONBOARDING],
        task: 'Send enrolment form to actuary.',
        getDisplayConditions: (instance, flowEvent) => {
            return {...DEFAULT_DISPLAY, enabled: flowHasEvent(flowEvent, ['enrlInfRcv'])};
        },
        form: <TaskItemCalendar
            message={'Has the enrolment form been sent to actuary?'}
            resolutionMessage={(instance, event) => {
                return 'The enrolment information has been sent to the actuary.';
            }}
        />,
        saveLabel: 'Sent',
    },
}


// notELigRedPen: {
//     task: 'Member is NOT Eligible to unreduced pension while continuing to work. Inform {employments.last.employer.code} to continue deductions',
//     group: [FLOW.ACT60_NOT_Eligible],
//     form: <EmailResolution 
//         emailTemplate='notEligibleTemplate' 
//         //templateInfo={getJurisdictionTemplateInfo}
//     />,
// },