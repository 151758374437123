import React, { useState } from 'react';
import { Button } from '../../../framework/components';
import { Ref, Definition } from '../../../framework/infra';
import { Modal, Row, Title } from '../../../framework/containers';
import { Excel } from '../../../framework/utils';
import Loading from '../../../components/containers/Loading';
import { Icon } from '../../../framework/controls';
import { ActionList } from '../../../services/pension/RemittanceService';

const PreUpload = ({summary, onHide, hideLabel = 'Close', onConfirm, confirmLabel = 'Ok'}) => {
    
    const [isLoading, setIsLoading] = useState(false);
    const [details, setDetails] = useState(summary);

    const handleConfirm = () => {
        setIsLoading(true);
        if(onConfirm) onConfirm(details);
        if(onHide) onHide();
    }

    const download = () => {
        const data = Report.getData(details);
        const HEADER_COLS = ['rowId', 'message'];
        const excelHeaders = new Excel.Headers(Report, HEADER_COLS);
        const excel = new Excel('UploadSummary');
        excel.addSheet(excelHeaders.list, data);
        excel.download();
    }

    return isLoading ? <Loading /> :
        <>
        <Title
            title="Pre-Upload Summary: Confirming this prompt will create permanent changes."
            onHide={onHide}
        />
        <div className='transfer-detail'>
            {details.length ? details
            .sort(byRelevance)
            .map((detail) => 
                <div className={"transfer-card"} key={detail.rowId}>
                    <div className="transfer-cols">
                        <div className="transfer-card-col">
                            { detail.severity === 'e' ?
                                <div className='s-error'><Icon icon="exclamation-circle"/> <span className="bold">Error - {detail.title}</span></div> :
                                <div className='s-warning'><Icon icon="exclamation-triangle"/> <span className="bold">Warning - {detail.title}</span></div>
                            }
                            <ul>
                                {detail.messages.map((x, i) => { 
                                    return <>
                                        <li key={detail.rowId +'-'+i}>{x.text}</li> 
                                        {x.action?.text && x.action?.text(x.action, detail) &&
                                            <li key={detail.rowId +'-'+i+'-r'} className="bold">{x.action?.text(x.action, detail)}</li>}
                                    </>  
                                })}
                            </ul>
                        </div>
                        <div className={"transfer-card-col"}>
                            {detail.messages.map((message) => {
                                const actionsProps = {
                                    actionContext: { get: details, set: setDetails }, 
                                    action: message.action,
                                    detail: detail,
                                    key: detail.rowId + '-action'
                                };

                                if (message.action) {
                                    switch (true) {
                                        case message.action instanceof ActionList.ActionPickParticipation:
                                            return <ActionPickParticipation {...actionsProps}/>
                                        case message.action instanceof ActionList.ActionSkip:
                                            return <ActionSkipEmploymentCreation {...actionsProps}/>
                                        default:
                                            return null;
                                    }
                                }
                            })}
                        </div>
                    </div>
                </div>) 
                : <span>No details.</span>}
        </div>
        <Row className='justify-content-end button-row mt15'> 
            <Button key="export" className='btn-secondary' onClick={download}>Export</Button>
            {onConfirm? 
            <Button 
                key="onConfirm" 
                className='btn-secondary' 
                onClick={handleConfirm}>
                    {confirmLabel}
            </Button> : null}
            {onHide? <Button key="ok" className='btn-secondary' onClick={onHide}>{hideLabel}</Button> : null}
        </Row>
    </> 


}
export default PreUpload;

class Report extends Ref {

    static getData(summary) {
        return summary.reduce((rows, row) => {
			const newRow = new Report({ rowId: row.rowId, message: row.message, severity: row.severity, severityPonderation: getSeverityPonderation(row.severity) });
			rows.push(newRow);
            rows.sort((a,b) => a.severityPonderation > b.severityPonderation ? -1 : 1);
            return rows;
        }, [])
    }
    
    static definitions = {
        rowId: { type: Definition.types.NUMBER, text: 'Row Id' },
		message: { type: Definition.types.STRING, text: 'Message'},
        severity: { type: Definition.types.STRING },
        severityPonderation: { type: Definition.types.NUMBER , text: 'Severity'},
    }
}

const getSeverityPonderation = (severity) => {
    switch (severity) {
        case 'e': 
            return 3;
        case 'w': 
            return 2;
        case 't':
        case 'i':
        default:
            return 1;
    }
}

const ActionPickParticipation = ({ actionContext, action, detail }) => {

    const [selecting, setSelecting] = useState(false);
    
    const byActionId = (x) => x.rowId === detail.rowId && x.messages.find(y=> y.id === action.id);
    const allItems = [...actionContext.get]
    const item = allItems.find(byActionId);

    const handleClick = (ppNo) => {
        item.targetPpNo = ppNo;
        actionContext.set([...allItems.filter((x=> !byActionId(x))), item]);
        setSelecting(false);
    }
    return <>
        <Button className='btn-primary btn-icon' onClick={()=> setSelecting(true)}>Choose Participation</Button> 
        {selecting && <Modal className="w-60 modal-bg-color">
            <>
                <Title
                    title="Choose Participation"
                    onHide={() => setSelecting(false)}
                />
                <div className='transfer-detail'>
                    {action.params.employments.sort((a,b)=> Number(a.participation.no) > Number(b.participation.no)? -1:1 ).map((employment) => 
                        <div className={"transfer-card"} key={employment.keyValue}>
                            <div className="transfer-cols">
                                <div className="transfer-card-col">
                                    <div><span className="bold">Participation #{employment.participation.no}</span></div>
                                    <div><span className="bold">Current status: </span>{employment.statusDesc}</div>
                                    <div><span className="bold">Hire date: </span>{employment.hiredDate !== '' ? employment.hiredDate : 'None'}</div>
                                    <div><span className="bold">Join date: </span>{employment.participation.joinDt !== '' ? employment.participation.joinDt : 'None'}</div>
                                </div>
                                <div className={"transfer-card-col"}>
                                    <Button 
                                        className={'btn-primary btn-icon ' + (item?.targetPpNo === employment.participation.no? 'btn-ghost' : '')} 
                                        onClick={() => { if(item?.targetPpNo !== employment.participation.no) handleClick(employment.participation.no)}}>
                                            {item?.targetPpNo !== employment.participation.no ? 'Select' : 'Selected'}
                                        </Button> 
                                </div>
                            </div>
                        </div>)}

                </div>
            </> 
            </Modal>
        }
    </>
}

const ActionSkipEmploymentCreation = ({ actionContext, action, detail }) => {

    const byActionId = (x) => x.rowId === detail.rowId && x.messages.find(y=> y.id === action.id);
    const allItems = [...actionContext.get];
    const item = allItems.find(byActionId);
    
    const handleClick = () => {
        let index = item.messages.findIndex(x=>x.action instanceof ActionList.ActionSkip);
        item.messages[index].action.resolution = !item.messages[index].action.resolution;
        actionContext.set([...allItems.filter((x=> !byActionId(x))), item]);
    }

    if (action.resolution) {
        return <Button className='btn-primary btn-icon btn-ghost btn-icon-with-text' onClick={handleClick} >
            <Icon icon={"undo"} />
            Undo
        </Button> 
    }
    return <Button className='btn-primary btn-icon' onClick={handleClick} >Skip Employment {action.params.isUpdate ? 'Update' : 'Creation'}</Button> 
}

const byRelevance = (a,b) => {
    const sev1 = getSeverityPonderation(a.severity);
    const sev2 = getSeverityPonderation(b.severity);
    if (sev1 === sev2) {
        const aHasActions = a.messages.find(x=>x.action);
        const bHasActions = b.messages.find(x=>x.action);

        if (aHasActions && bHasActions)
            return a.rowId < b.rowId ? -1 : 1 //sort by rowId 3.
        else { 
            return aHasActions ? -1 : 1 //sort by having action 2.
        } 
    } 
    return sev1 > sev2 ? -1 : 1 //sort by severity 1.
}