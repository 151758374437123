import React from 'react'
import { moment } from '../../../../framework/utils/helper'
import { Component } from '../../../../framework/components'
import { Row } from '../../../../framework/containers'
import { Date, Rate, Text, Choice } from '../../../../framework/controls'
import { Plan } from '../../../../entities'
import { EmployerService , AdjustmentService } from '../../../../services'

export default class EmployerContributionRateChange extends Component {
    load() {
        return EmployerService.getEmployers().then(employers => {
            const params = {
                employers: employers,
                rate: this.props.rate || 0,
                effDate: this.props.effDate || moment().date(1).format('YYYY-MM-DD'),
                retroDate: this.props.retroDate || moment().month(0).date(1).format('YYYY-MM-DD'),
                comment: this.props.comment,
                jurisdiction: this.props.jurisdiction
            }
            return params
        })
    }
    
    view() {
        const { jurisdiction, effDate, retroDate, rate, comment } = this.state
        return <Row className={this.props.classname}>
            <Choice name='jurisdiction' label='Jurisdiction' options={Plan.definitions.jurisdiction.options} value={jurisdiction} onChange={this.handleChange.bind(this, 'jurisdiction')} searcheable={false}></Choice>
            <Date name='effDate' label='Effective Date' onChange={this.handleChange.bind(this, 'effDate')} className='ml-3 ' value={effDate}></Date>
            <Date name='retroDate' label='Changed Date' onChange={this.handleChange.bind(this, 'retroDate')} className='ml-3 ' value={retroDate}></Date>
            <Rate name='rate' label='New Rate' onChange={this.handleChange.bind(this, 'rate')} className='ml-3' value={rate}></Rate>
            <Text name='comment' label='Comment' onChange={this.handleChange.bind(this, 'comment')} value={comment} className='pl-3 stretch'/>
        </Row>
    }

    handleChange(field, value) {
        this.setState({[field]: value})
        const adjustments = this.createAdjustments(Object.assign({}, this.state, {[field]: value}))
        this.props.onChange(adjustments)
    }

    createAdjustments(params) {
        const { employers, effDate, retroDate, rate, jurisdiction, comment } = params
        return employers.getEmployersForJurisdiction(jurisdiction).reduce((adjustments, er) => {
            const adjs = AdjustmentService.createEmployerContributionRateChangeAdjustments(er, effDate, retroDate, rate, 0.047, comment)
            return adjustments.concat(adjs)
        }, [])
    }
}
