
import React, {useEffect, useState} from "react"
import { Button } from '../../../framework/controls'
import { Row, Col, Modal } from '../../../framework/containers'

import { AdjustmentService, EmployerService } from '../../../services'
import { Adjustment } from '../../../entities'
import AdjustmentList from './AdjustmentList'
import AdjustmentForm from './AdjustmentForm'
import AdjustmentTemplate from './AdjustmentTemplate'
import useNotification from "../../../hooks/useNotification"
import { Tabs } from "../../../framework/containers"
import Loading from "../../../components/containers/Loading"

const AdjustmentsAdmin = ({ onChange }) => {
    const [adjustments, setAdjustments] = useState([]);
    const [adjustmentsExport, setAdjustmentsExport] = useState();
    const [employers, setEmployers] = useState();
    const [selected, setSelected] = useState();
    const [isOpenTemplate, setIsOpenTemplate] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [activeTab, setActiveTab] = useState('contribution');
    const { addMessage } = useNotification();

    useEffect(() => {
        let isMounted = true;

        const getData = async () => {
            const emps = await EmployerService.getAll();
            const adjs = await AdjustmentService.getAllAdjustments({ load: true });
            return {emps, adjs};
        }

        getData().then(({emps, adjs}) => {
            if (isMounted) {
                setAdjustments(adjs);
                setEmployers(emps);
                setIsLoading(false);
            }
        });

        return () => { isMounted = false };
    }, []);

    const handleNew = () => setSelected(new Adjustment());

    const handleSelect = (adjustment) => setSelected(adjustment);

    const handleCancel = () => setSelected(null);

    const handleOpenTemplate = () => setIsOpenTemplate(true);

    const handleCancelTemplate = () => setIsOpenTemplate(false);
    
    const handleSave = (adjustments) => {
        setIsLoading(true);
        adjustments = [].concat(adjustments);
        
        AdjustmentService.updateAll(adjustments).then(updatedAdjustments => {
            addMessage('Adjustment successfully saved', 'success');

            return EmployerService.getEmployers().then(employers => EmployerService.loadAdjustments(employers, {refresh: true})).then(employers => {
                employers.forEach(employer => {
                    employer.adjustments.assignAll(employer.code, employer.employees)
                //       employer.remittances.assignAll(employer.employees, employer.solvencies, employer.plan.historicRates, employer.adjustments, employer.earningTypes)
                })
                setIsOpenTemplate(false);
                setSelected(null);
                if(onChange) onChange(adjustments);
                setIsLoading(false);
            });
        });
    }

    const handleChangeAdjustmentForm = (adjustment, isDelete = false) => {
        setSelected(null);
        setIsLoading(true);
        AdjustmentService.invalidateCache();
        AdjustmentService.getAll().then((adjustments) => {
            addMessage(`Adjustment successfully ${isDelete ? 'deleted' : 'saved'}`, 'success');
            setIsOpenTemplate(false);
            setAdjustments(adjustments);
            if (onChange) {
                onChange(adjustment);
            }
            setIsLoading(false);
        });
    }

    return (
        <>
            {!isLoading && !selected ? (
                <>
                    <Row className="mt-2">
                        <Col left span="9">
                            <Row>
                                <Tabs
                                    initial={activeTab}
                                    onChange={(tab) => setActiveTab(tab)}
                                    className="h-100"
                                >
                                    <Tabs.Tab
                                        name="contribution"
                                        title="Contribution"
                                    />
                                    <Tabs.Tab
                                        name="earning"
                                        title="Earnings/Hours"
                                    />
                                </Tabs>
                                <Button onClick={handleNew} className="mt-2">
                                    New Adjustment
                                </Button>
                                <Button
                                    onClick={handleOpenTemplate}
                                    className="mt-2"
                                >
                                    Adjustments Templates
                                </Button>
                            </Row>
                        </Col>
                        <Col right>
                            <Button
                                key="export"
                                type="link"
                                onClick={() => adjustmentsExport()}
                            >
                                Export
                            </Button>
                        </Col>
                    </Row>
                    <AdjustmentList
                        adjustments={adjustments}
                        selected={selected && selected.id}
                        onSelect={handleSelect}
                        setExportFunction={setAdjustmentsExport}
                        activeTab={activeTab}
                    />
                </>
            ) : (
                <Loading />
            )}
            {selected && (
                <Modal className="h-100 w-80">
                    <AdjustmentForm
                        employers={employers}
                        adjustment={selected}
                        onSave={handleChangeAdjustmentForm}
                        onDelete={handleChangeAdjustmentForm}
                        onCancel={handleCancel}
                    />
                </Modal>
            )}
            {isOpenTemplate && (
                <Modal className="h-80 w-80" id="adjustments-templates-modal">
                    <AdjustmentTemplate
                        onSave={handleSave}
                        onCancel={handleCancelTemplate}
                    />
                </Modal>
            )}
        </>
    );
}

export default AdjustmentsAdmin;