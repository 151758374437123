import React from 'react'
import { Component, EInput, PageSubTitle } from '../../framework/components'
import { Row, Modal } from '../../framework/containers'
import { Button } from '../../framework/controls'
import EmploymentForm from './EmploymentForm'
export default class EmploymentSummary extends Component {
	view() {
		const { view, edit } = this.state
		const { employer, employment, readOnly } = this.props

		return <>
			<Row className="input-spacing-3 mt-2">
				<EInput name='effDt' instance={employment.event} readOnly={true}/>
				<EInput name='desc' label='Status' instance={employment.event} readOnly={true} />
				<EInput name="hiredDate" instance={employment} readOnly={true}/>
				<EInput name="noEmp" instance={employment} readOnly={true}/>
				<EInput name="isN" instance={employment} readOnly={true}/>
				<EInput name="isTP" instance={employment} readOnly={true}/>
			</Row>
			<Row className='pt-4 justify-content-end'>
				{!readOnly && <Button key='details' className='btn-secondary' onClick={this.handleDetails.bind(this)}>Details</Button>}
				{!readOnly && <Button key='edit' className='btn-secondary' onClick={this.handleEdit.bind(this)}>Edit</Button>}
			</Row>
			{(view || edit ) && <Modal id='employment' className='h-100 w-80 modal-bg-color'>
				<EmploymentForm readOnly={!edit} employer={employer} ee={employment} onCancel={this.handleCancel.bind(this)} onSave={this.handleUpdate.bind(this)} notify={this.props.notify}/> {/**Send person to update otherwise when we cancel we lose the data */}
			</Modal>}
		</>
	}

	handleDetails() { this.setState({view: true}) }
	handleCancel() { 
		this.setState({view: false, edit: false}) 
		if(this.props.onCancel) this.props.onCancel()
	}
	handleEdit() { this.setState({view: false, edit: true}) }
	handleUpdate() { 
		this.setState({view: false, edit: false})
		if(this.props.onUpdate) this.props.onUpdate() 
	}
	
}