import { Ref, Definition }  from '../../framework/infra'
import { concatStr } from '../../framework/utils/helper'
export default class Phone extends  Ref {
    get desc() {
        return concatStr(this.usage,': ') + concatStr(this.number)
    }
}

Phone.definitions = {
    usage: {
        type: Definition.types.CHOICE,
        options: [
            { key: '', text: '', text_fr: ''},
            { key: 'cell', text: 'Cell', text_fr: 'Mobile'},
            { key: 'home', text: 'Home', text_fr: 'Maison'},
            { key: 'work', text: 'Work', text_fr: 'Travail'},
            { key: 'fax', text: 'Fax', text_fr: 'Telecopieur'},
            { key: 'other', text: 'Other', text_fr: 'Autre'}
        ],
        text: 'Usage',
        text_fr: 'Usage',
    },
    number: {
        type: Definition.types.STRING,
        text: 'Phone Number',
        text_fr: 'Telephone'
    }
}
