import { Service, Persistence } from '../../framework'
import { Membership, MemberSummary, Person } from '../../entities'
import { EmploymentService, PersonService }  from '..'
import { MembershipBusiness } from '../../business'
import { BeneficiaryService, ParticipationService } from '.';

class MembershipService extends Service {
    constructor() {
        super(Membership, "Membership");
        this.persistences = {
            memberSummary: new Persistence(MemberSummary),
        };
    }

    getMembership(id, options) {
        return this.persistence.callApi("membership_GetMembership", { id }).then((mem) => {

            let member = new Membership(mem);
            if (!member.person?.id) member.person.id = id;
           
            return this.load(member, { ...options, includeEmployments: true}).then((loadedMember) => {
                return this.init(loadedMember);
            });
        });
    }

    getAllMemberships(options) {
        return this.persistence.callApi("membership_GetMemberships", options).then(resp => console.log('memberships:', resp));
    }

    getMemberships(options) {
        return PersonService.getAll().then(() => this.getAll(options, true));
    }

    getMembershipsForEmployer(employerId, options) {
        return this.getBy(
            "GetMembershipsForEmployer",
            { id: employerId },
            options
        );
    }

    getMembersSummary(options = {}) {
        const promise = () =>
            this.persistences.memberSummary.getAll(
                "membership_GetMembersSummary"
            );
        return this.useCache("_peopleSumary", promise, options.refresh);
    }

    async getNewMembersReport(startDate, endDate) {
        const action = "GetNewMemberReport";
        return this.getBy(action, {startDate, endDate}, {includeEmployments: true});
    }

    async load(membership, options = {}) {
        let person =  await PersonService.get(membership.person.id);

        let employments = options.includeEmployments ? await EmploymentService.getMemberEmployments(membership.person.id) : [];
        membership.person = person;
        membership.participations.forEach((pp) => {
            pp.membership = membership;
            if (options.includeEmployments) {
                pp.employments.pushList(employments.filter((e) => e.keysValues.participation === pp.keyValue));
            }
            pp.payout.distribution.forEach((distrib) => {
                distrib.account = membership.refundAccounts.find(
                        (account) => account.keyValue === distrib.account.keyValue) || distrib.account;
            });
        });
        
        if(membership.beneficiaries.length > 0) {
            const benefs = await BeneficiaryService.get(membership.person.id);
            for (let participation of membership.participations.all) {
                participation.beneficiaries.reset();
                participation.beneficiaries.pushList(benefs.filter(x=> x.participationNo === participation.no));
            }
        }
        membership.participations.resetMapKeys(); //since we only set the membership after the map is created then the keys are incomplete, this methods reset them

        for (let participation of membership.participations.all) {
            await ParticipationService.load(participation, options);
        }
        return membership;
    }

    init(membership) {
        return MembershipBusiness.validate(membership);
    }
}

const singelton = new MembershipService()
export default singelton
