import React, { useState } from 'react';
import { Table, Button } from '../../controls';
import { Ref, Definition } from '../../../framework/infra';
import { Row } from '../../containers';
import { Excel } from '../../utils';
import Loading from '../../../components/containers/Loading';

const SummaryTable = ({summary, onHide, hideLabel = 'Close', onConfirm, confirmLabel = 'Ok'}) => {
    const HEADER_COLS = ['rowId', 'message']
    const [isLoading, setIsLoading] = useState(false);

    const data = Report.getData(summary);
    const columns = new Table.Headers(Report, HEADER_COLS);
    columns['rowId'].width = 90;

    const handleSeverity = (row) => { 
        var severity = row.getData().severity;
		if (severity === 'w') row.getElement().className += ' warning';
		else if (severity === 't') row.getElement().className += ' todo';
		else if (severity === 'e') row.getElement().className += ' error';
    }

    const handleConfirm = () => {
        setIsLoading(true);
        if(onConfirm) onConfirm();
        if(onHide) onHide();
    }

    const download = () => {
        const excelHeaders = new Excel.Headers(Report, HEADER_COLS);
        const excel = new Excel('UploadSummary');
        excel.addSheet(excelHeaders.list, data);
        excel.download();
    }

    return isLoading ? <Loading /> :
        <>
        <Table id="summary-table" 
            className="mih-400"
            data={data} 
            columns={columns} 
            rowFormatter={handleSeverity}
        />
        <Row className='justify-content-end button-row mt15'> 
            <Button key="export" className='btn-secondary' onClick={download}>Export</Button>
            {onConfirm? 
            <Button 
                key="onConfirm" 
                className='btn-secondary' 
                onClick={handleConfirm}>
                    {confirmLabel}
            </Button> : null}
            {onHide? <Button key="ok" className='btn-secondary' onClick={onHide}>{hideLabel}</Button> : null}
        </Row>
    </> 


}
export default SummaryTable;

class Report extends Ref {

    static getSeverityPonderation(severity) {
        switch (severity) {
            case 'e': 
                return 3;
            case 'w': 
                return 2;
            case 't':
            case 'i':
            default:
                return 1;
        }
    }

    static getData(summary) {
        return summary.reduce((rows, row) => {
			const newRow = new Report({ rowId: row.rowId, message: row.message, severity: row.severity, severityPonderation: this.getSeverityPonderation(row.severity) });
			rows.push(newRow);
            rows.sort((a,b) => a.severityPonderation > b.severityPonderation ? -1 : 1);
            return rows;
        }, [])
    }
    
    static definitions = {
        rowId: { type: Definition.types.NUMBER, text: 'Row Id' },
		message: { type: Definition.types.STRING, text: 'Message'},
        severity: { type: Definition.types.STRING },
        severityPonderation: { type: Definition.types.NUMBER , text: 'Severity'},
    }
}