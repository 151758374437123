import { Definition, RefEnum } from '../../../framework/infra'

export default class ParticipationStatus extends RefEnum {
    
    isClose() { return ['TRM', 'PEN', 'DFR', 'DED', 'CAN'].includes(this.key) }
    isIneligible() { return this.key === 'NEG' }
    isPotential() { return this.key === 'POT' || this.isIneligible() }
    isOnlyPotential(){ return this.key === 'POT'}
    isActive() { return this.key === 'ACT' }
    isTerminated() { return ['TRM', 'DED'].includes(this.key) }
    isDeceased() { return this.key === 'DED'}
    isReceivingPension() { return this.key === 'PEN' }
    isPensionSuspended() { return this.key === 'SUP' }
    isDeferred() { return this.key === 'DFR' }
    isEligible() { return this.isActive() || this.key === 'ELI' }
    isEligiblePeriod() { return this.key === 'ELI'}
    isCancelled() { return this.key === 'CAN' }
    shouldShowMessage() {
        return !this.isPotential()
            && !this.isCancelled()
            && !this.isIneligible();
    }
    shouldShowTask(){
        return !this.isPotential()
        && !this.isCancelled()
        && !this.isIneligible()
        && !this.isTerminated()
        && !this.isReceivingPension()
        && !this.isPensionSuspended()
    }


    static types = {
        POT: new ParticipationStatus('POT', 'Not Enrolled'),
        ELI: new ParticipationStatus('ELI', 'Eligible'),
        ACT: new ParticipationStatus('ACT', 'Enrolled'),
        PEN: new ParticipationStatus('PEN', 'Pensioner'),
        SUP: new ParticipationStatus('SUP', 'Suspended'),
        TRM: new ParticipationStatus('TRM', 'Participation Terminated'),
        DFR: new ParticipationStatus('DFR', 'Deferred'),
        NEG: new ParticipationStatus('NEG', 'Not Eligible'),
        CAN: new ParticipationStatus('CAN', 'Cancelled'),
        DED: new ParticipationStatus('DED', 'Deceased')
    }
    static default = new ParticipationStatus('', '')
}
