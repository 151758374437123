import React from 'react'
import { Form } from 'react-bootstrap'

export default class Text extends React.Component {
    handleChange(event){
        this.props.instance[this.props.realName] = event.target.value
        if(this.props.onChange) this.props.onChange(event)
        this.setState({touch:true })
    }

    render() {
        const { name, required, className, variant, placeholder, instance, realName, readOnly, disabled, maxLength, hint } = this.props
       
        return (
            <>
            <Form.Control 
                className={className}
                as={variant}
                required={required}
                name={name}
                key={name}
                value = {instance[realName]}
                onChange={this.handleChange.bind(this)}
                placeholder={placeholder}
                readOnly={readOnly}
                disabled={disabled}
                onClick={this.props.onClick}
                maxLength={maxLength}
            />
            {hint && <Form.Text muted>{hint}</Form.Text>}
            </>
        )
    }
}
