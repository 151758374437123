

import EmployersPage from './views/employer/EmployersPage'
import EmploymentsPage from './views/employee/EmploymentsPage'
import MembersPage from './views/member/MembersPage'
import ReportsPage from './views/admin/ReportsPage'
import AdjustmentsAdmin from './views/pension/adjustment/AdjustmentsAdmin'
import PeriodicRatesList from './views/pension/PeriodicRatesList'
import Config from './views/admin/Config'
import Refresh from './views/application/Refresh'
import MemberUpload from './views/member/MembersUpload'
import EmployerPage from './views/employer/EmployerPage'
import RemittanceDetailsPage from './views/financial/remittance/RemittanceDetailsPage'

const routes = {
    pages: {
        employers: {
            path: "/employers",
            key: "employers",
            text: "Employers",
            Page: EmployersPage,
            access: "viewEmployers",
        },
        employer: {
            path: "/employer/:employerCode",
            hide: true,
            key: "employer",
            text: "Employer",
            Page: EmployerPage,
            access: "viewEmployers",
            children: [
                {
                    path: "/remittance/:period",
                    Page: RemittanceDetailsPage,
                    key: "employer-remittance-detail",
                    access: "viewEmployers",
                },
                {
                    path: "/remittances",
                    Page: EmployerPage,
                    key: "employer-remittances",
                    access: "viewEmployers",
                },
                {
                    path: "/payments",
                    Page: EmployerPage,
                    key: "employer-payments",
                    access: "viewEmployers",
                },
                {
                    path: "/employees",
                    Page: EmployerPage,
                    key: "employer-employees",
                    access: "viewEmployers",
                },
                {
                    path: "/contacts",
                    Page: EmployerPage,
                    key: "employer-contacts",
                    access: "viewEmployers",
                },
                {
                    path: "/details",
                    Page: EmployerPage,
                    key: "employer-details",
                    access: "viewEmployers",
                },
                {
                    path: "/adjustments",
                    Page: EmployerPage,
                    key: "employer-adjustments",
                    access: "viewEmployers",
                },
                {
                    path: "/earningTypes",
                    Page: EmployerPage,
                    key: "employer-earningTypes",
                    access: "viewEmployers",
                },
                {
                    path: "/report",
                    Page: EmployerPage,
                    key: "employer-report",
                    access: "viewEmployers",
                },
            ],
        },
        members: {
            path: "/members",
            key: "members",
            text: "Members",
            Page: MembersPage,
            access: "viewMembers",
        },
        reports: {
            path: "/reports",
            key: "reports",
            text: "Reports",
            Page: ReportsPage,
            access: "viewReports",
        },
        adjustments: {
            path: "/adjustments",
            key: "adjustments",
            text: "Adjustments",
            Page: AdjustmentsAdmin,
            access: "viewAdjustments",
        },
        rates: {
            path: "/rates",
            key: "rates",
            text: "Rates",
            Page: PeriodicRatesList,
            access: "viewRates",
        },
        config: {
            path: "/config",
            key: "config",
            text: "Configuration",
            superOnly: true,
            Page: Config,
            access: "viewSetup",
        },
        import: {
            path: "/import",
            key: "import",
            text: "Import",
            Page: MemberUpload,
            access: "viewImport",
        },
        employees: {
            path: "/employees",
            key: "employees",
            text: "Employees",
            hide: true,
            Page: EmploymentsPage,
            access: "viewEmployees",
        },
        refresh: {
            path: "/refresh",
            key: "refresh",
            text: "Refresh",
            hide: true,
            Page: Refresh,
        },
        default: {
            path: "/",
            key: "default",
            text: "Employers",
            hide: true,
            Page: EmployersPage,
            access: "viewEmployers",
        },
    },
};
export default routes;