import { Ref, Definition } from '../../framework/infra'
import { round, sum, map, moment, add } from '../../framework/utils/helper'
import { Period } from '../../framework/utils'
import RemittanceDistribution from './RemittanceDistribution'
import RemittanceDetail from './RemittanceDetail'
import Remittances from './Remittances'
import RemittanceStatus from './RemittanceStatus'
import { YECertificationEvents } from './yeCertification/YECertificationEvent'

const { PERIOD, AMOUNT, DATE, BOOLEAN, STRING } = Definition.types

export default class Remittance extends Ref {
    get regular() { return this.contributions.reg }
    get maternity() { return this.contributions.mat }
    get longTerm() { return this.contributions.ltd }
    get self() { return this.contributions.slf }
    get voluntary() { return this.contributions.vol }
    get deductions() { return this.contributions.deductions }

    get eeAdjustments() { 
        return sum(this.adjustments, 'employeeReg') 
            + sum(this.adjustments, 'employeeLtd') 
            + sum(this.adjustments, 'employeeMat') 
            + sum(this.adjustments, 'employeeSlf') 
            + sum(this.adjustments, 'employeeVol'); 
    }
    get erAdjustments() { return sum(this.adjustments, 'employerContribution') }
    get volAdjustments() { return sum(this.adjustments, 'voluntary') }
    get intAdjustments() { return sum(this.adjustments, 'interest') }
    get solAdjustments() { return sum(this.adjustments, 'solvency') }

    get eeAdjustedContribs() { return round(this.deductions + this.eeAdjustments) }
    get erAdjustedContribs() { return round(this.erContribs + this.erAdjustments) }
    get volAdjustedContribs() { return round(this.voluntary + this.volAdjustments) }
    get solAdjusted() { return round(this.solvency + this.solAdjustments) }
    get intAdjusted() { return round(this.interest + this.intAdjustments) }

    get totalPayments() { return sum(this.payments.nonRejectedPayments.all, 'amount') }
    get balance() {
        return round(
            this.prevBalance
            + this.total 
            - this.totalPayments
            + (this.appliedCredit + this.creditUsed) // cumulative remaining credit
        )
    }

    get appliedCredit() {
        if (this.isBeforeTotalOwingStartPeriod()) return 0;
        return this.adjustments.filter(adj => adj.type.config.isStartCredit).reduce((total, adj) => add(adj.total, total), 0);
    }

    get total() {
        const adjustments = this.validated && this.isBeforeCreditCalcsStartPeriod()
            ? this.totalAdjustments
            : this.getAdjustmentsUsed();

        return sum([
            this.deductions,
            this.voluntary,
            this.erContribs,
            adjustments,
            (this.solvency || 0),
            this.interest,
        ].map(x=>({amount: x})), 'amount'); 
    }
    get totalOwing() { 
        return this.isBeforeTotalOwingStartPeriod() ? this.balance : round(this.finalDistrBalance.balance - round(this.totalNegativeCredit + this.creditUsed));
    }

    get validated() { return this.status.isValidated()}
    get historicRates() { return this.employer.plan.historicRates }
    get rates() { return this.historicRates.getRatesAtPeriod(this.period)}

    hasOverPayment() {
        return this.finalDistrBalance.eeBal < 0 || this.finalDistrBalance.erBal < 0 || this.finalDistrBalance.volBal < 0
            || this.finalDistrBalance.solBal < 0 || this.finalDistrBalance.intBal < 0;
    }

    hasZeroNonEmployeeBalance() {
        return this.finalDistrBalance.erBal <= 0 && this.finalDistrBalance.solBal <= 0 && this.finalDistrBalance.intBal <= 0;
    }

    // Because we don't want to load the full list of adjustments, we need to get the total adjustments used, 
    // while only getting total of non-credit adjustments + actual credit used
    getAdjustmentsUsed() {
        //make credit used value a negative to offset contributions
        return round((this.totalAdjustments - (this.creditUsed)) - this.appliedCredit + (-this.creditUsed));
    }

    isOpen() { 
        const today = moment();
        const lastDayOfNextPeriod = moment(this.period.inc().dateEndPeriod).endOf('day');
        return !this.isLegacy() && (!this.validated || !today.isAfter(lastDayOfNextPeriod)) 
    } 
    isClose() { return !this.isOpen() }
    isCurrent() { return Period.getCurrentPeriod().isSame(this.period)}
    isDue() { return Period.getCurrentPeriod().dec().isSame(this.period) }
    isOverdue() { return Period.getCurrentPeriod().dec(2).isSame(this.period) }
    isSuccessfull() { return this.getDetailsInError().length === 0 }
    isEmpty() {return this.deductions === 0}
    isLegacy() { return this.period.isBefore(Period.getLaunchPeriod()) }
    isBeforeCreditCalcsStartPeriod() { return this.period.isBefore(Period.getCreditCalcStartPeriod()) }
    isBeforeTotalOwingStartPeriod() { return this.period.isBefore(Period.getTotalOwingStartPeriod()) }
    getDetailsInError() { return this.details && this.details.filter(detail => detail.message && detail.message.isError()) }
    getDetailsInWarning() { return this.details && this.details.filter(detail => detail.message && detail.message.isWarning()) }
    
    assignEarningTypes(earningTypes) { this.details.forEach(detail => detail.earnings.assignEarningTypes(earningTypes)) }
    hasPaymentBeforeCreditAdjustment(adjustment) {
        return this.payments.all.find(payment => {
            const paymentReceived = new Period(payment.rcvDate);
            const adjustmentReportedDate = new Period(adjustment.rts);
            return paymentReceived.isBefore(adjustmentReportedDate);
        })
    }

    static definitions = {
        employer: { ref: require('../employment/Employer'), text: 'Employer' },
        period: { type: PERIOD, text: 'Period' },
        yeCertificationEvents: { ref: YECertificationEvents },
        contributions: {ref: require('./contributions/Contributions'), text: 'Contributions', text_fr: 'Contributions' },
        payments: { ref: require('../financial/trustee/Payments'), text: 'Payments', text_fr: 'Payments' },
        
        importedDate: { type: DATE, text: 'Imported Date', },
        prevBalance: { type: AMOUNT, text: 'Previous Balance' },
        prevTotalOwing: { type: AMOUNT, text: 'Previous Total Owing' },
        solvency: { type: AMOUNT, text: 'Solvency', text_fr: 'Solvency' },
        interest: { type: AMOUNT, text: 'Interest', text_fr: 'Intérêt' },
        erContribs: { type: AMOUNT, text: 'Employer Contributions'},
        totalAdjustments: { type: AMOUNT,text: 'Adj. Contributions',text_fr: 'Adjustments' },
        totalNegativeCredit: { type: AMOUNT, text: "Available credit" },
        appliedCredit: { abstract: true, type: AMOUNT, text: "Original credits in period" },
        creditUsed: { type: AMOUNT, text: 'Credit Used' },
        shouldRecalculate: { type: BOOLEAN, text: 'Remittance needs recalculation' },

        status: { ref: require('./RemittanceStatus'), text: 'Status', default: RemittanceStatus.types.n },
        cmt: { type: STRING, text: 'Comment' },

        details: { transient: true, list: true, ref: RemittanceDetail, text: 'Details', text_fr: 'Details' },
        adjustments: { transient: true, list: true, ref: require('./adjustment/Adjustment'), text: 'Adjustments', text_fr: 'Adjustments' },
        startDistrBalance: {  transient: true, ref: RemittanceDistribution },
        currentDistrBalance: {  transient: true, ref: RemittanceDistribution },
        endDistrBalance: {  transient: true, ref: RemittanceDistribution },
        finalDistrBalance: {  transient: true, ref: RemittanceDistribution },
        lockedBalance: { ref: RemittanceDistribution },
        paidDateContribs: { transient: true, type: DATE, text: 'Paid Date'},
        paidDateSolvency: { transient: true, type: DATE, text: 'Solvency Paid Date'},


        
        adjusted: { transient: true, list: true, ref: require('./adjustment/Adjustment'), text: 'Adjustments', text_fr: 'Adjusted' },
        rates: {abstract: true, ref: require('../pension/Rates'), text: 'Rates', text_fr: 'Rates'  }, //TODO support 'inherited'
        historicRates: {abstract: true, inherited: true, ref: require('../pension/HistoricRates') },

        regular: { abstract: true, type: AMOUNT, text: 'Regular'},
        maternity: { abstract: true, type: AMOUNT, text: 'Maternity'},
        longTerm: { abstract: true, type: AMOUNT, text: 'Long Term'},
        self: { abstract: true, type: AMOUNT, text: 'Self'},
        voluntary: { abstract: true, type: AMOUNT, text: 'Voluntary'},
        deductions: { abstract: true, type: AMOUNT, text: 'Employee Contribs'},
        
        eeAdjustments: { abstract: true,type: AMOUNT,text: 'Employee Adjustments',text_fr: 'Employee Contributions Adjustments' },
        erAdjustments: { abstract: true,type: AMOUNT,text: 'Employer Adjustments',text_fr: 'Employer Adjustments' },
        volAdjustments: { abstract: true,type: AMOUNT,text: 'Voluntary Adjustments',text_fr: 'Employee Voluntary Contributions Adjustments' },
        solAdjustments: { abstract: true,type: AMOUNT,text: 'Solvency Adjustments',text_fr: 'Solvency Adjustments' },
        intAdjustments: { abstract: true,type: AMOUNT,text: 'Interest Adjustments',text_fr: 'Interest Adjustments' },

        // Contributions after adjustments are applied
        eeAdjustedContribs: { abstract: true,type: AMOUNT,text: 'Employee',text_fr: 'Employé' },
        erAdjustedContribs: { abstract: true,type: AMOUNT,text: 'Employer',text_fr: 'Employeur' },
        volAdjustedContribs: { abstract: true,type: AMOUNT,text: 'Voluntary',text_fr: 'Volontaire' },
        solAdjusted: { abstract: true,type: AMOUNT,text: 'Solvency',text_fr: 'Solvency' },
        intAdjusted: { abstract: true,type: AMOUNT,text: 'Interest',text_fr: 'Interest' },    
        
        totalOwing: { abstract: true, type: AMOUNT, text: 'Total Owing', text_fr: 'Total dû' },
        total: { abstract: true,type: AMOUNT,text: 'Total',text_fr: 'Total' },
        totalPayments: { abstract: true, type: AMOUNT, text: 'Total Payments'},
        balance: { abstract: true, type: AMOUNT, text: 'Balance', text_fr: 'Balance' },
        validated: {abstract: true, type: BOOLEAN, text: 'Validated'},

    }

    static key = ['employer', 'period']
    static refList = Remittances

}
