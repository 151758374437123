import React from "react"
import { Component } from '../../framework/components'
import { Choice } from '../../framework/controls'
import { sort } from '../../framework/utils/helper'

import EmployerService from '../../services/employer/EmployerService'

export default class EmployerSelection extends Component {
    load() {
        return EmployerService.getAll({load: false}).then(employers => ({employers}))
    }

    view() {
        const employerOptions = sort(this.state.employers.all, 'code').map(emp => ({ key: emp.id, text: emp.code + '  -  ' + emp.name }))
        return <Choice className={this.props.className} options={employerOptions} value={this.props.selected} onChange={this.props.onSelect || this.props.onChange} label={this.props.label }></Choice>
    }
}