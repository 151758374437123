import { isEmpty } from "lodash";
import moment from "moment";
import { Row } from "../../framework/containers";
import { ETS_FORMAT, getSafe } from "../../framework/utils/helper";

const DoubleColumnDisplay = ({entity, instance, properties = [], naValue ='None'}) => {

    const style = {flex: 1}
    return <Row>
        <div style={style} className="bold">
            {properties.map(property => 
                <div key={'label-'+property} className="row justify-content-start">{entity.getLabel(property)}:</div>
            )}
        </div>
        <div style={style} className="text-truncate">
            {properties.map(property => {
                let def = entity.getDefinition(property)
                let val = getSafe(instance, property);

                if (def?.type?.isChoice && val) val = def.getOptionText(val);
                if (def?.type?.isDate && val) val = moment(val).format(ETS_FORMAT);

                return <div key={'value-'+property} className={"row justify-content-start " + (isEmpty(val) || val === undefined ? 'btn-ghost' : '')}>
                    {isEmpty(val) || val === undefined ? naValue : val}     
                </div>
            })}
        </div>
    </Row>
}

export default DoubleColumnDisplay;