import { Persistence, Service } from '../../framework'

import { RemittanceDetail }  from "../../entities"
import RemittanceService from './RemittanceService'
import EmploymentService from '../employer/EmploymentService'
import FinancialSummary from '../../entities/pension/FinancialSummary';
import RemittanceDetailSummary from '../../entities/pension/RemittanceDetailSummary';
import { RemittanceDetailBusiness } from '../../business';
import { setAPIError } from '../../hooks/useAPIError';
import { AdjustmentService } from '.';
import { Period } from '../../framework/utils';
import DetailListMapper from '../../mappers/DetailListMapper';
import { RefEvent } from '../../framework/infra';

class RemittanceDetailService extends Service {
    constructor() {
        super(RemittanceDetail, "RemittanceDetail", "remittance");
        this.persistences = {
            financialSummary: new Persistence(FinancialSummary),
            remittanceDetailSummary: new Persistence(RemittanceDetailSummary),
        };
    }

    updateRemittanceDetailsBulk(changes) {
        return this.updateByCallApi("UpdateRemittanceDetailsBulk", {}, changes);
    }

    updateYEComments(changes) {
        return this.updateByCallApi("UpdateRemittanceDetailsComments", {}, changes);
    }

    getEmploymentRemittancesDetails(employment, options) {
        return this.getBy(
            "GetRemittancesDetailsForEmployment",
            { employment: employment.keyValue },
            options
        ).then((details) => {
            return details.setYtps();
        });
    }

    getDetailsSummaryForRemittance(employer, period) {
        const action = 'GetDetailsSummaryForRemittance';
        return this.callApi(action, action + '_' + employer+period, { employer, period, }, DetailListMapper);
    }

    getYearEndData(employer, employment, periods = []) {
        const action = 'GetYearEndData';
        const key = [employer, employment, periods.join(',')].join('_');
        let params = { periods };
        if (employer) params.employerId = employer;
        if (employment) params.employmentId = employment;

        return this.callApi(action, action + '_' + key, params, DetailListMapper);
    }

    getMemberRemittanceDetails(person, options) {
        return this.getBy(
            "GetRemittanceDetailsForMember",
            { person: person.id },
            options
        ).then((details) => {
            return details;
        });
    }

    getFinancialSummaryForPeriods(start, end, employer) {
        return this.persistences.financialSummary.getList(
            "remittance_GetFinancialSummary",
            { employer: employer, startDate: start, endDate: end }
        );
    }

    getRemittanceDetails(key, options) {
        return this.getBy(
            "GetDetailsForRemittance",
            { remittance: key },
            options
        ).then((details) => {
            return this.initList(details);
        });
    }

    async load(detail, options = {}) {
        const id = detail.remittance.employer.keyValue +  "_" + detail.participation.keyValue;
        
        detail.remittance = await RemittanceService.get(detail.remittance.keyValue);
        detail.employment =  await EmploymentService.get(id);

        if (!detail.employment) console.log("EMPLOYMENT NOT FOUND for this remittance detail", detail);

        return detail;
    }

    link(detail) {
        detail.earnings.assignEarningTypes(
            detail.remittance.employer.earningTypes
        );
        detail.ytpEarnings.assignEarningTypes(
            detail.remittance.employer.earningTypes
        );
        return detail;
    }

    initList(details, options = {}) {
        const detsByRem = details.group("remittance.keyValue");
        Object.getOwnPropertyNames(detsByRem).forEach((remKey) => {
            detsByRem[remKey].first.remittance.details = detsByRem[remKey];
        });
        return details;
    }

    transferRemittanceDetails(startPeriod, oldKeys, newKeys) {
        this.invalidateCache();
        return this.persistence
            .callApi(this.moduleName + "_TransferDetails", {
                period: startPeriod,
                oldKeys: oldKeys,
                newKeys: newKeys,
            })
            .catch((err) => {
                setAPIError(err);
                console.log(err);
                throw err;
            });
    }

    //invoked by detail page to load all details
    async initDetails(employer, period, remittance) {
        try {
            const year = (new Period(period)).year;
            const adjustments = await AdjustmentService.getAdjustmentsForYear(employer.id, year);

            remittance.details = await this.getDetailsSummaryForRemittance(employer.id, period);
            remittance.details.appendMissingEarningTypes(remittance.employer.getActiveEarningTypes());
            remittance.adjustments = adjustments.filter((x) => x.period.isSame(remittance.period));

            remittance.details = remittance.details.mapList((det) => {
                    det.addAdjustmentsToYTP(remittance.adjustments.filter((x) => x.keysValues?.participation === det.keysValues?.participation && !x.period.isSame(det.period)));
                    det.initDetailAdjustment(adjustments);
                    if (det.isRelevant()) {
                        RemittanceDetailBusiness.validate(det);
                        return det;
                }}).getFiltered((element) => element !== undefined);

            return remittance;
        } catch (e) {
            setAPIError(e);
        }
    }

    loadDetailsWithAdjustmentsForEmployment = async (employment) => {
        const details = await this.getEmploymentRemittancesDetails(employment);
        const adjustments = await AdjustmentService.getAdjustmentsForEmployment(employment.employer.keyValue, employment.keysValues.participation,{ load: true, refresh: true });

        adjustments.all.forEach((adjustment) => {
            if (!details.find((detail) =>detail.period.isSame(adjustment.period))) details.push(adjustment.remDetail);
        });
        details.forEach((detail) =>detail.initDetailAdjustment(adjustments));
        return details;
    };

    /**
     * 
     * @param {*} details a list of details for the interval of period we want to calculate the expected deemed amounts for
     * @returns an object containing the expected earnings, hours and contribution amounts for ltd, maternity, self and totals
     *  for the input details list
     */
    calculateExpectedDeemed(details) {
        const recalculatedDetails = details.map((det) => {
            det.employment.events.sortEvents();
            RemittanceDetailBusiness.calculateContribs(det);
            details.setYtps();
            return det;
        });

        return {
            calculatedDetails: recalculatedDetails,
            expected: recalculatedDetails.reduce(
            (totals, detail) => {
                return {
                    total: {
                        earnings: totals.total.earnings + detail.earnings.deemed,
                        hours: totals.total.hours + detail.earnings.deemedHours,
                        contributions: totals.total.contributions + detail.contributions.deemed,
                    },
                    ltd: {
                        earnings: totals.ltd.earnings + detail.earnings.ltd,
                        hours: totals.ltd.hours + detail.earnings.ltdHours,
                        contributions: totals.ltd.contributions + detail.contributions.ltd,
                    },
                    mat: {
                        earnings: totals.mat.earnings + detail.earnings.mat,
                        hours: totals.mat.hours + detail.earnings.matHours,
                        contributions: totals.mat.contributions + detail.contributions.mat,
                    },
                    slf: {
                        earnings: totals.slf.earnings + detail.earnings.slf,
                        hours: totals.slf.hours + detail.earnings.slfHours,
                        contributions: totals.slf.contributions + detail.contributions.slf,
                    },
                };
            },
            {
                total: {
                    earnings: 0,
                    hours: 0,
                    contributions: 0,
                },
                ltd: {
                    earnings: 0,
                    hours: 0,
                    contributions: 0,     
                },
                mat: {
                    earnings: 0,
                    hours: 0,
                    contributions: 0,     
                },
                slf: {
                    earnings: 0,
                    hours: 0,
                    contributions: 0,     
                }
            }
        )}
    }
}
const instance = new RemittanceDetailService()
export default instance

