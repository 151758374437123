import { Definition, RefEvent } from '../../../framework/infra'
import { SubEvents } from '../../flow/SubEvent'
import { participationEventConfigs } from './ParticipationConfig'
import ParticipationConstraints from './ParticipationConstraints'


import ParticipationStatus from './ParticipationStatus'

export default class ParticipationEvent extends RefEvent {

    get stsCode() { return (this.config.status ? 'PP-' + this.status.keyValue :'') }
    get status() { return ParticipationStatus.types[this.config.status] || ParticipationStatus.default }
    get statusDesc() { return this.name || this.status.desc }
    get effectiveDate() { return this.effDt }
    get config() { return { ...participationEventConfigs['default'], ...participationEventConfigs[this.code]}}
    get constraints() { return ParticipationConstraints }


    isEligibleEvent() { return this.code === 'metEligDate'}

    static messages = [

        ['newPotDiffJur', 'Potential Participation Created, Previous Participation is receiving pension', '',  'i'],

        //Payments
        ['payAut', 'PYMT - Payment authorization sent to RBC', '', 'i'],
        ['paySus', 'PYMT - SUS – Pension Benefit suspended; informed RBC', '', 'i'],
        ['payEnd', 'PYMT - STOP – Deceased-Pension Benefit stopped: informed RBC', '', 'i'],

         //Eligibility
        ['newPot', 'Potential participation created', '', 'i'],
        ['potRmb', 'Potential caused by reimbursement', '', 'i'],
        ['lgcyAct', 'NROL - Member joined the plan', '', 'i'],
        ['enrRec', 'Enrollment form received', '', 'i'],
        ['metElig', 'NROL - Met eligibility', '', 'i'],
        ['nrolFday', 'NROL - First day of work after meeting eligibility'],
        ['metEligDate', 'ELIG - Meets eligibility on this date', '', 'i'],
        ['erWaiv', 'NROL - ER waived eligibility requirements', '', 'i'],
        ['ineg', 'Not Eligible - Employee is not eligible', '', 'i'],
        ['ppExp', 'PPEXP- Eligibility period has expired', '', 'i'],
        ['retPay', 'RPAY - Retro payment received from employee', '', 'i'],
        ['noRetP', 'RNOPAY- Retro Payment not received by employee', '', 'i'],
        ['aElig', 'ELIG - Met eligibility but already enrolled', '', 'i'],


        //Age60
        ['a60Des', 'Age60 Declaration sent to EE& ER', '', 'i'], //add Task 'Member is eligible to unreduced pension while continuing to work.
        ['a60Der', 'Age60 Declaration received','', 'i'],
        ['a60Sen', 'Age60 Calculation request sent to Mercer','', 'i'],
        ['a60Pen', 'Age60 Member is eligible to Unreduced Pension while continuing to work', '', 'i'],
        ['a60Nop', 'NOP - Active60 Declaration received: continue accruing', '', 'i'],
        ['a60Fdr', 'Age60 Final data confirmed by ER', '', 'i'],
        ['a60sdf', 'SDF - Sixty & Still Deferred', '', 'i'],
        ['a60oso', 'OSO - Outstanding Age60 Options. Package mailed by Mercer', 'Age60 Options. Package mailed by Mercer', 'i'],
        //LTD60
        ['l60Ccp', 'LNP - LTD60 Requested EE choice: continue LTD or commence pension', '', 'i'], //add Task 'Member is eligible to unreduced pension while continuing to work.
        ['l60Pen', 'LTD60 Chose to commence pension. Calculation request sent to Mercer', '', 'i'],
        ['l60Nop', 'NOP - LTD60 Chose to continue LTD', '', 'i'],
        ['l60Fdr', 'LFDC - Final data confirmed by ER', '', 'i'],
        ['l60oso', 'OSO - Outstanding LTD60 Options. Package mailed by Mercer', 'LTD60 Options. Package mailed by Mercer', 'i'],
        //Retirement
        ['penPed', 'MEMTD - Membership termination date', '', 'i'],
        ['penSus', 'FRZ - Member elected to suspend pension', '', 'i'],
        ['penRea', 'REA - Member elected an Early Retirement', 'Pensioner - Receiving Early Mthly Benefit', 'i'],
        ['penRap', 'RAP - Member elected to receive an unreduced pension', 'Pensioner - Receiving Mthly Benefit', 'i'],
        ['penRac', 'RAC - Member cashed out', 'Pensioner - Paid Out', 'i'],
        ['penOnp', 'ONP - Pension calculation request sent to Mercer', 'Pensioner - Request sent to actuaries', 'i'],
        ['penOpo', 'OPO - Outstanding Pension Options. Options mailed by Mercer', 'Pensioner - Member response outstanding', 'i'],
        ['retDef', 'RFD – Documents have been requested to the employee', 'Pending Retirement from Deferred - wait for documents', 'i'],
        ['rfdDoc', 'RFD - Pension documents received', 'Pending Retirement from Deferred - documents received', 'i'],
        //Termination
        ['trmPed', 'MEMTD - Membership termination date', '', 'i'],
        ['trmReq', 'TFD - Cash out from Deferred', '', 'i'],
        ['trmPde', 'OTD - Options package mailed by Mercer: entitled to deferred', 'Terminated - Member response outstanding', 'i'],
        ['trmPda', 'OTDA - Options package mailed by Mercer: entitled to a deferred and additional benefit', 'Terminated - Member response outstanding', 'i'],
        ['trmPre', 'OTR - Options package mailed by Mercer: entitled to refund', 'Terminated - Member response outstanding', 'i'],
        ['trmDef', 'DEF - Member elected a Deferred pension; or defaulted as option expired', 'Terminated - Deferred', 'i'],
        ['trmDea', 'DEFX – Option expired – automatically deferred', '', 'i'],
        ['trmRef', 'REF - Member elected a Refund', 'Terminated - Paid Out', 'i'],
        ['trmPao', 'Paid Out to RQ - No response from Employee', '', 'i'],
        ['trmLit', 'LIT - Member elected a Locked-In Transfer', 'Terminated - Paid Out', 'i'],
        ['trmOnp', 'ONP: Termination calculation request sent to Mercer', 'Terminated - Request sent to actuaries', 'i'],
        //Death
        ['dapSurv', 'DAP – Survivor entitled to Single Life Pension', 'Deceased - Survivor entitled to Single Life Pension', 'i'],
        ['deaDap', 'DAP - Member\'s spouse/beneficiary(ies) elected a pension.', 'Deceased - Survivor Pension', 'i'],
        ['deaDac', 'DAC- Member\'s spouse/beneficiary(ies) elected a refund.', 'Deceased - Paid Out', 'i'],
        ['deaEnd', 'END - Member died after guarantee period expired. No further benefits are payable', 'Deceased - No further entitlements', 'i'],
        ['deaOdo', 'ODO - Outstanding Deceased Options. Package mailed by Mercer', 'Deceased - Member response outstanding', 'i'],
        ['deaOnp', 'ONP - Deceased calculation request sent to Mercer', 'Deceased - Request sent to actuaries', 'i'],
        //Transfer


        ['trfExp', 'TRFEXP – Response deadline expired', 'Transfer-In', 'i'],
        ['trfTri', 'TRFIN - Member elected to Transfer-In (default, if no response)', '', 'i'],
        ['trfNtr', 'NOTRF - Member elected to not transfer to new ER', '', 'i'], //process to follow
        ['trfAct', 'Active status confirmed', '', 'i'],
        ['trfOnp', 'ONP - Termination/Transfer calculation request sent to Mercer','', 'i'],
        ['trfOtf','OTF - Outstanding Transfer Options. Package mailed by Mercer', '', 'i'],

        //Legacy start
        ['trfLtr', 'Inter-Plan Transfer letter received: elected to transfer-in', '','i'], 
        ['nTrfLtr', 'Inter-Plan Transfer letter received: elected to NOT transfer-in','', 'i'], 
        ['trfDes', 'TRFSNT – Inter-Plan Transfer letter has been sent to employee and employer', '', 'i'], 
        ['trfExpAut', 'Inter-Plan Transfer expired (more than 30 days): automatically transferred in', '', 'i'],
        ['trfInfEmp','Employee has been informed of benefits being transferred-in and of automatic deductions', '', 'i'],
        //legacy end

        //Rehiring a pensioner
        ['rPenFrms','Rehiring a Pensioner form has been sent', '', 'i' ],
        ['frzPenExp', 'Option to freeze pension expired: will not accrue in new employer', '', 'i' ],
        ['rPenExpl', 'Pensioner: Rehired - Not Eligible (offer to suspend pension expired)', 'Pensioner: Rehired - Not Eligible (offer to suspend pension expired)', 'i'],
        ['rPenFrmr', 'Rehiring a Pensioner form has been received', '', 'i'],

        ['susPenEli', 'Member will suspend pension. Eligible to join', '', 'i'],
        ['susPenNEG', 'Pensioner: Rehired - Not Eligible (opted to not suspend pension)', 'Pensioner: Rehired - Not Eligible (opted to not suspend pension)', 'i'],
        ['susPen', 'Member elected to suspend pension and accrue in current participation', '', 'i'],
        
        ['penDea', 'Pending - Deceased', 'Pending - Deceased', 'i'],
        ['penTrm', 'Pending - Terminated', 'Pending - Terminated', 'i'],
        ['penPnr', 'Pending - Pensioner', 'Pending - Pensioner', 'i'],
        ['deaFDC', 'DFDC - Deceased Final Data Confirmed', 'Deceased - to be processed', 'i'],
        ['termFDC', 'TFDC - Terminated Final Data Confirmed', 'Terminated - to be processed', 'i'],
        ['pnrFDC', 'PFDC - Pension Final Data Confirmed', 'Pensioner - to be processed', 'i'],
        ['pRim', 'RIM - Not eligible: all contributions reimbursed to Employer','Reimbursed','i'],
        ['frstEmpAct', 'Active status in prior employer confirmed', '','i'],
        ['frstEmpNoAct', 'Confirmation received that member is no longer working in previous employer. Participation is no longer open.', '', 'i'],
        ['midMonthAdj', 'Mid Month Adjustment Received', '', 'i'],
        ['a60neg', 'Age 60 - Member not entitled to Pension and work at age 60. Data confirmed by employer.', '', 'i'],
        ['adjTerm', 'Adjustments made since Earnings and/or hours reported for a closed participation', '', 'i'],
        ['noAdjTerm','No adjustments were made for Earnings and/or hours reported for a closed participation', '', 'i' ],
        
        ['penDt', 'Pension start Date', '', 'i'],
        ['penEmp', 'Pensioner: Deductions ceased age 60', 'Pensioner: Deductions ceased age 60', 'i'],
        ['penRhdD', 'Pensioner: Rehired - Not Eligible (age requirement not met)', 'Pensioner: Rehired - Not Eligible (age requirement not met)', 'i'],
        ['inegOvAge', 'Not Eligible - Employee is not eligible (Age requirement not met)', 'Not Eligible (age requirement not met)', 'i'],
        ['inegNotF', 'Not Eligible - Not identified by Employer as Eligible', 'Not Eligible - Not identified by Employer as Eligible', 'i'],
        ['penRhdA', 'Pensioner: Rehired - Not Enrolled (waiting for Declaration)', 'Pensioner: Rehired - Not Enrolled (waiting for Declaration)', 'i'],        
        ['onbrd', 'Onboarding - Complete', '', 'i'],
        ['trfSnd','Membership Transfer {Not Eligible} notice has been sent to employee and employer.', '', 'i '],
        ['age60Flow', 'Age60 Complete', '', 'i'],
        ['noAdjTran', 'Required Contributions that need to be reimbursed or adjusted', '', 'i'],
    ]

    static definitions = {
        status : { abstract: true, ref: ParticipationStatus, text: 'Status'},
        statusDesc : { abstract: true, type: Definition.types.STRING, text: 'Status'},
        effectiveDate: { abstract: true, type: Definition.types.STRING, text: 'PP Effective Date' },
        eventDesc: { abstract: true, type: Definition.types.STRING, text: 'Last PP Event' },
        config: {abstract: true},
        stsCode: {abstract: true},
        subEvent: {ref: SubEvents, text: 'SubEvent'},
    }

    static groupedMessages(participation) {
        return Object.values(this.messages).reduce((eventGroups, message) => {
            const singleEvent = participationEventConfigs[message.key]?.singleEvent && participation.events.find(event => event.code === message.key);
            if (participationEventConfigs[message.key]?.groups && !participationEventConfigs[message.key]?.isHidden && !singleEvent) {
                participationEventConfigs[message.key].groups.forEach(groupName => {
                    eventGroups[groupName] = eventGroups[groupName] || []
                    eventGroups[groupName].push(message)
                })
            } 
            return eventGroups
        }, {})
    }
}
