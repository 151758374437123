import { MembershipBusiness } from "../../business";
import EmploymentBusiness from "../../business/EmploymentBusiness"
import { appCmt } from "../../framework/utils/helper";
import { EmploymentService, MembershipService, RemittanceDetailService } from "../../services"

const warnings = {
    NEW: 'Employment created',
    WILL_NEW: 'New employment will be created',
    NO_CREATE: 'Employment updated',
    WILL_NO_CREATE: 'Employment will be updated',
    AGE_JOIN: 'The employee did not meet age requirements to join the plan',
    NO_DOB: 'Not eligible. No date of birth on file',
    NOT_FOUND: 'No Eligibility event in history. Not Eligible',
    AGE_REJOIN: 'The employee did not meet age requirements to re-join the plan',
    REHIRING: 'Send Member Declaration: Rehiring a Pensioner form',
    EXPIRED: 'Found an expired on leave event',
    MARK_EXP: 'Employment marked as expired',
    WILL_MARK_EXP: 'The employment on leave will be marked as expired',
    ISSUE: 'The first hire date in the new participation is older than the pending close date the of previous participation',
    F60: 'Employee was fired less than 60 days ago',
    RTW: 'Employee returned to work',
    CANT: 'Employment already exists',
    ELIG_EXP: 'Eligibility expired in previous participation',
    ELIG: 'Employee is eligible to join the plan. Confirm first day of work',
}

async function create (employment, options) {

    let warning = '';
    const manageFunctions = [
        manageNew,
        managePensioner,
        manageClosed,
        managePending,
        manageActive,
        manageIneligible,
        manageEligible,
    ]
    let results = { warning };
    for (const manageFunction of manageFunctions) {
        results = await manageFunction(employment, employment.participation.membership, results.warning, options);
        if (results.employment) return results; //if we save an employment
    }
}
    
async function splitEmployment(employment, params) {

    const pp = params.participation;
    const oldKeys = employment.keyValue;
    pp.employments.remove(emp => emp.keyValue === employment.keyValue);
    employment.getHiredEvent().cmt = appCmt(params.cmt ?? '');
    await EmploymentBusiness.createNewPPForEmp(employment, employment.membership);
    const newKeys = employment.keyValue;

    if(!pp.isPendingClose()){
        pp.addEvent({code : 'penTrm'});
    }

    if(params.ppCode) {
        pp.addEvent({code: params.ppCode});
    }

    if (params.noCommit) {
        return Promise.resolve(employment)
    } else {
        await EmploymentService.update(employment)
        await RemittanceDetailService.transferRemittanceDetails(employment.hiredDate, oldKeys, newKeys)
        await EmploymentService.deleteEmptyEmployment(oldKeys)
        await MembershipService.update(employment.participation.membership)
    }
}
    
async function expireEmployment(employment, params) {
    employment.addEvent({code: 'tex', ets: params.ets });
    if (!params.noCommit) {
        await EmploymentService.update(employment);
        await MembershipService.update(employment.participation.membership);
    }
}

async function manageAgeRequirement(employment, membership, warning, options) {
    if (employment.hasAgeToRejoin()) {
        await EmploymentBusiness.createNewPPForEmp(employment, membership, {code: 'inegNotF', cmt: appCmt(warnings.NOT_FOUND)})
        return saveChanges(addWarn(warning ,warnings.NOT_FOUND), employment, membership, true, options);
    } else {
        await EmploymentBusiness.createNewPPForEmp(employment, membership, {code: 'inegOvAge', cmt: appCmt(warnings.AGE_JOIN)})
        return saveChanges(addWarn(warning ,warnings.AGE_JOIN), employment, membership, true, options);
    }
}

async function manageNew(employment, membership, warning, options) {
    const lastParticipation = membership.participations.last
    if (lastParticipation) return { warning: warning };

    if (!employment.person.dob) {
        await EmploymentBusiness.createNewPPForEmp(employment, membership, {code: 'inegNotF', cmt: appCmt(warnings.NO_DOB)})
        return saveChanges(addWarn(warning ,warnings.NO_DOB), employment, membership, true, options);
    }

    return manageAgeRequirement(employment, membership, warning, options);
}

async function managePensioner(employment, membership, warning, options) {
    if (!membership.isReceivingPension(employment.hiredDate, employment.employer.plan.jurisdiction)) return { warning: warning };
    
    if (membership.participations.last.status.isActive()) {
        return manageActive(employment, membership, warning, options);
    }

    if (employment.hasAgeToRejoin()) {
        await EmploymentBusiness.createNewPPForEmp(employment, membership, {code: 'penRhdA', cmt: appCmt(warnings.REHIRING)});
        return saveChanges(addWarn(warning, warnings.REHIRING), employment, membership, true, options);
    } else {
        await EmploymentBusiness.createNewPPForEmp(employment, membership, {code: 'penRhdD', cmt: appCmt(warnings.AGE_REJOIN)});
        return saveChanges(addWarn(warning, warnings.AGE_REJOIN), employment, membership, true, options);
    } 
}

async function manageClosed(employment, membership, warning, options) {
    const lastParticipation = membership.participations.last
    if (!lastParticipation.status.isClose()) return { warning: warning };
    
    return manageAgeRequirement(employment, membership, warning, options);
}

async function managePending(employment, membership, warning, options) {
    const lastParticipation = membership.participations.last
    const hireDateEts = employment.getHiredEvent().ets; 

    //expire all employments if they have outstanding leaves
    let results = await expireEmployments(lastParticipation, hireDateEts ,options.noCommit);
    if (results) warning = addWarn(results, warning);
    if (!lastParticipation.isPendingClose()) return { warning: warning };

    const employments = getEmploymentsFiredWithin60days(lastParticipation, employment.hiredDate);
    // some employments were recently fired quit, re-open this pending pp
    if (employments.length > 0) {
        let existingEmployment = employments.find(ee => ee.employer.keyValue === employment.employer.keyValue);
        
        if (existingEmployment) { //end process
            employment = existingEmployment;
            return saveChanges(warnings.F60, employment, membership, false, options);
        }

        lastParticipation.events.pullFilter((e => !e.config.isPendingEvent));

        //Switch-out, Switch-in
        let lastEmployment = getLastFiredEmployment(lastParticipation.employments.all);
        if (lastEmployment.employer.keyValue !== employment.employer.keyValue) {
            lastEmployment.addEvent({code: 'tsw', ets: hireDateEts });
            if (!options.noCommit) await EmploymentService.update(lastEmployment);
            employment.addEvent({code: 'swi', ets: hireDateEts});    
        }

        await EmploymentBusiness.mergeEmployment(employment, lastParticipation);
        return saveChanges(warning, employment, membership, true, options);
    } 
    // make new participation since employments fired a while ago
    else { 
        return manageAgeRequirement(employment, membership, warning, options);
    }
}

async function manageActive(employment, membership, warning, options) {

    const lastParticipation = membership.participations.last
    if (!lastParticipation.status.isActive()) return { warning: warning };
    let hireDateEts = employment.getHiredEvent().ets; 
    let joinedDateEts = lastParticipation.eligibilities.last?.ets;

    let existingEmployment = lastParticipation.employments.find(ee => ee.employer.keyValue === employment.employer.keyValue);
    let activeEmployments = lastParticipation.employments.filter(x=>x.status.isActive() || x.status.isOnLeave());

    for (let activeEmployment of activeEmployments) {
        if(!activeEmployment.getMerEvent()) {
            activeEmployment.addEvent({code: 'mer', ets: joinedDateEts});
            if (!options.noCommit){
                await EmploymentService.update(activeEmployment);
            }
        }
    }
    employment.addEvent({code: 'mer', ets: joinedDateEts})

    if (existingEmployment) { 
        //guard, we shoudln't get here with an active ER
        if (existingEmployment.status.isActive()) {
            return saveChanges(addWarn(warnings, warnings.CANT), employment, membership, false, {end: true});
        }
        employment = existingEmployment;
        employment.addEvent({code: 'rtw', ets: hireDateEts, guessed: true});
        return saveChanges(addWarn(warning, warnings.RTW), employment, membership, false, options);
    } else {
        await EmploymentBusiness.mergeEmployment(employment, lastParticipation);
        return saveChanges(warning, employment, membership, true, options);
    } 
}

async function manageIneligible(employment, membership, warning, options) {
    const lastParticipation = membership.participations.last
    if (!lastParticipation.status.isIneligible()) return { warning: warning };
    let existingEmployment = lastParticipation.employments.find(ee => ee.employer.keyValue === employment.employer.keyValue);
    if (!existingEmployment) {
        await EmploymentBusiness.mergeEmployment(employment, lastParticipation);
        return saveChanges(warning, employment, membership, true, options);
    } else {
        let hireDateEts = employment.getHiredEvent().ets; 
        employment = existingEmployment;
        employment.addEvent({code: 'rtw', ets: hireDateEts, guessed: true});
        return saveChanges(addWarn(warning, warnings.RTW), employment, membership, false, options);
    }
}

async function manageEligible(employment, membership, warning, options) {
    const lastParticipation = membership.participations.last
    if (!lastParticipation.status.isEligiblePeriod()) {
        let hireDateEts = employment.getHiredEvent().ets; 
        let existingEmployment = lastParticipation.employments.find(ee => ee.employer.keyValue === employment.employer.keyValue);
        if (existingEmployment) { //if the employment exists, we can just rtw since they weren't part of a plan
            employment = existingEmployment;
            employment.addEvent({code: 'rtw', ets: hireDateEts, guessed: true});
            return saveChanges(addWarn(warning, warnings.RTW), employment, membership, false, options);
        } else {
            await EmploymentBusiness.mergeEmployment(employment, lastParticipation, 'inegNotF', warnings.NOT_FOUND)
            return saveChanges(addWarn(warning, warnings.NOT_FOUND), employment, membership, true, options);
        }
    }
    
    employment.participation = lastParticipation;
    if (employment.isStillEligible()) {
        await EmploymentBusiness.mergeEmployment(employment, lastParticipation);
        return saveChanges(addWarn(warning, warnings.ELIG), employment, membership, true, options);
    } else {
        lastParticipation.addEvent({code: "ppExp"});
        
        for (let ppEmployment of lastParticipation.employments.all) {
            ppEmployment.addEvent({code: 'tclCan'});
            if (!options.noCommit) await EmploymentService.update(ppEmployment);
        }
            
        await EmploymentBusiness.createNewPPForEmp(employment, membership, {code: 'ineg'});
        return saveChanges(addWarn(warning, warnings.ELIG_EXP), employment, membership, true, options);
    }
}

function getEmploymentsFiredWithin60days(participation, hiredDate) {
    const employmentsWithin60Days = [];
    for (let employment of participation.employments.all) {
        if (employment.isFiredQuitWithin60Day(hiredDate)) {
            employmentsWithin60Days.push(employment);
        }
    }
    return employmentsWithin60Days;
}

async function expireEmployments(participation, date, noCommit) {
    let results = '';
    for (let employment of participation.employments.all) {
        let expirationEvent = employment.isExpiredAsOf(date)
        if (expirationEvent) {
            results = addWarn(results, warnings.EXPIRED + ' (' + employment.employer.code + ')');
            results = addWarn(results, noCommit ? warnings.WILL_MARK_EXP : warnings.MARK_EXP);
            await expireEmployment(employment, {noCommit, ets: expirationEvent.ets + 100, participation: participation});
        }
    }
    if (results) return results;
}

//finalized changes to employment creation
async function saveChanges(warning = '', employment, membership, created, options) {
    if (options.end) {
        return { warning: warning, employment, membership, newEmploymentCreated: false, noAction: true };
    }
    let creation = created ? 
                        (options.noCommit ? warnings.WILL_NEW : warnings.NEW) : 
                        (options.noCommit ? warnings.WILL_NO_CREATE : warnings.NO_CREATE);

    let finalWarning = addWarn(warning, creation)
    const res = { warning: finalWarning, employment, membership, newEmploymentCreated: created};
    
    if (!options.noCommit) {
        await MembershipService.update(membership);
        await EmploymentService.update(employment);
    }

    MembershipBusiness.reassignNestedParticipations(membership);
    return res;
}

function addWarn(warning, addon) {
    if (addon === '') return warning;
    return (warning !== ''? warning + '. ': '') + addon;
}

function getLastFiredEmployment(employments) {
    let latestFired;
    for (let employment of employments) {
        const event = employment.events.findLast(ev => ev?.status.isFiredQuit());
        if (!latestFired) latestFired = employment;
        else {
            const latest = employment.events.findLast(ev => ev?.status.isFiredQuit());
            if (latest.ets <= event.ets) latestFired = employment;
        }
    }

    return latestFired;
}

export const EmploymentTriggers = {
    create,
    splitEmployment,
    expireEmployment
}