
import React, { useState } from "react"
import { Button, Icon, UploadButton } from '../../../framework/controls'
import { Row } from '../../../framework/containers'
import { Excel } from '../../../framework/utils'

import { Modal } from "../../../framework/containers"
import YEUploadComments from "./YEUploadComments"
import { Report } from "../../../entities/yearEnd/Report"
import { YearEndService } from "../../../services"
import useNotification from "../../../hooks/useNotification"
import { isEmptyObject } from "jquery"
import yearEndTabs from "../../../templates/report-templates/yearEndTemplate"

const UploadTemplateReport = ({report, isMercer, onCommentSave, fileName}) => {
    const [uploadCommentsFile, setUploadCommentsFile] = useState();
    const [template, setTemplate] = useState();
    const { addMessage } = useNotification();

    const handleUploadTemplate = async (file) => {
        const loadedTemplate = new Excel(file.name);
        await loadedTemplate.loadFile(file);
        setTemplate(loadedTemplate);
    }

    const handleUploadComments = async (file) => {
        const excel = new Excel(file.name)
        await excel.loadFile(file)
        setUploadCommentsFile(excel);
    }

    const handleSavedUploadComments = (details) => {
        addMessage(`Comments successfully saved`, "success");
        setUploadCommentsFile();
        onCommentSave(details)
    }

    const fillData = (report, sheet, headers, lineStart, displayType=YearEndService.displayTypes.SIMPLE) => {
        let groupHeaders = new Excel.Headers(Report, headers)
        groupHeaders = YearEndService.getHeader(displayType, Excel, report.earningTypes, groupHeaders);
        template.addDataRows(sheet, groupHeaders, report.details, lineStart)
    }

    const fillTemplate = (excel, data, templateTabs) => {
        for (const tabKey in templateTabs) {
            const tab = templateTabs[tabKey];
            const sheet = excel.findSheet(tab.tabTitle);
            let rows = {...data};

            if (tab.filter) rows.details = rows.details.filter(tab.filter);
            if (tab.customLoad) tab.customLoad(report, sheet, template);
            if (tab.headerGroups) fillData(rows, sheet, tab.headerGroups, tab.startIndex);
            if (!isEmptyObject(tab.cellHardConfigs)) {
                rows.details.forEach((row, rowIndex) => {
                    for (const cell in tab.cellHardConfigs) {
                        for (const config in tab.cellHardConfigs[cell]) {
                            const cellValue = tab.cellHardConfigs[cell][config](sheet, row, rowIndex);
                            sheet.getCell(cell + (rowIndex + tab.startIndex))[config] = cellValue;
                        }
                    }
                });
            }
        }
    }

    const handleDownload = () => { 
        let excel = template;
        excel.fileName = fileName;
        if (isMercer) {
            fillData(report, excel.findSheet('Report'), mercerReport, 4, YearEndService.displayTypes.MERCER);
        }
        else { 
            fillTemplate(excel, report, yearEndTabs);
        }

        return excel.download();
    }

    return <> 
        <Row>
            <UploadButton buttonClassName="d-flex justify-content-center align-items-center ml-3 px-3" id={`upload-template-${isMercer}`} onUpload={handleUploadTemplate} text='Upload Template' displayfileName={true} accept='.xls,.xlsx,.csv' fileName={template?.fileName} />
            <Button className={`d-flex justify-content-center align-items-center ml-3 px-3 ${template ? '' : 'disabled'}`} key="download" onClick={handleDownload}>
                Download Report <Icon icon="file-download" className="text-white ml-2" tooltip-left large/>
            </Button>
        </Row>
        {!isMercer && <UploadButton buttonClassName="d-flex justify-content-center align-items-center ml-3 px-3" id={`upload-comments-${isMercer}`} onUpload={handleUploadComments} text='Upload Comments' accept='.xls,.xlsx,.csv'/>}
        {uploadCommentsFile && <Modal show={true} title="Year-End Comments" centered animation={false} onHide={() => setUploadCommentsFile()} id="yecomments" className='h-100 w-80'>
        <YEUploadComments file={uploadCommentsFile} report={report} onCancel={() => setUploadCommentsFile()} onSave={handleSavedUploadComments} />
        </Modal>}
    </>
}

export default UploadTemplateReport;


const mercerReport = [
    'mercerIntro',
    'employmentFull',
    'details', 
    'pensionableEarnings', 
    'contributions', 
    'creditedService', 
    'pensionAdjustment', 
    'lastYearDetails', 
    'adjustments',
    'comment', 
    'validation',
]
