import React from 'react'
import { Component } from '../../../framework/components'
import { Card, Col, Row, Title } from '../../../framework/containers'
import { Choice, Table, Button } from '../../../framework/controls'

import { Adjustment, AdjustmentType, Distribution } from '../../../entities'

const templates = Object.values(AdjustmentType.types).filter(type => type.template).reduce((templates, type) => { 
    templates[type.key] = require('./templates/' +  type.template).default
    return templates
}, {})

export default class AdjustmentTemplate extends Component {
    load() {
        return {template: this.props.type || 'ESC'}
    }
    
    view() {
        var { template, adjustments } = this.state
        const columns = new Table.Headers(Adjustment, 'period, employerCode, distributionMap.e.am, distributionMap.r.am, distributionMap.s.am, distributionMap.i.am, amount')
        const colsToHideIfEmpty = ['distributionMap.e.am', 'distributionMap.r.am', 'distributionMap.s.am', 'distributionMap.i.am']
        colsToHideIfEmpty.forEach(col => {
            columns[col].hideIfEmpty = true
            columns[col].title = Distribution.getOptionLabel('ta', col.split('.')[1])
        })
        const templateOptions = Object.values(AdjustmentType.types).filter(type => type.template).map(type => ({ key: type.key, text: type.desc }))
        const Template = templates[template]
        return <><Title title='Adjustments Templates' onHide={this.handleCancel.bind(this)}/>
            <Card>
                <Row className='pt-3 bg-color'>
                    <Choice name='template' options={templateOptions} value={template} placeholder='Select Adjustment Type' onChange={this.handleTemplateChange.bind(this)} empty={true} searcheable={false}></Choice>
                    <Col/>
                    <Button key="cancel" type="link" className='text-primary' onClick={this.handleCancel.bind(this)}>Cancel</Button> 
                    <Button key="save" className='v-center ml-3' onClick={this.handleSave.bind(this)} disabled={!adjustments || adjustments.length < 1}>Save</Button>
                </Row>
                <Card className="pt-3 bg-color">
                    {template && <Template onChange={this.handleChange.bind(this)}/>}
                </Card>
                <Card className="pt-3 bg-color">
                    <Table id='employer-adjustments-solvency-releive' data={adjustments || []} entity={Adjustment} columns={columns} sort='employer.code' desc onTableLoad={(table) => this.table = table} />
                </Card>
            </Card>
        </>
    }

    handleTemplateChange(template) {
        this.setState({template: template, adjustments: []})
    }

    handleChange(adjustments) {
      this.setState({adjustments})
    }

    handleSave() {
        if(this.props.onSave) this.props.onSave(this.state.adjustments)
    }
    
    handleCancel() {
        if (this.props.onCancel) this.props.onCancel()
    }
}
