import React from "react"
import { Component, PageTitle } from '../../framework/components'
import { Table, Button, Icon } from '../../framework/controls'
import { Modal, Row, Col, Card } from '../../framework/containers'
import { Check } from '../../framework/inputs'

import { Employment, MemberSummary } from '../../entities'
import { MembershipService } from '../../services'

import MemberDetails from "./MemberDetails"
import EmploymentForm from "../employee/EmploymentForm"
import PersonForm from '../person/PersonForm'

export default class MembersPage extends Component {
    load() {
        const filters =  {
            contacts: { key: 'contacts', text: 'Contacts', prop: 'isContact', on: false },
            spouse: { key: 'spouse', text: 'Spouse', prop: 'isSpouse', on: false },
            members: { key: 'members', text: 'Members', prop: 'isMember', on: true },
            // beneficiary: { key: 'beneficiary', text: 'Beneficiary', prop: 'isBeneficiary', on: false }
            // potential: { key: 'potential', text: 'Potential Members', prop: 'isPotentialMember', on: false }
        }
        return MembershipService.getMembersSummary().then(summaries => {
            return { rows: summaries, show: '', filters: filters }
        })
    }

    view() {
        const { selected, showDetails, addNewEmployee, showUpload, filters, rows, isLoading } = this.state
        const columns = new Table.Headers(MemberSummary, 'sin, name, dob, contactOf, email.longDesc, phone.number, spouseName, beneficiaries, ppJnDt, ppEr, lastPPEvents.statusDesc')
        columns['sin'].headerFilter = true
        columns['sin'].width = 160
        columns['name'].headerFilter = true
        columns['name'].width = 380
        columns['dob'].width = 150
        columns['contactOf'].width = 100
        columns['contactOf'].visible = filters.contacts.on
        columns['email.longDesc'].title = 'Email'
        columns['email.longDesc'].width = 300
        columns['email.longDesc'].visible = filters.contacts.on
        columns['phone.number'].width = 200
        columns['phone.number'].visible = filters.contacts.on 
        columns['spouseName'].title = 'Spouse'
        columns['spouseName'].visible = filters.spouse.on
        columns['spouseName'].width = 300
        columns['beneficiaries'].visible = false
        columns['ppJnDt'].width = 150
        columns['ppEr'].headerFilter = true
        columns['ppEr'].width = 130
        columns['lastPPEvents.statusDesc'].title = "PP Status Description"
        columns['lastPPEvents.statusDesc'].headerFilter = true
       
        const filtersOn = Object.values(filters).filter(f => f.on).map(f => f.prop)
        const filtered = filtersOn.length === 0 ? rows : rows.filter(row => filtersOn.find(filter => row[filter]))
        return <>
             <Row className='align-items-end' >
                <Col><PageTitle className='mt-1'>Members</PageTitle></Col>
            </Row>
            <Card framed>
                <Row className='mb-2 mt-2 mr-2'>
                    <Col right>
                        <Button key="new-employee" className='btn btn-secondary' onClick={this.handleNewEmployee.bind(this)}>New Employee</Button>
                    </Col>
                    <div>
                    <Icon onClick={this.swap.bind(this)} className='ml-3 mt-3' family='fas' icon='bars' large />
                        <div className={this.state.show + 'dropdown-menu dropdown-menu-right mr-4 mt-1 pr-2'} onMouseLeave={this.handleMouseOut.bind(this)}>
                            {Object.values(filters).map(option => <Check value={option.on} label={option.text} lcn='checkbox-inline' onChange={this.handleFilterChange.bind(this, option.key)} />)} 
                        </div>
                    </div> 
                </Row>
            </Card>
            {!showDetails && !isLoading && <Table id='members-table' 
                dataKey='membership.keyValue' 
                data={filtered} 
                columns={columns} 
                onTableLoad={(table) => this.table = table} 
                editable
                sort='name'
                onSelect={this.handleSelect.bind(this)}
                selected={selected && selected.person && selected.person.id}
                initialHeaderFilter={this.state.tableHeaderFilters} 
                initialSort={this.state.tableSorters} 
            />}
            {isLoading && <div style={{textAlign: 'center'}}>Refreshing data...</div>}
            {showDetails && selected && selected.isMember && <Modal className='h-100 w-80 modal-bg-color'>
                <MemberDetails 
                    activeTab={showDetails} 
                    personId={selected.id} 
                    handleUpdateLastPPEvents={this.handleUpdateLastPPEvents.bind(this)}
                    onCancel={this.handleCancel.bind(this)} 
                    onDelete={this.handlePersonDelete.bind(this)} 
                    onUpdate={this.handleStatusUpdate.bind(this)} 
                    onExistingPerson={this.handleSwitchPerson.bind(this)} 
                    notify={this.notify.bind(this)}
                />
            </Modal>}
            {showDetails && selected && !selected.isMember && <Modal className='h-100 w-80 modal-bg-color'>
                <PersonForm personId={selected.id} onCancel={this.handleCancel.bind(this)} notify={this.notify.bind(this)}/>
            </Modal>}
            {addNewEmployee && <Modal onHide={this.handleCancel.bind(this)} className='h-100 w-80 modal-bg-color'>
                <EmploymentForm ee={new Employment()} onCancel={this.handleCancel.bind(this)} onSave={this.handleSaveNewEmployee.bind(this)} notify={this.notify.bind(this)}/>
            </Modal>}
        </>
    }
    swap() { this.setState({show: this.state.show ? '' : 'show '}) }
    handleMouseOut(e) { this.setState({show: ""}) }
    handleStatusUpdate() { this.setState({touched: true}) }
    handleFilterChange(key, val) { 
        const filter = this.state.filters
        filter[key].on = val
        if (val === true) { 
            if (key === 'contacts') { 
                filter.members.on = false 
                filter.spouse.on = false 
            } else if (key === 'spouse') {
                filter.members.on = false 
                filter.contacts.on = false 
            } 
        } 
       this.setState({filters: filter})
    }
    handleFilterCancel(key) {
        const filters = this.state.filters
        filters[key].on = false
        this.setState({filters: filters })
    }
    handleSelect(row) { this.setState({selected: row, showDetails: true, tableHeaderFilters: this.table.getHeaderFilters(), tableSorters: this.table.getSorters()}) }
    
    handleUpdateLastPPEvents(membership) {
        const memberSummary = this.state.rows.find(mem => mem.keyValue === membership.keyValue);
        memberSummary.lastPPEvents = membership.lastParticipation.eventStatuses;
    }

    handleCancel() { 
        this.setState({ showDetails: false });
    }
    
    handleSwitchPerson(existingPerson) { this.setState({showDetails: true, selected: this.state.rows.find(row => row.person.id === existingPerson.id) }) }
    
    //Handle Bulk Upload members/employee information events
    handleUploadInfo() { this.setState({showUpload: true}) }
    handleCancelUploadInfo() { this.setState({showUpload: false}) }
    handleSaveUploadInfo() { this.setState({showUpload: false}) }

    //New Employment Handlers
    handleNewEmployee() { this.setState({ addNewEmployee: true }) }
    handleSaveNewEmployee() {
        this.busy(() => {
            return MembershipService.getMembersSummary({refresh: true}).then(summaries => {
                this.setState({addNewEmployee: false, rows: summaries})
                if(this.props.notify) this.props.notify('New Employment Saved', 'success')
            })
        })
    }
    handlePersonDelete(isDelete){
        this.setState({isLoading: true, showDetails: false, addNewEmployee: false})
        return isDelete ? MembershipService.getMembersSummary({refresh: true}).then(summaries => {
            this.setState({rows: summaries, isLoading: false})
        }) : this.setState({isLoading: false})
    }

}
