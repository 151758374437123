import { Definition, Ref, RefEvent } from '../../framework/infra'
import { round, zeroForInfinity, moment, sum } from '../../framework/utils/helper'
import { Period } from '../../framework/utils'
import Earnings from '../financial/earning/Earnings'
import { Contributions } from './contributions'
import { Employment, EmploymentStatus } from '../employment'
import {RemittanceMessage, RemittanceMessages} from './RemittanceMessage'
import RemittanceDetails from './RemittanceDetails'
import ParticipationEvents from '../membership/participation/ParticipationEvents'
import { YearEndMessageEvents } from "../yearEnd/YearEndMessageEvent";

const { AMOUNT, NUMBER, STRING, BOOLEAN, DATE, PERIOD } = Definition.types
export default class RemittanceDetail extends Ref {
    
    get message() { return this.ppStatus.shouldShowMessage()
        ? this.messages
            .sort((a,b) => b.getSeverityPonderation() - a.getSeverityPonderation())[0]
        : this.messages.filter(message => message.config.alwaysShow)[0];
    }

    get period() { return this.remittance.period }
    get periodText() { return this.remittance.period.text }
    get person() { return this.employment.person }
    get rates() { return this.historicRates.getRatesAtPeriod(this.period) }
    get historicRates() { 
        let historicRate = this.employment.employer.plan.historicRates;
        if (historicRate.length > 0) return historicRate; 
        return this.remittance.employer.plan.historicRates;
    }

    get employmentEventStatuses() { return this.employment 
        ? this.employment.eventStatuses.getDuring(this.period.timestampAtPeriodStart, this.period.timestampAtPeriodEnd)
        : [] 
    }
    get empStatusEvent() { 
        return this.employment.eventStatuses.getAt(this.period.timestampAtPeriodEnd) 
    }
    get employmentStatus() { return this.empStatusEvent.status }
    get ppStatusEvent() { return this.employment.participation.eventStatuses.getAt(this.period.timestampAtPeriodEnd) }
    get ppStatus() { return this.ppStatusEvent.status }
    //gets relevant pp event status for that month or year
    get ppStatusOfPeriod() { 
        var descriptionEvents = new ParticipationEvents(this.employment.participation.events.filter(x=> x.config.status || x.config.includeInDesc));
        descriptionEvents.sortEvents();
        var eventsDuring = new ParticipationEvents(descriptionEvents.getDuring(this.period.timestampAtPeriodStart, this.period.timestampAtPeriodEnd));
    
        return eventsDuring.statusDesc;
    }
    get employmentPpStatusDesc() { return this.employmentStatus.desc + (this.ppStatusOfPeriod && ' - (' + this.ppStatusOfPeriod + ')')}
    get employmentStatusEffDate() {
        const showJoinDate = this.empStatusEvent.config.isHiredEvent && this.ppStatus.isActive() && this.ppStatusEvent.config.showJoinDate && this.empStatusEvent.ets < this.ppStatusEvent.ets;
        const showEligDate = this.empStatusEvent.config.isHiredEvent && this.ppStatus.isEligiblePeriod() && this.empStatusEvent.ets < this.ppStatusEvent.ets;
        if(showJoinDate) return this.employment.participation.joinDt ?? this.participation.joinDt;
        else if(showEligDate) return this.employment.participation.lastEligibility ?? this.participation.lastEligibility;
        else return this.empStatusEvent.effDt;
    }
    get employmentStatusAtYearEnd() { return this.employment.eventStatuses.getAt(this.period.yearEndPeriod.timestampAtPeriodEnd).status }
    get totalEmployeeContributions() { return this.contributions.total + this.adjustmentContributions.total }

    get ytdContributions() { return Contributions.sum([this.ytpContributions, this.contributions, !this.prevYearAdjustment ? this.adjustmentContributions : null]) }
    get ytdPrevYearAdjustment() { return this.ytpPrevYearAdjustment + this.prevYearAdjustment }
    get ytdEarnings() { return Earnings.sum([this.ytpEarnings, this.earnings, this.adjustmentEarnings]) }
    get creditedService() { return this.calculateCreditedService() }
    get ytdCreditedService() { 
        var cs = round(this.ytpCreditedService + this.calculateCreditedService(this.isClosedOrPendingThisYear()), 6)
        if (this.period.yearEnd) cs = Math.max(Math.min(round(cs, 6), 12), 0)
        return round(cs, 6)
    }
    get workSchedule() { return this.employment.workSchHistory.getAt(this.period.timestampAtPeriodEnd).value }

    get indexedEarnings() {
        const ltdStatus = this.employment.eventStatuses.getInterval(this.period.timestampAtPeriodStart, this.period.timestampAtPeriodEnd).find(evt => evt.status.isLtd());
        if (ltdStatus && this.historicRates) { //just checking this.historicRates so it doesn't if not loaded it (remove in dev)
            const startYear = moment(ltdStatus.effDt).year()
            const currentYear = Number(this.period.year)
            if (currentYear > startYear) { 
                const nonLtdEarnings = this.ytdEarnings.pensionable - this.ytdEarnings.ltd
                const elapsedRatio = (ltdStatus.effPeriod.numberOfDaysInYear - moment(ltdStatus.effDt).dayOfYear() + 1) / ltdStatus.effPeriod.numberOfDaysInYear
                var indexedLtd = this.ytdEarnings.ltd * (1 + (elapsedRatio * this.historicRates.getRatesAtPeriod(ltdStatus.effPeriod).aiwRate))
                for (var year =  startYear + 1; year < currentYear; year++) {
                    indexedLtd *= (1 + this.historicRates.getRatesAtPeriod(Period.create(String(year))).aiwRate)
                }
                return indexedLtd + nonLtdEarnings
            }
        }
        return this.ytdEarnings.pensionable
    }
    get ytdCreditedServiceRegular() { return Math.min(round((this.ytdEarnings.regularHours/this.workSchedule.yearlySchedule * 12), 6), 12)}
    get annualizedEarningsRegular() { return round(zeroForInfinity(this.ytdEarnings.regular / this.ytdCreditedServiceRegular * 12))}
    get annualizedEarnings() { return round(zeroForInfinity(this.indexedEarnings / this.ytdCreditedService * 12))}
    get pensionAdjustment() { return round(zeroForInfinity( Math.max(Math.min((9 * (0.02 * this.annualizedEarnings - Math.min(this.rates.ympe, this.annualizedEarnings) * (this.employment.isCQ === 'y' ? 0.005 : 0))), this.rates.moneyPurchaseLimit) * this.ytdCreditedService / 12 - 600 * this.ytdCreditedService / 12, 0)), 0) + this.ytdContributions.vol}
    get yeValidated() { return this.reviewedCmt && String(this.reviewedCmt).toLowerCase().includes('validated') }
    get totalMatEarningsAmount() { return (this.earnings.m1?.amount ?? 0) + (this.adjustmentEarnings.m1?.amount ?? 0) }
    get totalLTDEarningsAmount() { return (this.earnings.l1?.amount ?? 0) + (this.adjustmentEarnings.l1?.amount ?? 0) }
    get totalSelfEarningsAmount() { return (this.earnings.s1?.amount ?? 0) + (this.adjustmentEarnings.s1?.amount ?? 0) }
    get totalMatEarningsHours() { return (this.earnings.m1?.hours ?? 0) + (this.adjustmentEarnings.m1?.hours ?? 0) }
    get totalLTDEarningsHours() { return (this.earnings.l1?.hours ?? 0) + (this.adjustmentEarnings.l1?.hours ?? 0) }
    get totalSelfEarningsHours() { return (this.earnings.s1?.hours ?? 0) + (this.adjustmentEarnings.s1?.hours ?? 0) }

    get ytdPriorAdjustment() {return sum(this.allYearAdjustment._list.filter(adj => adj.targetPeriod.moment < this.period.timestampAtPeriodStart), 'totalDetail',3)}

    get sumContributions() {return Contributions.sum([this.contributions, this.adjustmentContributions])}
    get sumEarnings() { return Earnings.sum([this.earnings, this.adjustmentEarnings]) }
    get activeDays() {return this.employment.getContributingDaysInYear(this.period)}
    get yearActiveDays() {return this.employment.getContributingDaysInYear(this.period, true)}

    get lastWorkDay() {
        return this.employment.firedQuitDate;
    }

    calculateCreditedService(excludeOvertime) {
        if (this.ppStatus.isActive() || this.isPartiallyActive(this.employment.participation.eventStatuses.getDuring(this.period.timestampAtPeriodStart, this.period.timestampAtPeriodEnd))){ 
            var crHours = this.earnings.hours + this.adjustmentEarnings.hours;
            var ovHours = (excludeOvertime ? (this.earnings.overtimeHours + this.adjustmentEarnings.overtimeHours) : 0);
            var monthly =  this.workSchedule.monthlySchedule;
            return round((crHours - ovHours)/monthly, 6);
        }
        return 0;
    }

    isClosedOrPendingThisYear() {
        let yearEnd = this.period.yearEndPeriod;
        var descriptionEvents = new ParticipationEvents(this.employment.participation.events.filter(x=> x.config.status || x.config.isPendingEvent));
        let yearlyEventStatuses = new ParticipationEvents(descriptionEvents.getDuring(yearEnd.timestampAtPeriodStart, yearEnd.timestampAtPeriodEnd));
        let event = yearlyEventStatuses.getAt(yearEnd.timestampAtPeriodEnd);

        return event.config.isPendingEvent || event?.status?.isClose();
    }

    isPending() {
        return this.isClosedOrPendingThisYear() && !this.ppStatus.isClose();
    }

    isMidMonthActive(events){
        return events.find(ev => ev.status.isActive() && ev.day !== 1);
    }

    isPartiallyActive(events){
        return events.length > 1 ? events.find(ev => ev.status.isActive()) : false;
    }

    isMidMonthRetired(events){
        const over60 = this.employment.participation.person.isOverAge60();
        const retired = events.find(ev => ev.config.isRetirementEvent && ev.day !== 1);
        return over60 && retired ? retired : undefined
    }

    isPartiallyRetired(events){
        const over60 = this.employment.participation.person.isOverAge60();
        const retired = events.length > 1 ?  events.find(ev => ev.config.isRetirementEvent) : undefined;
        return over60 && retired ? retired : undefined
    }

    isLTDtoTerminated(includeReviewed = false) {
        const hasLTD = this.employmentEventStatuses.find((event) =>
            includeReviewed
                ? event.status.isLtd() && includeReviewed && event.reviewed
                : event.status.isLtd() && !event.reviewed
        );
        const hasTerminated = this.employmentEventStatuses.find((event) =>
            event.status.isPermanentTerminated()
        );
        return hasLTD && hasTerminated && (hasLTD.ets < hasTerminated.ets);
    }

    isDeemedToFiredQuit() {
        const hasDeemed = this.employmentEventStatuses.find((event) =>
            event.status.isDeemedStatus()
        );
        const hasTerminated = this.employmentEventStatuses.find((event) =>
            event.status.isFiredQuit()
        );
        return hasDeemed && hasTerminated && hasDeemed.ets < hasTerminated.ets;
    }

    containsProgressiveReturn() {
        return this.employmentEventStatuses.find(empEvent => empEvent.status.isProgressiveReturn());
    }

    hasNoFinancialData(yearEnd = true) {
        let hasNoTotals = (
            this.contributions.total === 0 && 
            this.earnings.total === 0 &&
            this.adjustmentContributions.total === 0 &&
            this.adjustmentEarnings.total === 0);

        return yearEnd ? (this.ytdEarnings.pensionable === 0 && this.ytdContributions.total === 0 && hasNoTotals) 
                       : hasNoTotals
    }

    isRelevant() {
        if(moment(this.employment.hiredDate).isAfter(this.period.timestampAtPeriodEnd)) return false;
        // if the PP was closed last year and no earnings/contribution in period, we can exclude
        const hasNoFinancialDataCurrentPeriod = this.period.yearEnd ?  (this.hasNoFinancialData() && this.ytdPriorAdjustment === 0) : this.hasNoFinancialData(false);

        const shouldHaveDeemedContribs = this.employment.eventStatuses
            .getAllDuringWithEndTs(
                this.period.timestampAtPeriodStart,
                this.period.timestampAtPeriodEnd
            )
            .find(
                (event) =>
                    event.status.isDeemedStatus() ||
                    event.status.isProgressiveReturn()
            );
        const excludePP = this.employment.isCloseInPriorYear(this.period) && hasNoFinancialDataCurrentPeriod;
        const isRIM = this.employment.participation.lastStatusEvent.config.isReimbursment;

        return (!excludePP && !isRIM) || shouldHaveDeemedContribs;
    }

    isEmpty() { return !this.employment.person.id } 
    onError() { return this.messages.filter(msg => msg.isError()).length > 0 }

    //adds all ajdustments from jan to current period in the ytps
    addAdjustmentsToYTP(adjustments) {
        adjustments.forEach((adjustment => {
            //contribs
            if(moment(adjustment.effDate).format('YYYY') === this.period.year){
                this.ytpContributions.addAjdContrib(adjustment);
            }
            //earnings
            const currentPPEvents = this.employment.participation.eventStatuses.getDuring(adjustment.period.timestampAtPeriodStart, adjustment.period.timestampAtPeriodEnd);
            const lastEvent = currentPPEvents.last;
            if (lastEvent?.status.isActive() || this.isPartiallyActive(currentPPEvents) || this.isPartiallyRetired(currentPPEvents)) { 
                this.ytpEarnings.add(adjustment.adjustmentEarnings);
            }
        }))
    }

    initDetailAdjustment (adjustments){
        this.adjustments.reset();
        this.adjustmentEarnings.reset();
        this.adjustmentContributions.reg = 0;
        this.adjustmentContributions.vol = 0;
        this.adjustmentContributions.mat = 0;
        this.adjustmentContributions.ltd = 0;
        this.adjustmentContributions.slf = 0;
        adjustments
            .filter(
                (adj) => (adj.remDetail && adj.remDetail.keyValue === this.keyValue) 
                    || (adj.keysValues.participation === this.keysValues.participation) 
                    && adj.period.isSame(this.period)
            )
            .forEach((adj) => {
                this.adjustments.push(adj);
                this.adjustmentEarnings.add(adj.distributionEarning.all);
                this.adjustmentContributions.addAjdContrib(adj);
            });
        if(this.period.yearEnd){
            adjustments.filter(adj => adj.remittance.keysValues.employer === this.remittance.keysValues.employer && adj.participation.keyValue === this.participation.keyValue && adj.period.year === this.period.year).forEach(adj => {
                this.allYearAdjustment.push(adj);
            })
        }
    }

    static key = ['remittance', 'participation' ]
    static refList = RemittanceDetails
    static definitions = {
        remittance:  { key: true, ref: require('./Remittance') },
        participation: { key: true, ref: require('../membership/participation/Participation'), text: 'Participation' },
        contributions: { ref: require('./contributions/Contributions'), text: 'Contributions' },
        earnings: { ref: require('../financial/earning/Earnings'), text: 'Earnings' },
        workedDays: { type: NUMBER, text: 'Days worked in month' },
        messages: { ref: RemittanceMessages, text: 'Errors/Warnings' },
        cmt: { type: STRING, text: 'Comments'},
        financialCmt: { type: STRING, text: 'Financial Comments'},
        reviewedCmt: { type: STRING, text: 'Admin Validation Comments'},
        yeValidationEvents: { ref: YearEndMessageEvents },
    
        period: { abstract: true, type: PERIOD, text: 'Period' },
        periodText: { abstract: true, type: STRING, text: 'Period' },
        person: { abstract: true, ref: require('../person/Person'), text: 'Person' },
        rates: { abstract: true, inherited: true, ref: require('../pension/Rates'), text: 'Rates', text_fr: 'Rates'  }, //TODO support 'inherited'
        historicRates: {abstract: true, inherited: true, ref: require('../pension/HistoricRates') },

        employment: { transient: true, ref: require('../employment/Employment'), text: 'Employment' },

        ytpContributions: { transient: true, ref: require('./contributions/Contributions'), text: 'YTP Contributions' },
        ytpEarnings: { transient: true, ref: require('../financial/earning/Earnings'), text: 'YTP' },
        ytpCreditedService: { transient: true, type: NUMBER, text: 'YTP Credited Service' },
        ytpPrevYearAdjustment: { transient: true, type: AMOUNT, text: 'YTP Prev Year Adjustment' },
        
        adjustments: { transient: true, ref: require('./adjustment/Adjustments'), text: 'Adjustments' },
        adjustmentContributions: { transient: true, ref: require('./contributions/Contributions'), text: 'Contribution Adjustments' },  
        adjustmentEarnings: { transient: true, ref: require('../financial/earning/Earnings'), text: 'Earnings Adjustments' },  
        prevYearAdjustment: { transient: true, type: AMOUNT, text: 'Previous Year Adjustments' },
        allYearAdjustment: {transient: true, ref: require('./adjustment/Adjustments'), text: 'All Year adjustments' },
    
        employmentPpStatusDesc: { abstract: true, type: STRING, text: 'Status' },
        employmentStatusEffDate: { abstract: true, type: DATE, text: 'Effective Date' },
        lastWorkDay: { abstract: true, type: DATE, text: 'Last Work Date' },
        empStatusEvent: { abstract: true, ref: EmploymentStatus, text: 'Last Employee Status Event' },
        employmentStatus: { abstract: true, ref: EmploymentStatus, text: 'Last Employee Status' },
        ppStatus: { abstract: true, ref: require('../membership/participation/ParticipationStatus'), text: 'Participation Status' },
        ppStatusEvent: { abstract: true, ref: require('../membership/participation/ParticipationEvent'), text: 'Participation Event' },
        workSchedule:{ abstract: true, ref: Employment.definitions.workSch.ref, text: 'workSchedule' },
        totalEmployeeContributions: { abstract: true, type: AMOUNT, text: 'Total Employee Contributions'}, //include adjustments' 
        annualizedEarnings: { abstract: true, type: AMOUNT, text: 'Annualized earnings' },
        pensionAdjustment: { abstract: true, type: AMOUNT, text: 'Pensionable Adjustment' },
        ytdContributions: { abstract: true, ref: require('./contributions/Contributions'), text: 'YTD Contributions' },
        ytdEarnings: { abstract: true, ref: require('../financial/earning/Earnings'), text: 'YTD' },
        ytdCreditedService: { abstract: true, type: NUMBER, text: 'YTD Credited Service' },
        ytdPrevYearAdjustment: { abstract: true, type: AMOUNT, text: 'Prev Year Adjustments' },
        creditedService: { abstract: true, type: NUMBER, text: 'Credited Service' },
        yeValidated: { abstract: true, type: BOOLEAN, text: 'Validated' },
        ytdPriorAdjustment: {abstract: true, type: NUMBER, text: 'YTD adjustment'},
        isYMPEReached: {abstract: true, type: BOOLEAN, text: "YMPE Reached?"},

        totalMatEarningsAmount: {abstract: true, type: AMOUNT},
        totalLTDEarningsAmount: {abstract: true, type: AMOUNT},
        totalSelfEarningsAmount: {abstract: true, type: AMOUNT},
        totalMatEarningsHours: {abstract: true, type: NUMBER},
        totalLTDEarningsHours: {abstract: true, type: NUMBER},
        totalSelfEarningsHours: {abstract: true, type: NUMBER},

        sumContributions : { abstract: true, ref: require('./contributions/Contributions'), text: 'Contributions Sum' },
        sumEarnings:{ abstract: true, ref: require('../financial/earning/Earnings'), text: 'Earnings Sum' },
        yearActiveDays: {abstract: true, type: Definition.types.NUMBER, text: 'Total Active Days'},
        activeDays: {abstract: true, type: Definition.types.NUMBER, text: 'Active Days'},
        message: { abstract: true, ref: RemittanceMessage, text: 'Errors/Warnings' }, //TEMP
    }

    
}



