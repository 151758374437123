import React from 'react'
import { Ref, Definition } from '../../../framework/infra'

import { Component, EInput } from '../../../framework/components'
import { Card, Col, FieldSet, Row } from '../../../framework/containers'
import { Table, Button } from '../../../framework/controls'
import { getSafe, moment, removeDuplicates, sort } from '../../../framework/utils/helper'
import { Excel, Period } from '../../../framework/utils'

export default class ReportLayout extends Component {
    load() {
        const that = this
        
        class FilterRef extends Ref {
            static definitions = Object.getOwnPropertyNames(that.filters).reduce((defs, filterName) => {
                if(that.filters[filterName].definition) defs[filterName] = that.filters[filterName].definition
                else defs[filterName] = that.entity.getDefinition(filterName)
                return defs
            }, {})
        }
        class QueryParams extends Ref {
            static definitions = Object.getOwnPropertyNames(that.params).reduce((defs, pName) => {
                if(that.params[pName].definition) defs[pName] = that.params[pName].definition
                else defs[pName] = that.entity.getDefinition(pName) || new Definition()
                return defs
            }, {})
        }

        const state = {
            data: [],
            // headers: new Table.Headers(this.entity, this.headers),
            filtersInstance: new FilterRef(Object.getOwnPropertyNames(this.filters).reduce((initialValues, filterName) => {
                if (this.filters[filterName].initialValue) initialValues[filterName] = this.filters[filterName].initialValue
                return initialValues
            }, {})),
            queryParamsInstance: new QueryParams(Object.getOwnPropertyNames(this.params).reduce((initialValues, pName) => {
                if (this.params[pName].initialValue) initialValues[pName] = this.params[pName].initialValue
                return initialValues
            }, {})),
            FilterRef: FilterRef,
            QueryParams: QueryParams,
        }
        
        return this.execQuery(state.queryParamsInstance).then(data => {
            setFilterValues(this.filters, data, FilterRef)
            state.data = data
            return state
        })
    }

    view() {
        const { queryParamsInstance, filtersInstance, data, selectedPage, showDetails } = this.state
        const headers = new Table.Headers(this.entity, this.headers, this.headerProps)

        const filteredData = Object.getOwnPropertyNames(this.filters).reduce((fData, pName) => {
            const value = filtersInstance[pName]
            if (value && !this.isEmpty(value)) {
                fData = fData.filter(v => getSafe(v, pName) === value)
            }
            return fData
        }, data)
        if (this.groupHeaders) {
            this.groupHeaders.forEach(path => {
                const header = headers[path] = new Table.Header(this.entity, path, this.headerProps[path])
                const columns = this.subHeaders.reduce((cols, sub) => {
                    cols.push(path + `.${sub}`)
                    return cols
                }, [])
                header.headers = new Table.Headers(this.subEntity, columns) 
            })
        }

        return <>
            <Card className='p-3' framed>
                <Row className='input-spacing-2'>
                    <Col span='3'>
                        <FieldSet className={this.fieldsetClass} title={this.reportDesc} variant='page'>
                            <Row className='input-spacing-2 '>
                                {Object.getOwnPropertyNames(this.params).map(pName => {
                                    return <EInput name={pName} className={this.params[pName].className} instance={queryParamsInstance} onChange={this.handleParamsChange.bind(this)}/>
                                })}
                                </Row>
                        </FieldSet>
                    </Col>
                    <Col className='ml-3 align-self-end '>
                        <Row className='input-spacing-2'>
                            {Object.getOwnPropertyNames(this.filters).map(pName => {
                                return <EInput name={pName} instance={filtersInstance} className={this.filters[pName].className} options={this.filters[pName].options.sort((a,b) => {
                                    if(a.text < b.text) { return -1; }
                                    if(a.text > b.text) { return 1; }
                                    return 0;
                                })} nullable={this.filters[pName].nullable} onChange={this.handleFilterChange.bind(this)}/>
                            })}
                        </Row>
                    </Col>
                    <Col className='align-self-end' right>
                        <Button key='export' className='btn-secondary' onClick={this.handleExport.bind(this, filteredData)}>Export</Button>
                    </Col>
                </Row>
            </Card>
          
            <Table id='reports-table' 
                dataKey='id' 
                data={filteredData} 
                columns={headers} 
                onTableLoad={(table) => this.table = table} 
                sort={this.tableSort}
                onSelect={this.handleSelect.bind(this)}
                removeDuplicateRows={this.removeDuplicateRows}
            />
            {showDetails && selectedPage}
        </>     
    }
    handleParamsChange() {
        const { queryParamsInstance, FilterRef } = this.state
        return this.busy(() => this.execQuery(queryParamsInstance).then(data => {
            setFilterValues(this.filters, data, FilterRef)
            this.setState({data: data})
        }))
    }
    
    handleFilterChange(val) { this.setState({touched: true}) }
    handleSelect(row) { 
        const page = this.handleRowSelect ? this.handleRowSelect(row) : null
        this.setState({selectedPage: page, showDetails: true})
    }
    handleCancel() { 
        this.setState({showDetails: false})
        return this.busy(() => this.execQuery().then(data => {
            setFilterValues(this.filters, data, this.state.FilterRef)
            this.setState({data: data})
        }))
    }
    handleExport(data) {
        const { queryParamsInstance } = this.state
        const excel = new Excel(this.reportDesc)
        const headers = new Excel.Headers(this.entity, this.headers)
        if (this.groupHeaders) {
            this.groupHeaders.forEach(path => {
                const header = headers[path] =  new Excel.Header(this.subEntity, "")
                const columns = this.subHeaders.reduce((cols, sub) => {
                    cols.push(path + `.${sub}`)
                    return cols
                }, [])
                header.headers = new Excel.Headers(this.subEntity, columns) 
            })
        }
        if (this.tableSort) data = sort(data, this.tableSort)
        if (this.headerProps) this.applyHeaderProps(headers)
        excel.addSheet(headers, data, this.reportDesc)
        if (queryParamsInstance && (queryParamsInstance.from || queryParamsInstance.to)) this.addPeriods(excel, this.reportDesc, queryParamsInstance)
        return excel.download()
    }
    addPeriods(excel, sheetname, dateRange) {  //TODO - Loop over the params and filters and put all
        const ws = excel.findSheet(sheetname)
        const periodStartDate = dateRange.from instanceof Period ? dateRange.from.text : dateRange.from
        const periodEndDate = dateRange.to instanceof Period ? dateRange.to.text : dateRange.to
        ws.addRows([[], [`Start Date: ${periodStartDate}`], [`End Date: ${periodEndDate}`], [], [`Printed On: ${moment().format('LLLL')}`]])
        return ws
    }
    applyHeaderProps(headers) {
        Object.getOwnPropertyNames(this.headerProps).forEach(props => {
            Object.getOwnPropertyNames(this.headerProps[props]).forEach(prop => {
                headers[props][prop] = this.headerProps[props][prop]
            })
        })
        return headers
    }
    isEmpty(val) {
        if ((val instanceof Ref && !(val.desc || val.id || val.key)) || val.length === 0) return true
        else return false
    }

    handleCellSelect(e, cell) {
        e.stopPropagation()
        var page = this.handleCellClick ? this.handleCellClick(cell) : null
        this.setState({selectedPage: page, showDetails: true}) 
	}
}

function setFilterValues(filters, data, FilterRef) {
    Object.getOwnPropertyNames(filters).forEach(pName => {
        const filter = filters[pName]
        if (FilterRef.ownDefinitions[pName].isRef()) {
            const pList = data.map(v => getSafe(v, pName))
            var filterList = removeDuplicates(pList, 'keyValue')
            if (filter.sortBy) filterList = sort(filterList, filter.sortBy)
            filter.options = filterList.map(entity => ({key: (entity.keyValue), text: (entity[filter.display || entity.desc]), value: entity}))
        } else {
            filter.options = FilterRef.ownDefinitions[pName].options
                ? FilterRef.ownDefinitions[pName].options.map((option) => ({
                      key: option.key,
                      text: option.text,
                      value: option.key,
                  }))
                : null;
        }
    })

}