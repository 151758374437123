import { Entity, Definition, RefMap}  from '../../framework/infra'
import { concatStr, mask, moment } from '../../framework/utils/helper'

import PersonMessages from './PersonMessages'
import SpouseHistory from './SpouseHistory'

export default class Person extends Entity {
    get desc() { return concatStr(this.title, '. ') + concatStr(this.firstName, ' ')  + concatStr(this.middleName) + concatStr(this.lastName) }
    get name() { return (concatStr(this.lastName,', ') + concatStr(this.firstName, ' ') + concatStr(this.middleName)) || '' }
    get formatedSin () { return this.sin ? mask(this.sin, Definition.types.SIN.mask) : null } //TODO if is not a definition then should be a function
    get searchDesc () { return this.formatedSin + ' - ' + this.name }
    get defaultPhone() { return this.phones && this.phones[0] }
    get defaultEmail() { return this.emails && this.emails[0] }
    get alternatePhone() { return this.phones && this.getContact(this.phones) } //change the name with sth else-- formatted phones
    get alternateEmail() { return this.emails && this.emails.slice(0,2).map(contact => concatStr(contact.usage,': ') + concatStr(contact.email)).join(' - ') }
    get spouseQue () { return this.findSpouse('que') }
    get spouseFed () { return this.findSpouse('fed') }
    
    findSpouse(jurisdiction) {
        return this.spouseHistory.findSpouse(jurisdiction);
    }

    getContact(contacts) {
        return contacts.filter(cont => cont.usage !== 'fax').slice(0,2).map(contact => concatStr(contact.usage,': ') + concatStr(contact.number)).join(' - ')   
    }
    get sin() { return this.__sin }
    set sin(val) { this.__sin = String(val).replace(/[^\d]/g, "") }
    
    getBirthdayForAge(age) {return moment(this.dob).add(12 * age,'months').format('YYYY-MM-DD')}
    sixtyfifthBirthday() { return moment(this.dob).add(780,'months').format('YYYY-MM-DD') }
    sixtiethBirthday() { return moment(this.dob).add(720,'months').format('YYYY-MM-DD') }
    seventyfirstBirthday() { return moment(this.dob).add(852,'months').format('YYYY-MM-DD') }
    // //rule
    isOverAge60() { 
        const today = moment().format('YYYY-MM-DD')
        return this.sixtiethBirthday() < today
    }
    isTurning60inNext3Months() {
        const today = moment().format('YYYY-MM-DD')
        if (today < this.sixtiethBirthday() && this.sixtiethBirthday() <= moment(today).add(3, 'months').format('YYYY-MM-DD')) { 
            return true
        }
    }
    isTurning71inNext3Months() {
        const today = moment().format('YYYY-MM-DD')
        if (today < this.seventyfirstBirthday() && this.seventyfirstBirthday() <= moment(today).add(3, 'months').format('YYYY-MM-DD')) { 
            return true
        }
    }

    static refMap = class People extends RefMap {}
    static definitions = {
        sin: { type: Definition.types.SIN, text: 'SIN', text_fr: 'NAS' },
        title: { type: Definition.types.CHOICE, text: 'Title', text_fr: 'Titre', options: [
            { key: '', text: '', text_fr: ''},
            { key: 'mr', text: 'Mr', text_fr: 'M.'},
            { key: 'ms', text: 'Ms', text_fr: 'Mme'},
        ]},
        firstName: { type: Definition.types.STRING, text: 'First Name', text_fr: 'Prenom', required: true },
        lastName: { type: Definition.types.STRING, text: 'Last Name', text_fr: 'Nom' },
        middleName: { type: Definition.types.STRING, text: 'Middle Name' },
        maidenName: { type: Definition.types.STRING, text: 'Other Name' },
        gender: { type: Definition.types.CHOICE, text: 'Sex', text_fr: 'Sex', options: [
            { key: '', text: '', text_fr: ''},
            { key: 'male', text: 'Male', text_fr:'Homme'},
            { key: 'female', text: 'Female', text_fr:'Femme'},
        ]},
        lng: {
            type: Definition.types.CHOICE, text: 'Language', text_fr: 'Lang', options: [
            { key: 'en', text: 'English', text_fr:'Anglais'},
            { key: 'fr', text: 'French', text_fr:'Français'},
        ]},
        dob: { type: Definition.types.DATE, text: 'Date of Birth' },
        dod: { type: Definition.types.DATE, text: 'Date of Death' },
        address: { ref: require('../address/Address'), text: 'Address' },
        phones: { list: true, ref: require('../address/Phone'), text: 'Phones', text_fr: 'Telephone' },
        emails: { list: true, ref: require('../address/Email'), text: 'Emails' },
        spouseFed: { abstract: true, ref: require('./Spouse'), text: 'Spouse' },
        spouseQue: { abstract: true, ref: require('./Spouse'), text: 'Spouse' },
        spouseHistory: { transient: true, ref: SpouseHistory, text: 'Spouse History' },
        cmt: { type: Definition.types.STRING, text: 'Comments' },
        name: { abstract: true, type: Definition.types.STRING, text: 'Name', text_fr: 'Nom' },
        defaultPhone: { abstract: true, ref: require('../address/Phone'), text: 'Phone', text_fr: 'Telephone' },
        alternatePhone: { abstract: true, ref: require('../address/Phone'), text: 'Phone', text_fr: 'Telephone' },
        defaultEmail: { abstract: true,  ref: require('../address/Email'), text: 'Email', text_fr: 'Courriel' },
        alternateEmail: { abstract: true,  ref: require('../address/Email'), text: 'Email', text_fr: 'Courriel' },
        phoneDesc: { abstract: true, ref: require('../address/Phone'), text: 'Phone', text_fr: 'Telephone' },
    }
    
}