import React from 'react'
import { Component } from '../../framework/components'
import { Row, Modal, FieldSet } from '../../framework/containers'
import { Button } from '../../framework/controls'
import PersonForm from './PersonForm'
import PersonSearch from './PersonSearch'
import PersonSummaryInfo from './PersonSummaryInfo'

export default class PersonSummary extends Component {
	view() {
		const { view, edit, search } = this.state
		const { person, readOnly, allowPersonChange, validation, validate, hideTitle, title, errors } = this.props

		return person && <FieldSet title={title}>
			<PersonSummaryInfo errors={errors} person={person} readOnly={person.isExisting() || readOnly} />
			<Row className='justify-content-end button-row mt20'>
				{(person.isExisting()) && <Button key='details' className='btn-secondary' onClick={this.handleDetails.bind(this)}>Details</Button>}
				{person.isExisting() && !readOnly && <Button key='edit' className='btn-secondary' onClick={this.handleEdit.bind(this)}>Edit</Button>}
				{!readOnly && (allowPersonChange || person.isNew()) && <Button key='search' className='btn-secondary' onClick={this.handleSearch.bind(this)}>{person.isExisting() ? 'Change' : 'Search'}</Button>}
			</Row>
			{(view || edit ) && <Modal id='personDetails' className='h-100 w-80 modal-bg-color'>
				<PersonForm person={person} hideTitle={hideTitle} onCancel={this.handleCancel.bind(this)} onSave={this.handleSavedPerson.bind(this)} readOnly={!edit} validate={validate} validation={validation} />
			</Modal>}
			{search && <Modal id='personSearch' className='h-100 w-80 modal-bg-color'>
				<PersonSearch person={person} onCancel={this.handleCancel.bind(this)} onNew={this.handleNewPerson.bind(this)} onSwitch={this.handleSwitchPerson.bind(this)} validation={validation} />
			</Modal>}
		</FieldSet> 
	}

	handleDetails() { this.setState({view: true}) }
	handleCancel() { this.setState({view: false, edit: false, search: false}) }
	handleEdit() { this.setState({view: false, edit: true}) }
	handleSearch() { this.setState({search: true}) }
	
	handleSavedPerson(person) {
        this.setState({edit: false})
		if (this.props.onSave) this.props.onSave(person)
    }
	handleSwitchPerson(selectedPerson) {
        this.setState({search: false})
		if (this.props.onSwitchPerson) this.props.onSwitchPerson(selectedPerson)
    }
	handleNewPerson(newPerson) {
        this.setState({search: false})
		if (this.props.onNewPerson) this.props.onNewPerson(newPerson)
    }
	
}