import { setAPIError } from '../../hooks/useAPIError'
import config from '../../utils/config'
import Persistence from './Persistence'
import { Ref, RefList } from './model'
import pLimit from 'p-limit'
import { chunk } from 'lodash'

export default class Service {
    constructor(entity, serviceName = '', moduleName) {
        this.entity = entity
        this.persistence = new Persistence(entity)
        this.serviceName = serviceName
        this.moduleName = moduleName || serviceName.toLowerCase()
        this.promises = {}
        this.registrations = {}
    }
    
    _setPromise(key, promise, broadcast) {
        this.broadcastChange(broadcast || key, promise)
        return this.promises[key] = promise
    }
    _getPromise(key) {
        return this.promises[key]
    }
    _getPromises(prefix) {
        return Object.getOwnPropertyNames(this.promises).filter(key => !key.startsWith('_') && (!prefix || key.startsWith(prefix))).map(key => this.promises[key])
    }
    useCache(key, promise, refresh) {
        if (refresh === true) {
            return this._setPromise(key, promise())
        } else {
            return this._getPromise(key) || this._setPromise(key, promise())
        }
    }
    invalidateCache() {
        this.promises = {}
        console.debug(`[Cache Invalidation] ${this.serviceName} Cache destroyed.`)
    }
    
    register(key, id, callback) {
        this.registrations[key] = this.registrations[key] || {}
        this.registrations[key][id] = callback
    }
    unregister(key, id) {
        if (this.registrations[key] && this.registrations[key][id])
        delete this.registrations[key][id]
    }
    broadcastChange(key, promise) {
        Object.getOwnPropertyNames(this.registrations[key] || {}).forEach(id => {
            this.registrations[key][id](promise, key, id)
        })
    }

    //Persistence Helper
    get(key, options = {}) {
        return this.useCache(key, () => this.persistence.get(this.moduleName + '_Get' + this.serviceName, key), options.refresh).then(inst => {
            if (!inst) return null
            return (options.load === false ? Promise.resolve(inst) : this.load(inst, options)).then(() => {
                if (options.link !== false) this.link(inst)
                if (options.init !== false) this.init(inst)
                return inst
            }).catch(err => console.log('Can not load instance', key, err))
        })
    }
    getAll(options = {}, batch = false) {
        const name = this.moduleName + '_Get'  + this.serviceName + 's';
        return this.useCache("_all", () => {
            return this.persistence.getAll(name).then(insts => {
                return Promise.all(insts.map(inst => this.useCache(inst.keyValue, () => Promise.resolve(inst), options.refresh)))
            })
            .then(insts => {
                if(!batch || !insts?.length || (typeof insts?.length === 'number' && insts.length < 50)) {
                    console.log('Service getAll not using pLimit, instances length:', name, insts?.length);
                    return Promise.all(insts?.map(inst => this.get(inst.keyValue, options)) ?? [])
                }

                /**
                 * Run a batch of `this.get` calls
                 * @param {*} instsBatch a subset of the `insts` array
                 * @returns a Promise will the results for the batch
                 */
                const getBatch = async (instsBatch) => {
                    return Promise.all(instsBatch.map(inst => {
                        return this.get(inst.keyValue, options);
                    }));
                };

                /**
                 * Run batches of `this.get` calls
                 * @param {*} instsBatches batches of `insts`: an array of arrays. Each item in the array is an array of size 50.
                * @returns a Promise will the results for all batches
                */
                const getBatches = async(instsBatches) => {
                    const batchesResults = [];
                    // important: must use `for` and not `forEach`
                    // because `forEach()` expects a synchronous function — it does not wait for promises.
                    // see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/forEach#description
                    for (let index = 0; index < instsBatches.length; index++) {
                        const instsBatch = instsBatches[index];
                        console.log(`Service getAll Processing batch ${index + 1} / ${instsBatches.length} (${instsBatch.length} items)`, name);
                        try{
                            const batchResult = await getBatch(instsBatch);
                            batchesResults.push(batchResult);
                        } catch(err) {
                            console.error(err);
                        }
                    }
                    const unbatchedResults =  batchesResults.flat(); // unbatch the results
                    console.log(`Service getAll Finished processing all batches, got results for ${unbatchedResults.length} items (in ${batchesResults.length} batches)`, name);
                    return unbatchedResults;
                };

                const batchSize = 50;
                const instsBatches = chunk(insts, batchSize); // split insts in arrays
                console.log(`Service getAll will process ${instsBatches.length} batches (with ${batchSize} items each)`, name);

                return getBatches(instsBatches);
            })
            .then(insts => this.toRefList(insts))
        }, options.refresh)
    }
    getList(keys, options = {}) {
        const limit = pLimit(20);
        return Promise.all(keys.map(key => limit(() => this.get(key, options)))).then(insts => this.toRefList(insts))
    }
    getBy(action, params, options = {}) {
        return this.useCache('_' + action + '_' + JSON.stringify(params), () => {
            return this.persistence.getBy(this.moduleName + '_' + action, params).then(insts => {
                return Promise.all(insts.map(inst => this.useCache(inst.keyValue, () => Promise.resolve(inst), options.refresh)))
            }).then(insts => {
                return Promise.all(insts.map(inst => this.get(inst.keyValue, options)))
            }).then(insts => this.toRefList(insts))
        }, options.refresh)
    }

    callApi(action, key, params, mapper, options = {}) {
        return this.useCache(key, async () => { 
            let items = await this.persistence.callApi(this.moduleName + '_' + action, params)
            let dataMapper = new mapper(items, options);
            let data =  await dataMapper.map();
            return data;
        }, options.refresh)
    }

    toRefList(instances) {
        const refList = new (this.entity.refMap || this.entity.refList || RefList)()
        instances.forEach(inst => refList.push(inst))
        return refList
    }

    deleteBy(action, params, options = {}) {
        return this.persistence.deleteBy(this.moduleName + '_' + action, params).then(res => {
            this.invalidateCache();
            return res
        })
    }

    /**
     * 
     * @param {*} inst the instance to update
     * @param {*} propsToUpd particular props to update, if empty, all props will be updated
     * @param {*} additionalContentToSend additional content to send to backend (ex: sending old adjustment key for updateAdjustment)
     */
    update(inst, propsToUpd = '', additionalContentToSend) {
        return this.persistence.update(this.moduleName + '_Update' + this.serviceName, inst, propsToUpd, additionalContentToSend).then(inst => {
            const response = this._setPromise(inst.keyValue, Promise.resolve(inst))
            this.invalidateCache();
            return response;
        })
    }

    updateByCallApi(apiCallName, params = {}, content) {
        return this.persistence.callApi(
            this.moduleName + "_" + apiCallName,
            params,
            content,
        )
        .then(() => {
            this.invalidateCache();
        })
        .catch((error) => {
            setAPIError(error);
            console.log(error);
            throw error;
        })
    }

    /**
     * 
     * @param {*} inst the instance to add
     * @param {*} additionalContentToSend additional content to send to backend (ex: sending old adjustment key for updateAdjustment)
     */
    add(inst) {
        return this.persistence.update(this.moduleName + '_Add' + this.serviceName, inst).then(inst => {
            const response = this._setPromise(inst.keyValue, Promise.resolve(inst))
            this.invalidateCache();
            return response;
        })
    }

	updateAll(insts, propsToUpd = '') { return this.persistence.updateAll(this.moduleName + '_Update' + this.serviceName, insts, propsToUpd).then(() => this.invalidateCache()) }
    
    //abstract
    load(inst) { return Promise.resolve(inst)}
    link(inst) { return inst }
    init(inst) { return inst }

    handleException(err){
        this.throwError('unexpectedException', {}, err)
    }
    
    throwError(code, params, err){
        const error = new Error()
        error.code = code
        //TODO look for message for error code)
        error.message = err.message || ('An Error ' + code + ' has occurred' )
        throw error
    }

    log(msg) {
        if (this.debug) console.log(('[-- ' + this.constructor.name + ' --]').padEnd(25) + msg)
    }
    logDataIntegrity(msg) {
        Service.dataIntegrity.push(msg)
        if (this.debug) console.log('Data integrity Warning! - ' + msg)
    }
    getConfig() {
        return config
    }

    async loadList(items = [], options = {}) {
        return Promise.all(items.map(async (item) => {
            let loaded = new this.entity(item);
		    if (options.load !== false){
                 await this.load(loaded, options);
            }
            if (this.link) this.link(loaded);
            if (this.init) this.init(loaded);
            return loaded;
        }));
    }

    static dataIntegrity = []
}
