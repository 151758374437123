import { EmploymentService } from '../../../services';
import RefDescription from '../../../framework/infra/model/RefDescription';
import EmployerMessageWarningReport from './EmployerMessageWarningReport';

export default class MessageWarningReport extends EmployerMessageWarningReport {
    //add extra employer filter
    filters = {
        'message._msgDesc': {definition: {ref: RefDescription, text: 'Message Type' }, display: 'name', nullable: true},
        'employment.employer': {sortBy: 'code', display: 'longDesc', nullable: true}
    }

    execQuery() {
        return EmploymentService.getAllEmployments().then(this.getWarningList);
    }
}
