import React, { useState } from "react"
import { SystemService } from '../../framework/infra'
import { Button } from '../../framework/controls'
import { Excel } from '../../framework/utils'
import { Component, PageTitle } from '../../framework/components'
import { Row, Col, SideBar } from '../../framework/containers'
import Menu from "../../framework/infra/Menu"
import YEReport from '../pension/yearend/YEReport'
import TerminationReport from '../pension/yearend/TerminationReport'
import DuplicatedSinReport from '../person/DuplicatedSinReport'
import SimilarPeopleReport from '../person/SimilarPeopleReport'
import Age60Report from "../member/reports/Age60Report"
import Age65Report from "../member/reports/Age65Report"
import Age71Report from '../member/reports/Age71Report'
import RBCPensionReport from '../employee/reports/RBCPensionReport'
import CQPPStatusReport from "../member/reports/CQPPStatusReport"
import NewMembersReport from "../member/reports/NewMembersReport"
import RemittancesSummaryReport from "../financial/remittance/reports/RemittancesSummaryReport"
import RBCPaymentsContribsReport from "../financial/payments/RBCPaymentsContribsReport"
import MessageWarningReport from '../member/reports/MessageWarningReport'
import TransferReport from "../member/reports/TransferReport"
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'
import ReportSelf from "../pension/yearend/ReportSelf"

const ReportsPage = () => {
    const [uniqueKey, setUniqueKey] = useState(0); 
    const [selectedMenu, _setSelectedMenu] = useState();
    const [exportDirectly, setExportDirectly] = useState(false);

    const setSelectedMenu = (menu) => {
        //set new key on menu click to destroy last component
        setUniqueKey(uniqueKey+1);
        _setSelectedMenu(menu);
    };

    const getMenu = () => {
        const menu = {
            members: {key: 'members', text: 'Members', open: false, subMenu: [
                {key: 'warnings', text: 'Reminders', page: MessageWarningReport},
                {key: 'age60', text: 'Members turn 60', page: Age60Report},
                {key: 'age65', text: 'Members turn 65', page: Age65Report},
                {key: 'age71', text: 'Members turn 71', page: Age71Report},
                {key: 'cqppStatus', text: 'Member CQPP/Native/Tax Status', page: CQPPStatusReport},
                {key: 'transfer', text: 'Transfer Report', page: TransferReport}
            ]},
            remSummaries: {key: 'remSummaries', text: 'Remittances', open: false, subMenu: [
                {key: 'remittance', text: 'Processing Log', page: RemittancesSummaryReport}
            ]},
            mercer: {key: 'mercer', text: 'Mercer', open: false, subMenu: [
                {key: 'newMembers', text: 'New Members', page: NewMembersReport},
            ]}, 
            rbc: {key: 'rbc', text: 'RBC', open: false, subMenu: [
                {key: 'rbcPension', text: 'RBC Report - Pensioner Taxation Status', page: RBCPensionReport},
            ]},
            auditor: {key: 'auditor', text: 'Auditor', open: false, subMenu: [
                {key: 'payment', text: 'RBC Payment and Contributions', page: RBCPaymentsContribsReport}
            ]},
            dataIntegrity: {key: 'dataIntegrity', text: 'Data Integrity', open:false, subMenu: [
                {key: 'similarPeopleReport', text: 'Possible Duplicates', page: SimilarPeopleReport, params: {year: '2020'}},
                {key: 'duplicatedSin', text: 'Duplicated SIN\'s', page: DuplicatedSinReport, params: {year: '2020'}},
                {key: 'integrityWarnings', text: 'Integrity Warnings', page: IntegrityWarningsReport}
            ]},
        }
       
        return menu
    }

    let menu = getMenu();
    return <>
            <Row className='align-items-end' >
                <PageTitle className='mt-1'>Reports
                <ToggleButtonGroup name="export-style" className="toggle-button-group" onChange={() => setExportDirectly(!exportDirectly)} value={exportDirectly}>
                    <ToggleButton value={false}>Show Table</ToggleButton>  
                    <ToggleButton value={true}>Export directly</ToggleButton>  
                </ToggleButtonGroup>
                </PageTitle> 

            </Row>
            <SideBar>
                <SideBar.Left className='w-15'>
                    <Menu menu={Object.values(menu)} active={selectedMenu} variant="sidebar" onSelect={(item) => setSelectedMenu(item)}/> {/** we put active = selectedMenu when we're using this.busy() to keep the selectedMEnu*/}
                </SideBar.Left>
                <SideBar.Body> 
                    {selectedMenu && selectedMenu.page && <selectedMenu.page key={uniqueKey} exportDirectly={exportDirectly} query={selectedMenu.query} params={selectedMenu.params} reportTitle={selectedMenu.reportTitle}/>}
                </SideBar.Body>
            </SideBar>
        </>
}
export default ReportsPage;
class IntegrityWarningsReport extends Component {
    load() {
        return {report: SystemService.getDataIntegrityWarnings()}
    }
    view() {
        return <> 
            <Row><Col right><Button key="export" type="link" onClick={this.handleExport.bind(this)} >Export</Button></Col></Row>
            {this.state.report.map(warning => <Row>{warning}</Row>)}
        </>
    }
    handleExport() {
        this.busy(() => {
            const headers = [{ title: 'Warnings', name: "warning"}]
            const values = this.state.report.map(warning => ({warning}))
            const excel = new Excel('Data_integrity_warning')
            excel.addSheet(headers, values, 'Warnings')
            return excel.download()
        })
    }
}

