import React from 'react'
import { Wait } from '../../framework/components'
import { Component } from '../../framework/components'
import { Title, Row, Modal } from '../../framework/containers'
import { Button, Table } from '../../framework/controls'

import { Person } from '../../entities'
import { PersonService } from '../../services'
import PersonSummaryInfo from './PersonSummaryInfo'
import PersonForm from './PersonForm'


export default class PersonSearch extends Component {
	load() {
		const { person } = this.props
		const searchPerson = person.isNew() ? person.copy() : new Person(null, true) //if the person exist, we want to search everybody so we clear the fields

		if (['sin', 'firstName', 'lastName'].find(prop => searchPerson[prop])) {
			return PersonService.searchPerson(searchPerson).then(matches => {
				if (matches.length === 1 && this.props.onSwitch) this.props.onSwitch(matches[0])
					return { filteredPeople: matches, searchPerson: searchPerson, searchAttempt: true }
			})
		}else {
			return { filteredPeople: [], searchPerson: searchPerson, searchAttempt: false}
		}	
	}

	view() {
		const { validation } = this.props
		const { filteredPeople, searchPerson, newPerson, searchAttempt} = this.state
        const columns = new Table.Headers(Person, 'sin, name, dob' )
		return <><Title title='Person Search' onHide={this.handleCancel.bind(this)} />
			<PersonSummaryInfo person={searchPerson} editable={true}/>
			<Row className='mt-3 mb-3 justify-content-end'>
				{searchAttempt && <Button key='new' className='btn-secondary' onClick={this.handleNew.bind(this)}>New</Button>}
				<Button key='search' className='btn-secondary' onClick={this.handleSearch.bind(this)}>Search</Button>
			</Row>
			<Table id='person-table' 
				data={filteredPeople} 
				columns={columns} 
				onSelect={this.handleSwitch.bind(this)}
			/>
			{this.state.processing && <Wait/>}

			{newPerson && <Modal id='personDetails' className='h-100 w-80 modal-bg-color'>
				<PersonForm person={searchPerson} onSave={this.handleSavedNew.bind(this)} onSwitch={this.handleSwitch.bind(this)} onCancel={this.handleCancelNew.bind(this)} validation={validation} />
			</Modal>}
		</>

	}

	handleNew() { this.setState({newPerson: true}) }
	handleSearch() { this.processing('search', () => PersonService.searchPerson(this.state.searchPerson).then(matches => this.setState({filteredPeople: matches, searchAttempt: true})))}
	handleCancelNew() { this.setState({newPerson: false}) }
	handleCancel() {if (this.props.onCancel) this.props.onCancel()}
	handleSavedNew(person) { 
		this.setState({newPerson: false})
		if (this.props.onNew) this.props.onNew(person) 
	}
	handleSwitch(person) {
		if (this.props.onSwitch) this.props.onSwitch(person)
	}
	
	
	

}