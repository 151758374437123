import { useHistory } from "react-router-dom";
import { Icon } from "../framework/controls";

const Title = ({
    titleText,
    showBackButton,
}) => {
    let history = useHistory();

    const handleOnClick = () => {
        history.goBack();
    }

    return (
        <div className="d-flex align-items-center mb-2">
            {showBackButton && (
                <Icon
                    icon="arrow-circle-left"
                    large
                    onClick={handleOnClick}
                    className="mr-2 clickable text-primary"
                />
            )}
            <span className="navigation-title">{titleText}</span>
        </div>
    );
};

export default Title;
