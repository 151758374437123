import React from 'react'
import { Table } from '../../framework/controls'
import { Component } from '../../framework/components'
import { Modal} from '../../framework/containers'
import { EmploymentEvent } from '../../entities/employment'
import EventPage from '../../framework/components/page/EventPage'

export default class EmploymentHistoryList extends Component {
	view() {
		const { event, showEvent } = this.state;
		const { employment, readOnly } = this.props;

		const columns = new Table.Headers(EmploymentEvent, 'effDt, desc, cmt, rptDt');
		columns['cmt'].title = 'Comment';
		columns['effDt'].title = 'Effective Date';
		columns['effDt'].maxWidth = 150;
		columns['rptDt'].maxWidth = 150;
		columns['rptDt'].title = 'Reported Date';

		return <>
			<Table id="employment-history-table" 
				data={[...employment.events.all.filter(x=>!x.config.histHidden)]} 
				columns={columns} 
				onSelect={this.handleSelect.bind(this)} 
				fitHeightToContent
				rowFormatter={this.handleSeverity.bind(this)}
			/>
			{!readOnly && event && showEvent && <Modal className='w-60 modal-bg-color'>
				<EventPage event={event} employment={employment} onSave={this.handleSave.bind(this)} onCancel={this.handleCancel.bind(this)}/>
			</Modal>}
		</>
	}
	handleSelect(row) { this.setState({event: row, showEvent: true}) }
	handleCancel() { this.setState({showEvent: false, event: null}) }
	handleSave() { 
		this.setState({showEvent: false}) 
		if (this.props.onChange) this.props.onChange()
	}
	handleSeverity(row) { 
		if (row.getData().severity === 'w') row.getElement().className += ' warning' 
		else if (row.getData().severity === 't') row.getElement().className += ' todo'
		else if (row.getData().severity === 'e') row.getElement().className += ' light-error'
	}

}
