const { BeneficiaryService, MembershipService, SpouseService, RemittanceService, PersonService, EmploymentService, RemittanceDetailService, AdjustmentService, EmployerService } = require(".");

class ServiceRegistry {
    constructor() {
        this.beneficiaries = BeneficiaryService;
        this.spouses = SpouseService;
        this.remittances = RemittanceService;
        this.people = PersonService;
        this.memberships = MembershipService;
        this.employments = EmploymentService;
        this.details = RemittanceDetailService;
        this.adjustments = AdjustmentService;
        this.employers = EmployerService;
    }

    async loadPointer(pointer) {
        let service = this[pointer.registryKey];
        if (service) return await service.get(pointer.instanceKey);
    }
}

export default new ServiceRegistry();