import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { ESelect, Question } from "../../../framework/components";
import { Icon } from "../../../framework/controls";
import { EmploymentService, RemittanceDetailService } from "../../../services";

const MOVE_CONFIRMATION = 'The participation you are moving these details to already contains a remittance for the same period. Are you sure you want to overwrite the financial info of this participation?';
const SAVE_CONFIRMATION = 'Are you sure you want to save? You are about to remove {:remove} detail(s) and move {:move} detail(s).';
const ACTIONS = { DELETE: 'DELETE', MOVE: 'MOVE' };
const REGEX = /[^0-9-.]/g;

const PeriodList = ({details1, details2, isTarget, target, changes}) => {

    const [filter, setFilter] = useState('');
    const [moving, setMoving] = useState();

    const handleChange = (e) => setFilter(e.target.value);
    const isDeleted = (key) => (changes.get[key]?.action === ACTIONS.DELETE);
    const isMoved = (key) => (changes.get[key]?.action === ACTIONS.MOVE);
    
    const warnMove = (key) => {
        const [period] = key.split('_');
        if (isMoved(key)) {
            revert(key);
        } else {
            const destination = details2.find(x=> x.period.value === period)
            if (destination && destination.hasNoFinancialData()) {
                setMoving(key);
            } else {
                moveDetail(true, key);
            }
        }
    }

    const moveDetail = (answer, key) => { 
        setMoving();
        if (answer) {
            let newChanges = {...changes.get};
            const [employer, period] = key.split('_');
            newChanges[key] = { action: ACTIONS.MOVE, destination: employer + '_' + period + '_' + target.keysValues.participation };
            changes.set(newChanges);
        }
    }

    const deleteDetail = (key) => {
        if (isDeleted(key)) {
            revert(key);
        } else {
            let newChanges = {...changes.get};
            newChanges[key] = { action: ACTIONS.DELETE };
            changes.set(newChanges);
        }
    }

    const revert = (key) => {
        let newChanges = {...changes.get};
        delete newChanges[key];
        changes.set(newChanges);
    }

    //get keys that moved
    const movedPeriods = []
    for (const key in changes.get) {
        if (changes.get[key].action === ACTIONS.MOVE) {
            movedPeriods.push(key.split('_')[1]);
        }
    }
    
    //remove keys from the detail list since they appear in other table
    //if target, put moved details in list
    var filteredDetails = !isTarget ? 
            details1.filter(x => !movedPeriods.includes(x.period.value)) : 
            [...details1.filter(x => !movedPeriods.includes(x.period.value)), ...details2.filter(x => movedPeriods.includes(x.period.value))];
    
    //apply filter on list and sort by period
    filteredDetails = (filter === '' ? filteredDetails : filteredDetails.filter(x => {
        let searchString = `${x.period.value}-${x.contributions.total}-${x.earnings.total}-${x.earnings.hours}`.replace(REGEX, '');;
        let sanitizedFilter = filter.replace(REGEX, '');
        return searchString.includes(sanitizedFilter);
    })).sort((a,b) => a.period.isSameOrAfter(b.period));

    return <>
            {moving && <Question title={MOVE_CONFIRMATION} onAnswer={(answer) => moveDetail(answer, moving)} />}
            <input placeholder='Filter...' type='text' className="form-control mb10" onChange={handleChange}/>
            <div className='transfer-detail'>
                {filteredDetails.length ? filteredDetails.map(detail => {
                    let hasAdjustmentInfo = detail.adjustmentContributions.total || detail.adjustmentEarnings.total;
                    let remittanceClosed = detail.remittance.isClose();
                    return <div className={"transfer-card" + (isDeleted(detail.keyValue) ? ' lcard-disabled lcard-deleted ' :'')} key={detail.keyValue}>
                        <div className="transfer-cols">
                            <div className="transfer-card-col">
                                <div><span className="bold">Period:</span> {detail.period.text}</div>
                                <div><span className="bold">Contributions:</span>  {detail.contributions.total ?? 0}</div>
                                <div><span className="bold">Earnings:</span>  {detail.earnings.total ?? 0} - <span className="bold">Hours:</span> {detail.earnings.hours ?? 0}</div>
                                { hasAdjustmentInfo ? 
                                    <>
                                        <div><span className="bold">Contribution Adjustments:</span>  {detail.adjustmentContributions.total ?? 0}</div>
                                        <div><span className="bold">Earnings Adjustments:</span>  {detail.adjustmentEarnings.total ?? 0} - <span className="bold">Hours:</span> {detail.adjustmentEarnings.hours ?? 0}</div>
                                    </>
                                : null}
                                { hasAdjustmentInfo && remittanceClosed ? <div><span className="bold">This remittance is closed with an adjustment that can't be deleted.</span></div> : null}
                            </div>
                            
                            <div className={"transfer-card-col"}>
                                {!isTarget && !(hasAdjustmentInfo && remittanceClosed)? 
                                    <Button className='btn-primary btn-icon' onClick={() => deleteDetail(detail.keyValue)}>
                                        <Icon icon={!isDeleted(detail.keyValue) ? "trash" : "undo"} />
                                    </Button> 
                                : null }
                                {((target && !isTarget) || (isMoved(detail.keyValue))) && !isDeleted(detail.keyValue) ? 
                                    <Button className='btn-secondary btn-icon' onClick={() => warnMove(detail.keyValue)}>
                                        <Icon icon={isMoved(detail.keyValue) ? "arrow-left" : "arrow-right"} tooltip="Move this remittance" />
                                    </Button>
                                : null}
                            </div>
                        </div>
                    </div>
                }) : <span>No details.</span>}
            </div>
        </>
}

const RemittanceDetailsAdmin = ({employment, handleClose}) => {
    
    const [employments, setEmployments] = useState([]);
    const [target, setTarget] = useState();
    const [details, setDetails] = useState([]);
    const [targetDetails, setTargetDetails] = useState([]);
    const [changes, setChanges] = useState([]);
    const [warn, setWarn] = useState();

    useEffect(() => {
        const fetch = async () => {
            const membershipEmployments = await EmploymentService.getMemberEmployments(employment.person.id);
            const remittanceDetails = await RemittanceDetailService.loadDetailsWithAdjustmentsForEmployment(employment);
            if (membershipEmployments) setEmployments(membershipEmployments);
            if (remittanceDetails) setDetails(remittanceDetails.all.filter((x) => !x.hasNoFinancialData(false)));
            
        }
        fetch();
    }, [employment]);
    
    const handleSave = async (answer) => {
        setWarn();
        if (answer) {
            await RemittanceDetailService.updateRemittanceDetailsBulk(changes);
            if (handleClose) handleClose();
        }
    }

    const handleTargetSelect = async (newTarget) => {
        setTarget(newTarget);
        
        //remove moves, keep deletes
        const filteredChanges = {};
        for (const [key, value] of Object.entries(changes)) {
            if (value.action !== ACTIONS.MOVE) filteredChanges[key] = value;
        }
        setChanges(filteredChanges);

        if (newTarget) {
            const remittanceDetails =
                await RemittanceDetailService.loadDetailsWithAdjustmentsForEmployment(
                    newTarget
                );
            
            if (remittanceDetails) {
                setTargetDetails(
                    remittanceDetails.all.filter(
                        (x) => !x.hasNoFinancialData(false)
                    )
                ); 
            }
        }
    }

    const employmentsSameER = employments.filter(x=>x.employer.keyValue === employment.employer.keyValue && x.keyValue !== employment.keyValue);
    const employmentOptions = employmentsSameER.map(ee => ({ key: ee.keyValue, text: 'PP#'+ ee.participation.no + ' Join Date: ' + (ee.participation.joinDt !== ''? ee.participation.joinDt : 'None'), value: ee }));

    var deleteCount = 0, moveCount = 0;
    for (const key in changes) {
        if (changes[key].action === ACTIONS.MOVE) moveCount++;
        if (changes[key].action === ACTIONS.DELETE) deleteCount++;
    }

    const saveMessage = SAVE_CONFIRMATION.replace('{:remove}', deleteCount).replace('{:move}', moveCount);

    return <>
            {employmentsSameER.length > 0 ?
                <div className="mb15">
                    <ESelect className="col-6" name='employment' value={target?.keyValue} instance={employment} label='Destination:' nullable={true} options={employmentOptions} onChange={handleTargetSelect} /> 
                </div> : null
            }
            <div className="transfer-layout transfer-cols">
                <div className="transfer-container">
                    <div className={'transfer-col'}>
                        <div>
                            Participation #{employment.participation.no}
                        </div>
                        <PeriodList 
                            details1={details} 
                            details2={targetDetails} 
                            target={target} 
                            changes={{set: setChanges, get: changes}}
                        />
                    </div>
                    {target ? <div className={'transfer-col'}>
                        <div>
                            Participation #{target.participation?.no}
                        </div>
                        <PeriodList 
                            details1={targetDetails} 
                            details2={details} 
                            isTarget={true} 
                            changes={{set: setChanges, get: changes}}
                        />
                    </div> : null}   
                </div>
                <div className='footer-buttons'>
                    <Button disabled={Object.keys(changes).length === 0} className='btn-primary' cn='col-4' onClick={() => setWarn(true)}>
                        Save
                    </Button>
                    {warn && <Question title={saveMessage} onAnswer={(answer) => handleSave(answer)} />}
                    <Button className='btn-secondary' cn='col-4' onClick={handleClose}>
                        Cancel
                    </Button>
                </div>
            </div>
        </> 
        
}

export default RemittanceDetailsAdmin;