import { Ref, Definition }  from '../../framework/infra'

export default class Email extends Ref{
    getDesc() {
        return (this.email + (this.usage ? ('  (' + this.usage + ')') : ''))
    }
    isWorkEmail() {return this.usage === 'work'}
}
Email.definitions = {
    usage: {
        type: Definition.types.CHOICE,
        options: [
            { key: '', text: '', text_fr: ''},
            { key: 'personal', text: 'Personal', text_fr: 'Personnelle'},
            { key: 'work', text: 'Work', text_fr: 'Work'},
            { key: 'other', text: 'Other', text_fr: 'Autre'}
        ],
        text: 'Usage',
        text_fr: 'Usage',
    },
    email: {
        type: Definition.types.STRING,
        text: 'Email',
        text_fr: 'Courriel',
    }
}
