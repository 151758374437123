import React from 'react'
import { Component, Question } from '../../framework/components'
import { Button, Icon } from '../../framework/controls'
import { Tabs, Modal, Title } from '../../framework/containers'

import { AdjustmentService, EmploymentService, MembershipService, PersonService, RemittanceDetailService } from '../../services'
import { Employment } from '../../entities'

import MemberParticipations from './MemberParticipations'
import LegacyParticipations from './LegacyParticipations'
import MemberPersonalInfoForm from './MemberPersonalInfoForm'
import EmploymentForm from '../employee/EmploymentForm'
import { KeyPressHandler } from '../../framework/utils'
import SpouseDisplay from './SpouseDisplay'
import BeneficiaryDisplay from './BeneficiaryDisplay'

export default class MemberDetails extends Component {
	load() {
		return MembershipService.get(this.props.personId || this.props.person.id, { includeEmployments: true }).then(mem => {
			KeyPressHandler.register('consoleData', ['AltLeft', 'ShiftLeft', 'KeyJ' ], () => console.log(JSON.stringify(this.state.membership.getData({removeBlanks: true}))))
			return {membership: mem, activeTab: (!this.props.activeTab || this.props.activeTab === true) ? 'ppHistory' : this.props.activeTab}
		})
	}
	unload() {
		KeyPressHandler.unregister('consoleData')
	}
	view() {
		const { membership, addNewEmployee, edit, activeTab, isLoading, isHandleDelete, warningDelete} = this.state
		return <><Title title={`${membership.person.desc}`} midtitle='Membership' subtitle={`${membership.lastParticipation?.events?.statusDesc || ''}`} onHide={this.handleCloseButton.bind(this)}/>
			<Tabs initial={activeTab} className='h-100' onChange={tab => this.setState({ activeTab: tab })}>
				<Tabs.Options></Tabs.Options>
				<Tabs.Tab name='ppHistory' title='History'>
					<MemberParticipations membership={membership} onSave={this.handleUpdate.bind(this)} onCancel={this.handleUpdate.bind(this)} handleDeletePP={this.handleParticipationDelete.bind(this)}  notify={this.props.notify}/>
				</Tabs.Tab>
				<Tabs.Tab name='personalInfo' title='Personal Info'>
					<MemberPersonalInfoForm hideTitle readOnly={true} person={membership.person}/>
				</Tabs.Tab>
				<Tabs.Tab name='spouse' title={`Spouse`}>
					<SpouseDisplay hideTitle membership={membership} onClose={() => this.handleCloseButton.bind(this)}/>
				</Tabs.Tab> 
				<Tabs.Tab name='beneficiary' title={`Beneficiary`}>
					<BeneficiaryDisplay hideTitle membership={membership} onClose={() => this.handleCloseButton.bind(this)}/>
				</Tabs.Tab> 
				<Tabs.Tab name='legacy' title='Legacy Statuses'>
					<LegacyParticipations membership={membership}/>
				</Tabs.Tab>
			</Tabs>	
			<div className='line'> 
				<div className='line-stretch'/>
				{!['spouse', 'beneficiary', 'legacy'].includes(activeTab) && <Button key='new' className='btn-secondary' onClick={this.handleNewEmployment.bind(this)}>New Employment</Button>}
				{['personalInfo'].includes(activeTab) && !edit && <Button key='edit' className='btn-secondary' onClick={this.handleEditInfo.bind(this)}>Edit</Button>}
				{activeTab === 'personalInfo' && <Button key="delete" className='btn-secondary' onClick={() => this.setState({isHandleDelete: true})}><Icon className='mr-2' icon='trash' />Delete</Button>}
			</div>
			{edit && <Modal className='h-100 w-80 modal-bg-color'>
				{activeTab === 'personalInfo' && <MemberPersonalInfoForm person={membership.person} onSave={this.handleUpdate.bind(this)} onCancel={this.handleCancel.bind(this)} onSwitch={this.handleSwitchMember.bind(this)} notify={this.props.notify}/>}
			</Modal>}
			{addNewEmployee && <Modal onHide={this.handleCancel.bind(this)} title={'New Employment'} id="members" className='h-100 w-80 modal-bg-color'>
				<EmploymentForm membership={membership} ee={new Employment()} onCancel={this.handleCancel.bind(this)} onSave={this.handleSaveNewEmployee.bind(this)} notify={this.props.notify}/>
			</Modal>}
			{isHandleDelete && <Question title='Warning. Deleting a person can impact existing financial data and plan participation. Are you sure you want to delete?' onAnswer={this.handleMemberDelete.bind(this)} />}
			{isLoading && <Modal centered className="text-center"><span className="ml-3">{'Loading...'}</span></Modal>}	
			{warningDelete && <Modal centered ><Title className="ml-3" onHide={() => this.closeWarning()} title="The member still has financial data, we cannot delete this member. He must be reimbursed"/></Modal>}
		</>
	}

	handleCancel() { this.setState({edit: false, addNewEmployee: false}) }


	closeWarning(){
		this.setState({warningDelete: false});
		if(this.props.onDelete) this.props.onDelete(false);
	} 

	handleParticipationDelete(){
		return MembershipService.get(this.props.personId || this.props.person.id, {refresh: true}).then(() => {
			return EmploymentService.getMemberEmployments(this.props.personId, {refresh: true}).then(emps => {
				this.setState({membership: emps.last.participation.membership});
				if(this.props.onUpdate) this.props.onUpdate();
			})
		})
	}

	handleMemberDelete(confirmed) {
		this.setState({isHandleDelete: false})
		if(confirmed){
			this.setState({isLoading: true})
			return PersonService.deletePerson(this.state.membership.person).then(resp => {
				this.setState({isLoading : false})
				if(!resp) this.setState({warningDelete: true})
				else if(this.props.onDelete) this.props.onDelete(true)
			}).catch(err => console.log(err));
		}
		
		
	}
	handleEditInfo() { this.setState({edit: true}) }
	handleCloseButton() {
		const { handleUpdateLastPPEvents, onCancel } = this.props;
		const { membership, touched } = this.state;
		if (touched && handleUpdateLastPPEvents) {
			handleUpdateLastPPEvents(membership);
		}
		if (onCancel) onCancel();
	}
	handleUpdate() {
		MembershipService.get(this.props.personId || this.props.person.id, { includeEmployments: true}).then(membership => {
			this.setState({edit: false, touched: true, membership});
			if(this.props.onUpdate) this.props.onUpdate();
		})
	}

	handleSwitchMember(answer) {
		if(answer && this.props.onExistingMember) {
			PersonService.getPeople().then(people => {
				const existMember = people.find(mem => mem.sin === this.state.membership.sin)
				this.props.onExistingMember(existMember)
				this.setState({membership: existMember})
			})
		} else {
			this.setState({existingSin: false})
		}
	}
	handleNewEmployment () { this.setState({addNewEmployee: true}) }
	handleSaveNewEmployee(employment) {
		EmploymentService.getMemberEmployments(this.props.personId || this.props.person.id, {refresh: true}).then(emp => {
			this.setState({membership: emp.last.participation.membership})
		}) 
		this.setState({addNewEmployee: false, activeTab: 'ppHistory'}) 
		if(this.props.onUpdate) this.props.onUpdate()
	} //TODO why is not selecting the 


}