import { RefList } from '../../framework/infra'
import { Period } from '../../framework/utils'

import Rates from './Rates'


export default class HistoricRates extends RefList {
    get currentRates() {
        return this.findLast(rate => rate.effPeriod.isSameOrBefore(Period.currentPeriod))
    }
    
    getRatesAtPeriod(period) {
        return this.findLast(rate => rate.effPeriod.isSameOrBefore(period))
    }
    
    static ref = Rates
}