import React from 'react'
import { moment } from '../../../../framework/utils/helper'
import { Component } from '../../../../framework/components'
import { Row } from '../../../../framework/containers'
import { Date, Text } from '../../../../framework/controls'

import { EmployerService , AdjustmentService } from '../../../../services'

export default class SolvencyAmountChange extends Component {
    load() {
        return EmployerService.getEmployers().then(employers => {
            const params = {
                employers: employers,
                effDate: this.props.effDate || moment().date(1).format('YYYY-MM-DD'),
                retroDate: this.props.retroDate || moment().month(0).date(1).format('YYYY-MM-DD'),
                comment: this.props.comment
            }
            return params
        })
    }
    
    view() {
        const { effDate, retroDate, comment } = this.state
        return <Row className={this.props.classname}>
            <Date name='effDate' label='Effective Date' onChange={this.handleChange.bind(this, 'effDate')} className='ml-3 ' value={effDate}></Date>
            <Date name='retroDate' label='Changed Date' onChange={this.handleChange.bind(this, 'retroDate')} className='ml-3 ' value={retroDate}></Date>
            <Text name='comment' label='Comment' onChange={this.handleChange.bind(this, 'comment')} value={comment} className='pl-3 stretch'/>
        </Row>
    }

    handleChange(field, value) {
        this.setState({[field]: value})
        const adjustments = this.createAdjustments(Object.assign({}, this.state, {[field]: value}))
        this.props.onChange(adjustments)
    }

    createAdjustments(params) {
        const { employers, effDate, retroDate, comment } = params
        return employers.reduce((adjustments, er) => {
            const adj = AdjustmentService.createSolvencyAmountChangeAdjustments(er, effDate, retroDate, 0.023, comment)
            if (adj) adjustments.push(adj)
            return adjustments
        }, [])
    }
}
