import {  RefMap, Definition } from '../../../framework/infra'
import { round } from '../../../framework/utils/helper'

export default class Adjustments extends RefMap {
	get reg() { return this.getAmount('REG') }
    set reg(val) { this.setAmount('REG', val) }
	
	get vol() { return this.getAmount('VOL')  }
	set vol(val) { this.setAmount('VOL', val) }
	
	get mat() { return this.getAmount('MAT') }
	set mat(val) { this.setAmount('MAT', val) }
	
	get ltd() { return this.getAmount('LTD') }
	set ltd(val) { this.setAmount('LTD', val) }
	
	get slf() { return this.getAmount('SLF') }
    set slf(val) { this.setAmount('SLF', val) }
	
	get total() { return this.sum(this.all)}
	get deemed() { return this.sum(this.filter(contr => contr.type.isDeemed())) }
	get excludesVoluntary() { return this.sum(this.filter(contr => !contr.type.isVoluntary())) }
	
	
	getAmount(type) { return this[type] ? this[type].amount : 0 }
	setAmount(type, val) {
		if (this[type]) this[type].amount = val
        else if(val) this.pushNew({ amount: val, type: type })
	}
	addAmount(type, val) {
		if (this[type]) this[type].amount = round(this[type].amount + val)
        else this.pushNew({ amount: val, type: type })
	}
	sum(adjustments) { return round(adjustments.reduce((sum, adjustment) => { sum += adjustment.amount; return sum }, 0))}

	add(adjustments) {
		adjustments.forEach(adjustment => {
			this.addAmount(adjustment.type.key, adjustment.amount)
		})
		return this
	}
	round() { this.forEach(contr => contr.amount = round(contr.amount)) }

	assignEmployees(employees) { this.forEach(adj => adj.employee = employees[adj.employeeId]) }
	assignEmployerCode(code) { this.forEach(adj => adj.employerCode = code) }
	assignAll(code, employees) { this.assignEmployerCode(code); this.assignEmployees(employees) }
	sortNewestToOldest() {
		return this.getSorted((a, b) => a.period.isAfter(b.period) ? 1 : 0);
	}

	////Model attributes 
	static definitions = {
		reg: { abstract: true, type: Definition.types.AMOUNT, text: 'Regular'},
		vol: { abstract: true, type: Definition.types.AMOUNT, text: 'Voluntary'},
		mat: { abstract: true, type: Definition.types.AMOUNT, text: 'Maternity'},
		ltd: { abstract: true, type: Definition.types.AMOUNT, text: 'Long Term'},
		slf: { abstract: true, type: Definition.types.AMOUNT, text: 'Self'},
		total: { abstract: true, type: Definition.types.AMOUNT, text: 'Adjustments'},
		deemed: { abstract: true, type: Definition.types.AMOUNT, text: 'Deemed'},
		excludesVoluntary: { abstract: true, type: Definition.types.AMOUNT, text: 'Total (Excludes Voluntary)'},
	}
}


