import { Ref, Definition, RefHistorical, RefEvent, RefList } from '../../../framework/infra'
import { moment } from '../../../framework/utils/helper'
import Payout from './Payout'
import ParticipationPension from './PartcipationPension'
import ParticipationMessages from './ParticipationMessages'
import ParticipationMessage from './ParticipationMessage'
import ParticipationEvents from './ParticipationEvents'
import ParticipationEvent from './ParticipationEvent'
import ParticipationStatus from './ParticipationStatus'
import ParticipationTasks from './ParticipationTasks'
import { ParticipationTaskConfig } from './ParticipationTaskConfig'

const { STRING, DATE, BOOLEAN } = Definition.types

export default class Participation extends Ref {
    get person() { return this.membership.person }

    get eventStatuses() { 
        const filteredEvents = this.events.getFiltered(eve => eve.config.status);
        filteredEvents.sortEvents();
        return filteredEvents;
    }
    get closeDate() { return this.events.find((event) => event.status.isClose() || event.config.isPendingEvent)?.ets }
    get latestStatus() { return this.eventStatuses.findLast(eve => eve.config.sts) || new ParticipationEvent()} //remove
    get lastStatusEvent() { return this.eventStatuses.last || new ParticipationEvent({code: 'newPot', effDt: null})}
    get status() { return this.lastStatusEvent.status } //remove
    get statusDesc() { return this.events.statusDesc }
    get message() { return this.messages.last }
    get joinDt() {
        const actEvent = this.getActiveStatusEvent()
        return actEvent ? actEvent.effDt : ''
    }

    get eligibilities(){
        return this.events.getFiltered(eve => eve.code === 'metEligDate');
    }
    
    get firstEligibility(){ return this.eligibilities.first?.effDt }
    get lastEligibility(){ return this.eligibilities.last?.effDt }

    get combinedWarnings() { return RefHistorical.combine([this.messages, this.tasks]) }
    get ppNo() {
        if (this.lastStatusEvent.status.isCancelled()) return 'C';
        if (this.lastStatusEvent.status.isIneligible()) return 'NE';
        if (this.lastStatusEvent.status.isPotential()) return 'P';
        return this.no
    }

    get isMER(){
        return this.employers.length > 1 || this.employments._list.length > 1 || this.employments.find(e=>e.events.find(x=> x.config.isMultipleEmployer));
    }
    getPotentialEvent() { return this.events.find(e => e.config.isNewPotential)};

    //since old forms were not sub events, we need a legacy function
    hasLegacyEnrollmentForm () {
        return this.events.all.find(x=>x.config.formReceived);
    }
    hasEnrollmentForm() { 
        //enRec and penExpCan means we either received a form, or the pen was cancelled
        if (this.hasLegacyEnrollmentForm()) return true;
        if (this.events.all.find(x=>x.subEvent.find(x=>x.config.formReceived))) return true;
    } 
    //ASK is it okay if we have one event or it should be the last event?
    isDeferredOverAge60() { return this.person.isOverAge60() && this.lastStatusEvent.status.isDeferred() }
    isOpenOverAge60() { return this.person.isOverAge60() && (!this.lastStatusEvent.status.isClose() && !this.events.find(ev => ev.code === 'a60Nop')) && !this.isPendingClose()}
    hasOutstandingPkgOver6Months() { // ASK ---> I'm not sure about the naming and this belongs here or in Employment?
        if(!this.events?.last?.effDt) {
            console.warn('Participation hasOutstandingPkgOver6Months events.last.effDt is undefined, events:', this.events);
            return false;
        }
        const outstandingEndDate = moment(this.events.last.effDt).add(180, 'days').format('YYYY-MM-DD')
        const today = moment().format('YYYY-MM-DD')
        if (this.events.last.code.isPackageSent && (outstandingEndDate < today)) return true
    }
    hasUnclaimedBenefit() { // ASK ---> I'm not sure about the naming and this belongs here or in Employment?
        if(!this.events?.last?.effDt) {
            console.warn('Participation hasUnclaimedBenefit events.last.effDt is undefined, events:', this.events);
            return false;
        }
        const today = moment().format('YYYY-MM-DD')
        const unclaimedBenefitDate = moment(this.events.last.effDt).add(39, 'months').format('YYYY-MM-DD')
        return this.events.last.code.isPackageSent && (unclaimedBenefitDate < today)
    }

    eligibilityEndDate(){
        const eligEvent = this.eligibilities.last;
        if (eligEvent) return moment(eligEvent?.ets).add(1, 'year');
    }

    isPendingClose() { return this.events.find(e => e.config.isPendingEvent ) && !this.status.isClose() }
    isPendingOrClosed() { return this.isPendingClose() || this.status.isClose() }

    getActiveStatusEvent() { return this.eventStatuses.find(eve => eve.config.isEnrollEvent) }

    getPayloadInclusions() { return ['events', 'no']};

    getFlowStatusDesc(event) {

        let flow = ParticipationTaskConfig[event.code].desc;
        let tasks = event.subEvent.constructor.ref.getTaskList(this, event);
        let desc = 'Complete';
        let complete = tasks.filter((value) => value.validated).length;
        let all = tasks.length;

        let task = tasks.find((value) => !value.validated);
        if (task) desc = task.config.task;

        return `${complete}/${all} ${flow} - ${desc}`;
    }

    addEvent(event, params = {}) {
        this.events.pushEvent(event, {participation: this, ...params});
    }
    replaceEvent(oldEvent, newEvent, params = {}) {
        this.events.replaceEvent(oldEvent, newEvent, {participation: this, ...params});
    }
    updateEvent(oldEvent, newEvent, params = {}) {
        this.events.updateEvent(oldEvent, newEvent, {participation: this, ...params});
    }
    deleteEvent(event, params = {}) {
        this.events.deleteEvent(event, {participation: this, ...params});
    }

    static key = ['membership', 'no']
    static definitions = {
        membership: { key: true, transient: true, ref: require('../Membership') },

        no: { type: STRING, text: 'Participation #' },
        mercerKey: { type: STRING, text: 'Mercer Key' },
        
        beneficiaries: { transient: true, ref: require('../../pension/member/Beneficiaries'), text: 'Beneficiaries' },
        employments: { transient: true, map: true, ref: require('../../employment/Employment') },
        event: {transient: true, ref: ParticipationEvent, text: 'Status'},
        messages: { transient: true, ref: ParticipationMessages, text: 'Message'},
        tasks: { transient: true, ref: ParticipationTasks, text: 'Tickets'},
        employers: { transient: true, type: RefList, text: 'Related employers'},

        closeDate: { abstract: true, type: DATE, text: 'Close Date' },
        joinDt: { abstract: true, type: DATE, text: 'Join Date' },
        eligibilities: {abstract: true, type: DATE, text: 'Eligibility Dates'},
        firstEligibility: {abstract: true, type: DATE, text: 'First Eligibility Date'},
        lastEligibility: {abstract: true, type: DATE, text: 'Last Eligibility Date'},
        isMER: {abstract: true, type: BOOLEAN, text: 'Working at Multiple Employers'},
        person: { abstract: true, ref: require('../../person/Person'), text: 'Person'},
        statusDesc: { abstract: true, text: 'Status'},
        lastStatusEvent: { abstract: true, ref: ParticipationEvent, text: 'Last Event Status'},
        status: { abstract: true, ref: ParticipationStatus, text: 'Status'},
        message: { abstract: true, ref: ParticipationMessage, text: 'Message'},
        ppNo: {abstract: true, type: STRING, text: 'Participation #'},
        nativeStatus: { abstract: true, type: STRING, text: 'Status' },
        cqppStatus: { abstract: true, type: STRING, text: 'Status' },
        taxStatus: { abstract: true, type: STRING, text: 'Status' },
        
        events: { ref: ParticipationEvents, text: 'Message'},
        payout: {ref: Payout, text: 'Payout'},
        pension: { ref: ParticipationPension, text: 'Pension'},
    }

    
}
