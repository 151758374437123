import React, { useState } from "react"
import { UploadButton } from '../../framework/controls'
import useEmployer from "../../hooks/useEmployer"
import { EmploymentService } from "../../services"
import { Modal } from "../../framework/containers"
import SummaryTable from "../../framework/components/page/SummaryTable"
import useNotification from "../../hooks/useNotification"

const EmployeeIdImport = ({ employments, employer, onClose }) => {
    const { employerId } = useEmployer();
    const [summary, setSummary] = useState(true);
    const [isImportModalOpen, setIsImportModalOpen] = useState(false);
    const {addMessage} = useNotification();

    const handleUpload = async (file) => {
        const data = await EmploymentService.handleEmployeeIdImport(employer?.id || employerId, file, employments);
        if (data.error !== "") addMessage(data.error, "danger");
        setSummary(data.summary);
        setIsImportModalOpen(true);
    }

    const hideModal = () => {
        if (onClose) onClose();
        setIsImportModalOpen(false)
    }

    return (
        <>
            <UploadButton
                id="import-employee-ids"
                buttonClassName="btn-secondary"
                onUpload={handleUpload}
                text="Import Employee IDs"
                accept=".xls,.xlsx,.csv"
            />
            {(isImportModalOpen && summary) && <Modal 
                title="Employee IDs Import Summary" 
                className="h-100 w-80 modal-bg-color" 
                onHide={hideModal}
            >
                <SummaryTable
                    summary={summary}
                    hideLabel="Ok"
                    onHide={hideModal}
                />
            </Modal>}
        </>
    );
};

export default EmployeeIdImport;