import React, { useEffect, useState } from "react"
import { Modal, Row, Col, Card } from '../../framework/containers'
import { Button, Table } from '../../framework/controls'
import { Excel } from '../../framework/utils'
import { Employment } from "../../entities"
import { EmploymentService } from "../../services"

import EmploymentForm from './EmploymentForm'
import useEmployer from "../../hooks/useEmployer"
import { useParams } from "react-router-dom"
import CustomDropdown from "../../components/form/Dropdown"
import Loading from "../../components/containers/Loading"
import EmployeeIdImport from "./EmployeeIdImport"

const EmploymentsPage = ({ employer }) => {
    const { employerCode } = useParams();
    const { employerId } = useEmployer();
    const [ppFilter, setPPFilter] = useState("all");
    const [selected, setSelected] = useState();
    const [employments, setEmployments] = useState();
    const [showEmployment, setShowEmployment] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const participationOptions = [
        { value: "all", text: "All Participations" },
        { value: "last", text: "Last Participations" },
    ];
    const columns = new Table.Headers(
        Employment, 
        'person.sin, person.name, status.desc, noEmp, participation.joinDt, participation.statusDesc, baseEarnings'
    );
    columns['noEmp'].hideIfEmpty = true;
    columns['baseEarnings'].hideIfEmpty = true;
    columns['status.desc'].title = 'Employment Status';
    columns['participation.statusDesc'].title = 'Participation Status';
    columns["person.sin"].headerFilter = true;
    columns["person.name"].headerFilter = true;
    columns["status.desc"].headerFilter = true;
    columns["participation.joinDt"].headerFilter = true;
    columns["participation.statusDesc"].headerFilter = true;
    columns["participation.joinDt"].headerFilterPlaceholder = "YYYY-MM-DD";

    useEffect(() => {
        let isMounted = true;

        if (employerId) {
            loadEmployments()
        }

        return () => { isMounted = false };
    }, [employerId]);

    const handleSelect = (row) => { 
        setSelected(row);
        setShowEmployment(true);
    }

    const handleCancel = () => {
        setShowEmployment(false);
    }
    
    const handleNewEmployment = () => {
        setSelected(new Employment());
        setShowEmployment(true);
    }
    
    const loadEmployments = () => {
        EmploymentService.getEmployerEmployments(employerId).then((employmentList) => {
            setEmployments(employmentList);
            setIsLoading(false)
        });
    }

    const handleExport = () => {
        const excelHeaders = new Excel.Headers(
            Employment, 
            'person.sin, person.name, noEmp, participation.joinDt, participation.statusDesc, event.effDt'
        );
        const excel = new Excel(employerCode + '_Employments');
        excel.addSheet(
            excelHeaders,
            ppFilter === "last"
                ? employments.getLatestEmploymentPerPerson().all
                : employments.all
        );
        excel.download();
    }

    const handleUploadEmployeeIds = () => {
        setIsLoading(true);
        loadEmployments();
    }

    return (
        <>
            {!isLoading ? (
                <div className="h-100 d-flex flex-column justify-content-between">
                    <div className="h-90">
                        <Card framed>
                            <div className="w-100 d-flex justify-content-between">
                                <CustomDropdown
                                    buttonText={`Participation: ${ppFilter}`}
                                    options={participationOptions}
                                    onSelect={(value) => setPPFilter(value)}
                                />
                                <div className="d-flex">
                                    <Button
                                        key="new-employment"
                                        className="btn btn-secondary"
                                        onClick={handleNewEmployment}
                                    >
                                        New Employment
                                    </Button>
                                    <EmployeeIdImport employments={employments} onClose={handleUploadEmployeeIds} /> 
                                    <Button
                                        key="export"
                                        className="btn btn-secondary"
                                        onClick={handleExport}
                                    >
                                        Export
                                    </Button>
                                </div>
                            </div>
                        </Card>
                        {!showEmployment && (
                            <Table
                                id="employments-table"
                                data={
                                    ppFilter === "last"
                                        ? employments.getLatestEmploymentPerPerson()
                                              .all
                                        : employments.all
                                }
                                columns={columns}
                                onSelect={handleSelect}
                                sort="person.name"
                                className="h-90"
                            />
                        )}
                    </div>
                    {selected && showEmployment && (
                        <Modal className="h-100 w-100 modal-bg-color">
                            <EmploymentForm
                                ee={selected}
                                employer={employer}
                                onCancel={handleCancel}
                                onSave={loadEmployments}
                            />
                        </Modal>
                    )}
                </div>
            ) : (
                <Loading />
            )}
        </>
    );
};

export default EmploymentsPage;