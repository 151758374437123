import React from 'react'
import { Ref, Definition, RefEvent, RefTask, RefMessage, Auth } from '../../framework/infra'
import { Table, Icon } from '../../framework/controls'
import { Component } from '../../framework/components'
import { Modal, Row, Col, Card } from '../../framework/containers'
import { Check } from '../../framework/inputs'
import { getSafe, moment } from '../../framework/utils/helper'
import { Participation, Employment } from '../../entities'
import { MembershipService, EmploymentService, ParticipationService } from '../../services'
import { ParticipationEvent, ParticipationTask } from '../../entities/membership/participation'
import { EmploymentBusiness, MembershipBusiness, ParticipationBusiness } from '../../business'

import config from '../../utils/config'
import EventPage from '../../framework/components/page/EventPage'
import MemberParticipationsForm from './MemberParticipationsForm'
import EmploymentForm from '../employee/EmploymentForm'
import { renderComponent } from '../../framework/utils/renders'
import { ParticipationTaskConfig } from '../../entities/membership/participation/ParticipationTaskConfig'


export default class MemberParticipations extends Component {
	load() {
		const { membership } = this.props

		return Promise.all([
			EmploymentService.getMemberEmployments(membership.keyValue, {refresh: true}),
			Auth.getCurrentUser(),
		]).then(([employments, user]) => {
			membership.participations.forEach(participation => {
				participation.employments.pushList(employments.filter(x=> x.keysValues.participation === participation.keyValue));
			})
			const filters =  {
				empInfo: { key: 'empInfo', text: 'Employment Info', on: true },
				messages: { key: 'messages', text: 'Messages', on: true },
				tasks: { key: 'tasks', text: 'Tickets', on: true }
			}
			return {filters: filters, show: '', user};
		})
	}
	view() {
		const { selected, showEmployment, showParticipation, showTask, showEvent, filters, user } = this.state;
		const { participation, membership, openEmployment } = this.props;

		const participationsData = Report.getData(membership.participations.all, filters, user);
		const data = participation ? (participationsData.find(d => d.participation.no === participation.no)?._children ?? []) : participationsData;

		const columns = new Table.Headers(Report, ['treeVal', 'effDt', 'employer', 'statusDesc' , 'eventDesc', 'cmt', 'event.rptDt']);
		columns['treeVal'].width = 70;
		columns['treeVal'].visible = !participation;
		columns['effDt'].width = 150;
		columns['effDt'].headerSort = false;
		columns['effDt'].format = this.effectiveDateOrTaskIcon.bind(this);
		columns['effDt'].align = 'center';
		columns['employer'].headerSort = false;
		columns['employer'].width = 90;
		columns['employer'].format = !participation && this.handleEmpLink.bind(this); //only when multiple PPs
		columns['employer'].cellClick = !participation && this.handleEmpSelect.bind(this);
		columns['event.rptDt'].maxWidth = 150;
		columns['event.rptDt'].title = 'Reported Date';
		columns['statusDesc'].maxWidth = 90;
		columns['statusDesc'].headerSort = false;
		columns['statusDesc'].visible = config.debug;
		columns['eventDesc'].format = this.formatEventDesc.bind(this);
		columns["cmt"].className = "comment-overflow";

		return  <><Card framed>
				<Row className='mb-2 mt-2 mr-4'>
					{/* {Object.values(filters).map(option => option.on && <Col span='4' className='ml-1 filter-pill'><Label>{option.text}</Label><Icon tooltip={"Cancel"} onClick={this.handleFilterCancel.bind(this, option.key)} className='ml-2 v-middle' family='fas' icon='times' medium/></Col>) } */}
					<Col/>
					<div>
					<Icon onClick={this.swap.bind(this)} family='fas' icon='bars' large />
						<div className={this.state.show + 'dropdown-menu dropdown-menu-right mr-4 mt-1 pr-2'} onMouseLeave={this.handleMouseOut.bind(this)}>
							{Object.values(filters).map(option => <Check value={option.on} label={option.text} lcn='checkbox-inline' onChange={this.handleFilterChange.bind(this, option.key)} />)} 
						</div>
					</div> 
				</Row>
			</Card>
			{user && <Table id="participation-list-table" 
				className="mih-400"
				data={data} 
				columns={columns} 
				onSelect={this.handleSelect.bind(this)} 
				dataTree={!participation}
				dataTreeStartExpanded={(row) => !row.getPrevRow()} //We want to open the first tree 
				rowFormatter={this.handleSeverity.bind(this)}
			/>}
			{selected && showParticipation && <Modal className='h-100 w-80 modal-bg-color'>
				<MemberParticipationsForm participation={selected.participation} membership={membership} onCancel={this.handleCancel.bind(this)} onSave={this.handleSaveParticipation.bind(this)} notify={this.props.notify} handleDelete={this.onPPDelete.bind(this)}/>
			</Modal>}
			{selected && showEmployment && <Modal className='h-100 w-80 modal-bg-color'>
				<EmploymentForm ee={selected.employment} employer={selected.employment.employer} membership={membership} onCancel={this.handleCancel.bind(this)} onDeleteEmployment={this.handleDeleteEmployment.bind(this)} onSave={this.handleSaveEmployment.bind(this)} notify={this.props.notify}/>
			</Modal>}
			{selected && showTask && showTask !== '' && <Modal className='h-100 w-70 modal-bg-color'>
				{showTask}
			</Modal>}
			{selected && showEvent && <Modal className='w-60 modal-bg-color' >
				<EventPage event={selected.event} employment={selected.employment} participation={selected.participation} onSave={this.handleSaveEvent.bind(this)} onCancel={this.handleCancel.bind(this)} eventParameters={{openEmployment}}/>
			</Modal>}
		</>
	}

	effectiveDateOrTaskIcon = (value, instance) => {
		let columnValue = renderComponent(<>
			{value} {instance?.event?.guessed ? <Icon 
				icon='question-circle'
				tooltip='This date has been guessed'
				tooltip-right
				className='text-primary'
			/> : <></>}</>)
		
		if (instance.task.code) {
			columnValue = renderComponent(instance.task.hasFlow() ?
					<Icon 
						icon='exclamation-circle'
						large
						tooltip='This task has a resolution flow.'
						tooltip-right
						className='text-primary'
					/>
					: <></>
				)
		} else if (instance.event instanceof ParticipationEvent && instance.event.config.shouldHideDate) {
			columnValue = '';
		}

		return columnValue;
	}

	formatEventDesc = (value, instance) => {

		if (instance.task?.config?.isMultipleResolution || instance.event?.subEvent?.length > 0) {
			return renderComponent(
				<><Icon 
					icon='list'
					tooltip='Task with multiple items to resolve'
					tooltip-right
				/>  {value} </> )
		}

		return value;
	}

	
	async handleDeleteEmployment(employment) {
		const { membership } = this.props;
		const targetPP = membership.participations.all.find((pp) => pp.keyValue === employment.participation.keyValue);
		targetPP.employments.pullFilter((emp) => emp.keyValue !== employment.keyValue);
	}

	onPPDelete(participation){
		return this.props.handleDeletePP(participation).then(() => {
			this.setState({showParticipation: false });
		})
	}
	swap() { this.setState({show: this.state.show ? '' : 'show '}) }
	handleMouseOut(e) { this.setState({show: ''}) }
	handleSelect(row) { 
		if ((row.getData().page === 'task' && row.task.form) || row.event.config.showTask) {
			let task = row.task;
			if (row?.event?.config?.showTask) {
				task = row.event.config.showTask;
			}
			const props = task.form.props;
			var fixer = React.cloneElement(task.form, {
				startDate: props.definedDate ? task.params[props.definedDate].value : undefined,
				instance: props.isEmployment ? row.employment : row.participation,
				onClose: this.handleCancel.bind(this),
				onSave: this.handleTaskCompleted.bind(this),
				task: task
			})
			this.setState({selected: row, showTask: fixer})
		}
		else if (row.getData().page === 'participation') this.setState({selected: row, showParticipation: true})
		else if (row.getData().page === 'event') this.setState({selected: row, showEvent: true})
	}
	handleEmpSelect(e, cell) {
		e.stopPropagation()
		if (cell.getData().employer) this.setState({selected: cell.getData(), showEmployment: true}) 
	}
	handleEmpLink(value, data, cell) {
		cell.getElement().className += ' tabulator-cell-link'
		return value
	}
	handleFilterCancel(key) {
		const filters = this.state.filters
		filters[key].on = false
        this.setState({ filters: filters })
    }
	handleFilterChange(key, val) {
		const filters = this.state.filters
		filters[key].on = val
        this.setState({ filters: filters })
	}
	handleCancel() { 
		this.setState({showParticipation: false, showEmployment: false, showTask: '', showEvent: false });
		if(this.props.onCancel) this.props.onCancel();
	}
	async handleTaskCompleted(instance) { 
		const { membership, loadingContext } = this.props;
		if (loadingContext) loadingContext.set(true);
		const isEmployment = instance instanceof Employment;
		if (isEmployment) { 
			EmploymentBusiness.validate(instance);
			await EmploymentService.updateEmployment(instance);
		} else {
			MembershipBusiness.reassignNestedParticipations(membership);
			await MembershipService.update(membership);
		}

		this.setState({ showTask: "" });
		this.finalizeSave();
	}

	async handleSaveEvent(event) {
		const { selected } = this.state;
		var { membership,loadingContext } = this.props;

		if(loadingContext) loadingContext.set(true);

		const ppToFind = membership.participations.find(pp => pp.no === selected.participation.no);
		const empToFind = ppToFind.employments.find(emp => emp.keyValue === selected.employment.keyValue);
		if (empToFind) {
			empToFind.events = selected.employment.events;
		}
		if (ppToFind) {
			ppToFind.events = selected.participation.events;
		}
		const isPPEvent = event instanceof ParticipationEvent;
		if(!isPPEvent) {
			EmploymentBusiness.validate(selected.employment);
			await MembershipService.update(selected.employment.participation.membership);
			await EmploymentService.updateEmployment(selected.employment);
		} else {
			await MembershipService.update(membership);
		}

		this.setState({ showEvent: false}) 
		this.finalizeSave();
	}

	finalizeSave() {
		const { selected } = this.state;
		var { openEmployment, loadingContext } = this.props;
		
		if (openEmployment && selected?.employment?.keyValue === openEmployment.keyValue) openEmployment.events = selected.employment.events
		if (this.props.notify) {this.props.notify('Information was successfully saved', 'success')}
		if (this.props.onSave) this.props.onSave()
		if (loadingContext) loadingContext.set(false);
	}

	handleSeverity(row) { 
		const { user } = this.state;
		const isHistHidden = row._row.data?.event?.config?.histHidden;

		if (row.getData().severity === 'w') row.getElement().className += ' warning' 
		else if (row.getData().severity === 't') row.getElement().className += ' todo'
		else if (row.getData().severity === 'e') row.getElement().className += ' light-error'
		else if (isHistHidden && user.isSuperAdmin) row.getElement().className += ' greyed-out'
	}
	handleSaveEmployment() { this.setState({showEmployment: false}) }
	handleSaveParticipation() {
		this.setState({showParticipation: false})
		if (this.props.onSave) this.props.onSave()
	}
}

class Report extends Ref { //ReportParticipations
    static getData(participations, filters, user) {
        return participations.reduce((rows, pp) => {
			var participation = ParticipationBusiness.validate(pp);
			const row = new Report({ treeVal: participation.ppNo, effDt: participation.lastStatusEvent.effDt, eventDesc: participation.statusDesc, employer: getSafe(participation, 'employments.last.employer.shortDesc'), employment: getSafe(participation, 'employments.last'), event: participation.lastStatusEvent, participation: participation, page: 'participation' })
			rows.push(row)
			row._children = []
			//ReportParticipation
			participation.events.filter(event => (user.isSuperAdmin || (event.config && !event.config.histHidden)) && (!event.config.canShow || event.config.canShow({instance: participation, event: event}))).forEach(ev => {
				row._children.push(new Report({ effDt: ev.effDt, eventDesc: ev.eventDesc, statusDesc: ev.stsCode, cmt: ev.cmt, severity: ev.severity, event: ev, participation: participation, page: 'event' }))
			})

			if (filters.empInfo.on) {
				participation.employments.forEach(ee  => {
					var employment = EmploymentBusiness.validate(ee);
					employment.events.filter(event => event.config && !event.config.histHidden).forEach(event => row._children.push(new Report({ effDt: event.effDt, statusDesc: event.stsCode,  eventDesc: event.eventDesc, cmt: event.cmt, severity: event.severity, employer: employment.employer.shortDesc, employment: employment, event: event, participation: participation, page: 'event' })))
					if (participation.lastStatusEvent.status.shouldShowTask()) {
						if (filters.messages.on) employment.messages.forEach(message => row._children.push(new Report({effDt: message.effDt, eventDesc: message.desc, cmt: message.cmt, severity: message.severity, employer: employment.employer.shortDesc, message: message, employment: employment, participation: participation, page: 'message'})))
						if (filters.tasks.on) employment.tasks.forEach(task => row._children.push(new Report({effDt: task.effDt, eventDesc: task.desc, cmt: task.cmt, severity: task.severity, employer: employment.employer.shortDesc, task: task, employment: employment, participation: participation, page: 'task'})))
					}
				})
			}
			row._children = row._children.sort((a, b) => {
				let result = 0;
				const effectiveDateA = moment(a.effDt);
				const effectiveDateB = moment(b.effDt);

				if (effectiveDateA.isSame(effectiveDateB)) {
					result = RefEvent.compareEvents(a.event, b.event);
				} else if (effectiveDateA.isBefore(effectiveDateB)) {
					result = -1;
				} else {
					result = 1;
				}
				return result;
			})
			rows.sort((a,b) => a.participation.no > b.participation.no ? -1 : 1)
			
			ParticipationTask.alwaysShownTask().forEach(validTask => {
				const task = participation.tasks.find(task => task.code === validTask.key)
				if(task) { 
					row._children.push(new Report({ effDt: task.effDt, eventDesc: task.desc, cmt: task.cmt, severity: task.severity, task: task, participation: participation, page: 'task' }))
				}
			})

			if (participation.lastStatusEvent.status.shouldShowTask()) {
				if (filters.messages.on) participation.messages.forEach(message => {
					row._children.push(new Report({ effDt: message.effDt, eventDesc: message.desc, cmt: message.cmt, severity: message.severity, message: message,participation: participation, page: 'message' }))
				})
				if (filters.tasks.on) participation.tasks.forEach(task => {
					let taskDesc = task.desc;
					if (ParticipationTaskConfig[task.code].isMultipleResolution) taskDesc = participation.getFlowStatusDesc(participation.events.findOrCreate('code', { code: task.code }));
					row._children.push(new Report({ effDt: task.effDt, eventDesc: taskDesc, cmt: task.cmt, severity: task.severity, task: task, participation: participation, page: 'task' }))
				})
			}
			row._children.sort((a,b) => {
				if (a.page === 'task') return 1;
				else if (b.page === 'task') return -1;
				else return 0;
			})
            return rows
        }, [])
    }
    
    static definitions = {
        treeVal: { type: Definition.types.STRING, text: 'No' },
        participation: { ref: Participation, text: 'Participation' },
		employment: {ref: Employment, text: 'Employment'},
		eventDesc: { type: Definition.types.STRING, text: 'Description'},
		statusDesc: { type: Definition.types.STRING, text: 'Status'},
		event: { ref: RefEvent },
		task: { ref: RefTask},
		message: { ref: RefMessage },
		effDt: { type: Definition.types.DATE, text: 'Date'},
		cmt: { type: Definition.types.STRING, text: 'Comment'},
		employer: {type: Definition.types.STRING, text: 'Entity'},
		page: { type: Definition.types.STRING },
		severity: { type: Definition.types.STRING },
    }
}