import React from 'react'
import _ from 'lodash'
import { Masked, Date, Text, AutoSuggest, Amount, Number } from './inputs'
import { Icon, PeriodSelect, YesNo } from '../../controls'
import EList from './EList'
import ESelect from './ESelect' 

export default class EInput extends React.Component {

    handleControlChange(value, event) {
        if (!this.props.isCustomOnChange) {
            this.props.instance[this.props.name] = value;
        }
        this.handleChange(value, event);
    }

    handleChange(eventOrValue, event) {
        this.setState({ touch:true });
        if(this.props.onChange) {
            this.props.onChange(eventOrValue, event);
        }
    }

    // text, password, datetime, datetime-local, date, month, time, week, number, email, url, search, tel, and color
    render() {
        const { 
            readOnlyDisplay, //for historical fields, makes display read only but calendar interactable 
            name, 
            reference, 
            required, 
            cn, 
            className, 
            ccn, 
            lcn, 
            variant, 
            instance, 
            isCustomOnChange, 
            label, 
            noLabel, 
            toolTip, 
            columns, 
            min,
            max, 
            readOnly, 
            disabled, 
            from, 
            to, 
            includeYearEnd, 
            maxLength, 
            noHist, 
            hint, 
            options, 
            nullable, 
            showRequiredLabelMarker,
            valueProp,
            guessable,
            error,
        } = this.props
        let definition = this.props.definition;

        if(!definition){
            if (instance.constructor.ref) {
            definition = _.get(instance.constructor.ref.definitions, name);
        } else if (instance.constructor.definitions) {
            definition = _.get(instance.constructor.definitions, name);
        }}



        const value = valueProp ?? _.get(instance, name);
        const props = {
            name: (reference || '') + name,
            value: value,
            variant: variant,
            required: required,
            definition: definition,
            instance: instance,
            // isCustomOnChange: does not change the value in the entity, should be handled on form save
            isCustomOnChange: isCustomOnChange,
            /** flag to set the component as controlled (set its value) or uncontrolled. Only used by the Date input. */
            isControlled: this.props.isControlled,
            className: className || cn || 'col',
            realName: name,
            onChange: this.handleChange.bind(this),
            onClick: this.props.onClick,
            onNew: this.props.onNew,
            onDelete: this.props.onDelete,
            toolTip: toolTip,
            min: min,
            max: max,
            columns: columns,
            readOnly: readOnly,
            disabled: disabled,
            from: from,
            to: to,
            includeYearEnd: includeYearEnd,
            maxLength: maxLength,
            hint: hint,
            options: options,
            nullable: nullable,
            showRequiredLabelMarker: showRequiredLabelMarker,
            guessable : guessable,
            error: error,
            placeholder: this.props.placeholder,
        }
        if (noLabel) {
            props.className = ccn
            return (
                <div className={'e-input ' + (className || cn || 'col')}> 
                    {this.getControl(definition, props)}
                    {error && <div class="invalid-feedback">{error}</div>}
                </div>
            )
        } else {
            props.className = ccn
            return <div className={'e-input ' + (className || cn || 'col')}> 
                <label className={lcn || ''}>{label || (definition ? definition.text : '')} {showRequiredLabelMarker && '*'}</label>
                <div className={(definition && definition.historical) ? 'e-input-calendar' : ''}>
                    {this.props.children || this.getControl(definition, {...props, readOnly: definition?.historical ? readOnlyDisplay : readOnly})}
                    {!noHist && definition && definition.historical && <Icon icon='calendar-alt' className={'e-input-icon-calendar' + (props.readOnly ? ' readOnly' : '')} large onClick={this.props.onClick}/>}
                </div>
                {error && <div className="invalid-message">{error}</div>}
                {/* {toolTip && <Tooltip toolTip={toolTip}/>} */}
            </div>
        }
    }

    getControl(definition, props) {
        if (!definition) {
            return <Text {...props} />
        } else if (definition.isBool()) {
            return <YesNo {...props} onChange={this.handleControlChange.bind(this)} />
        } else if (definition.isDate()) { 
            return <Date {...props} />
        } else if (definition.isPeriod()) { 
            return <PeriodSelect {...props } onChange={this.handleControlChange.bind(this)}/>
        } else if (definition.isChoice()) { 
            const options = props.options ?? definition.options.map(opt => ({key: opt.key, text: opt.text, value: opt.key}))
            return <ESelect {...props} options={options} onChange={this.handleControlChange.bind(this)}/>
        } else if (definition.hasMask()) {
            return <Masked {...props} mask={definition.type.mask}/>
        } else if (definition.hasSuggestions()) {
            return <AutoSuggest {...props} suggestions={definition.suggestions}/>
        } else if (definition.isList() || definition.isRefList()) {
            return <EList {...props} />
        } else if (definition.isRef()) { 
            return <ESelect {...props} onChange={this.handleControlChange.bind(this)} />
        } else if (definition.isCurrency()){
            return <Amount {...props} />
        } else if (definition.isNumeric()){
            return <Number {...props} />
        } else {
            return <Text {...props} />
        }
    }

}
