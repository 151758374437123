import React from 'react'
import { Modal }  from '../../../framework/containers'
import RefDescription from '../../../framework/infra/model/RefDescription'
import { EmploymentService, ParticipationService } from '../../../services'
import { Participation } from '../../../entities'
import ReportLayout from '../../../framework/components/page/ReportLayout'
import MemberDetails from '../MemberDetails'

export default class TransferReport extends ReportLayout {
    reportDesc = 'Transfer Report'
    entity = Participation
    headers = ['person.sin', 'person.name', 'no', 'lastStatusEvent.status.desc', 'lastStatusEvent.effDt', 'employments.startEndDesc', 'message']
    headerProps = {}
    tableSort = 'person.name'
    filters = {
        'status': {display: 'desc', nullable: true},
        'message._msgDesc': {definition: {ref: RefDescription, text: 'Message Type' }, display: 'name', nullable: true}
    }
    params = {}
    
    execQuery(queryParams) {
        return ParticipationService.getAll().then(participations => {
            return EmploymentService.getAllEmployments().then(() => {
                return participations.filter(pp => pp.employments.length > 1)
            })
        })
    }

    handleRowSelect(row, showDetails) {
        return (<Modal className='h-100 w-80 modal-bg-color'>
                <MemberDetails personId={row.membership.person.id} activeTab={showDetails} onCancel={this.handleCancel.bind(this)}/>
            </Modal>)
    }
}