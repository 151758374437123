import React from 'react'

import { formatStringByPattern, formatAmount, formatNumber, formatDate, formatPercentage } from './formating'
import Period from './Period'
import { renderToString } from 'react-dom/server'
import { Icon } from '../controls'

const renderComponent = renderToString

function renderAmount(val) {
    const amount = formatAmount(val)
    if (val < 0) {
        return "<div style='color:red;'>" + amount + "</div>"
    }
    return amount
}
function renderAmountEmptyFor0(val) {
    if (!val) return ''
    return renderAmount(val)
}
function renderNumber(val, valIfZero = '0', noStyle = false) {
    const number = formatNumber(val, 3, undefined, valIfZero)
    if (number[0] === '-') {
        let string =  '(' + number.slice(1) + ')'
        return noStyle ? string : "<div style='color:red;'>(" + string + ")</div>"
    }
    return number
}
function renderNumberEmptyFor0(val, noStyle = false) {
    return renderNumber(val, '', noStyle)
}
function renderPercentage(val) {
    return formatPercentage(val, '') + '%'
}

function renderDate(val) {
    return formatDate(val)
}
function renderTS(val) {
    return formatDate(val)
}

function renderBool(val) {
    if (!val) return ''
    return "<i style='color:green;' class='fa fa-check'></i>"
}
function renderPeriod(val) {
    return Period.create(val).format('MMMM YYYY')
}
function renderWarning(message) {
    if (message && message.desc) {
        var severity = 's-info'
        if (message.severity === 'w') severity = 's-warning'
        if (message.severity === 'e') severity = 's-error'
        let icon = null;
        if (message.config?.fixer) {
            icon = <Icon 
                icon='exclamation-circle'
                large
                tooltip='This task has a resolution flow.'
                tooltip-right
            />
        }
        return renderToString(<div className={severity}>{icon} {message.desc}</div>)
    }
}

function renderStringByPattern(mask, val) {
    if (!val) return ''
    return formatStringByPattern(mask, val)
}

export {
    renderStringByPattern,
    renderAmount,
    renderAmountEmptyFor0,
    renderPercentage,
    renderNumber,
    renderNumberEmptyFor0,
    renderBool,
    renderDate,
    renderTS,
    renderPeriod,
	renderWarning,
	renderComponent,
    renderToString
}
