import { Definition, Ref}  from '../../framework/infra'
import { concatStr } from '../../framework/utils/helper'

//Someone with no SIN that cannot be an entity in the APP
export default class UnknownPerson extends Ref{
    get desc() { return concatStr(this.title, '. ') + concatStr(this.firstName, ' ')  + concatStr(this.middleName) + concatStr(this.lastName) }
    get name() { return (concatStr(this.lastName,', ') + concatStr(this.firstName, ' ') + concatStr(this.middleName)) || '' }
    
    static definitions = {
        firstName: { type: Definition.types.STRING, text: 'First Name', text_fr: 'Prenom', required: true },
        lastName: { type: Definition.types.STRING, text: 'Last Name', text_fr: 'Nom' },
        middleName: { type: Definition.types.STRING, text: 'Middle Name' },
        dob: { type: Definition.types.DATE, text: 'Date of Birth' },
        dod: { type: Definition.types.DATE, text: 'Date of Death' },
        name: { abstract: true, type: Definition.types.STRING, text: 'Name', text_fr: 'Nom' },
    }
}