import React, { useEffect, useState } from 'react'
import { Table, Button, Icon } from '../../framework/controls'
import { Row, Col } from '../../framework/containers'
import { Excel, Period } from '../../framework/utils'
import { moment } from '../../framework/utils/helper'
import { EarningType } from '../../entities'

import { RemittanceDetail } from '../../entities'
import { YearEndService, RemittanceDetailService, AdjustmentService } from '../../services'
import CustomDropdown from '../../components/form/Dropdown'
import { renderAmountEmptyFor0, renderComponent, renderNumberEmptyFor0, renderToString } from '../../framework/utils/renders'
import Loading from '../../components/containers/Loading'
import { formatAmount } from '../../framework/utils/formating'

const EmploymentFinancialTable = ({ employment, tabName }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [remittanceDetails, setRemittanceDetails] = useState();
    const [year, setYear] = useState("all");
    const isContributions = tabName === "contributions";
    const isEarnings = tabName === "earnings";
    const isDeemedEarnings = tabName === 'deemedEarnings';
    const isCreditedService = tabName === "creditedService";
    const yearOptions = [
        { text: "All", value: "all" },
        ...Period.getYears(undefined, undefined).map((option) => ({
            text: option.year,
            value: option.year,
        })),
    ];

    useEffect(() => {
        
        let isMounted = true;
        RemittanceDetailService.loadDetailsWithAdjustmentsForEmployment(employment).then(details => {
            details.setYtps();
            details.setYMPEReached();
            if (isMounted) {
                setRemittanceDetails(details);
                setIsLoading(false);
            }
        });

        return () => { isMounted = false };
    }, []);
    
    const renderYMPEIcon = (value, instance) =>
        renderComponent(
            value && value !== 0 ? (
                <div className={value < 0 ? "text-danger" : ""}>
                    {formatAmount(value, "")}
                    {instance.isYMPEReached && (
                        <Icon
                            icon="exclamation-triangle"
                            large
                            tooltip="YMPE was reached"
                            tooltip-left
                            className="text-warning ml-2"
                        />
                    )}
                </div>
            ) : (
                <></>
            )
        );

    const renderExcelYMPEIcon = (value, instance) => {
        return instance.isYMPEReached
            ? `$${value} (YMPE REACHED)`
            : `$${value}`;
    }

    const renderContributions = (value, instance, cell) => {
        const columnNames = cell.getField().split('.');
        const isHours = columnNames[1].toLowerCase().includes("hours"); 
        const adjType = columnNames[0] === 'sumContributions' ? instance.adjustmentContributions : instance.adjustmentEarnings;
        const adjustedValue = value;

        if (
            adjType[columnNames[1]] === 0 ||
            columnNames[1] === "total"
        ) {
            return isHours 
                ? renderNumberEmptyFor0(adjustedValue)
                : renderAmountEmptyFor0(adjustedValue);
        }

        return renderComponent(
            <div className={adjustedValue < 0 ? "text-danger" : ""}>
                {isHours ? adjustedValue : formatAmount(adjustedValue, "")}
                <Icon
                    tooltip="An adjustment is added to this amount"
                    icon="calculator"
                    className="s-warning ml-2"
                />
            </div>
        );
    }

    const getColumnString = () => {
        let columnString = "";

        if (isContributions) {
            columnString = 
                "period, sumContributions.reg, sumContributions.mat, sumContributions.ltd, sumContributions.slf, sumContributions.vol, sumContributions.total, ytdContributions.reg, ytdContributions.mat, ytdContributions.ltd, ytdContributions.slf, ytdContributions.vol, ytdContributions.total, reviewedCmt";
        } else if (isEarnings) {
            columnString =
                "period, sumEarnings.regular, sumEarnings.overtime, sumEarnings.oneTime, sumEarnings.deemed, sumEarnings.pensionable, sumEarnings.nonPensionable, sumEarnings.total, ytdEarnings.regular, ytdEarnings.overtime, ytdEarnings.oneTime, ytdEarnings.deemed,ytdEarnings.pensionable, ytdEarnings.total, reviewedCmt";
        } else if (isDeemedEarnings) {
            columnString =
                "period, totalMatEarningsAmount, totalMatEarningsHours, totalLTDEarningsAmount, totalLTDEarningsHours, totalSelfEarningsAmount, totalSelfEarningsHours, earnings.deductable, earnings.deductableHours, creditedService, ytdCreditedService";
        } else if (isCreditedService) {
            columnString =
                "period, earnings.hours, earnings.regularHours, earnings.overtimeHours, earnings.deemedHours, earnings.deductableHours, earnings.deductableHours, creditedService, ytdCreditedService, workSchedule.monthlySchedule, workedDays";
        }

        return columnString;
    }

    let columns = new Table.Headers(
        RemittanceDetail,
        "period, sumContributions.reg, sumContributions.mat, sumContributions.ltd, sumContributions.slf, sumContributions.vol, sumContributions.total, ytdContributions.reg, ytdContributions.mat, ytdContributions.ltd, ytdContributions.slf, ytdContributions.vol, ytdContributions.total, reviewedCmt"
    );

    if (isContributions) {
        columns.list.filter(header => String(header.name).startsWith('sumContributions')).forEach(header => columns[header.name].format = renderContributions)
        columns["sumContributions.total"].title = "Total";
        columns["sumContributions.total"].className = "table-col-separator";
        columns["ytdContributions.reg"].title = "YTD Regular";
        columns["ytdContributions.mat"].title = "YTD  Maternity";
        columns["ytdContributions.ltd"].title = "YTD LTD";
        columns["ytdContributions.slf"].title = "YTD Self";
        columns["ytdContributions.vol"].title = "YTD Voluntary";
        columns["ytdContributions.total"].title = "YTD Total";
        columns["ytdContributions.total"].className = "table-col-separator";
        columns["ytdContributions.total"].format = renderYMPEIcon;
        columns["ytdContributions.total"].minWidth = 185;
        columns["period"].minWidth = 100;
        columns["reviewedCmt"].minWidth = 200;
        columns["reviewedCmt"].hideIfEmpty = true;
    } else if (isEarnings) {
        columns = new Table.Headers(
            RemittanceDetail,
            "period, sumEarnings.regular, sumEarnings.overtime, sumEarnings.oneTime, sumEarnings.deemed, sumEarnings.pensionable, sumEarnings.nonPensionable, sumEarnings.total, ytdEarnings.regular, ytdEarnings.overtime, ytdEarnings.oneTime, ytdEarnings.deemed,ytdEarnings.pensionable, ytdEarnings.total, reviewedCmt"
        );
        columns.list.filter(header => String(header.name).startsWith('sumEarnings')).forEach(header => columns[header.name].format = renderContributions)
        columns["sumEarnings.total"].title = "Total";
        columns["sumEarnings.total"].className = "table-col-separator";
        columns["ytdEarnings.regular"].title = "YTD Regular";
        columns["ytdEarnings.overtime"].title = "YTD  Overtime";
        columns["ytdEarnings.oneTime"].title = "YTD One Time";
        columns["ytdEarnings.deemed"].title = "YTD Deemed";
        columns["ytdEarnings.pensionable"].title = "YTD Pensionable";
        columns["ytdEarnings.pensionable"].format = renderYMPEIcon;
        columns["ytdEarnings.pensionable"].minWidth = 185;
        columns["ytdEarnings.total"].title = "YTD Total";
        columns["ytdEarnings.total"].className = "table-col-separator";
        columns["period"].minWidth = 100;
        columns["reviewedCmt"].minWidth = 200;
        columns["reviewedCmt"].hideIfEmpty = true;
    } else if (isDeemedEarnings) {
        columns = new Table.Headers(
            RemittanceDetail,
            "period, totalMatEarningsAmount, totalMatEarningsHours, totalLTDEarningsAmount, totalLTDEarningsHours, totalSelfEarningsAmount, totalSelfEarningsHours, earnings.deductable, earnings.deductableHours, creditedService, ytdCreditedService"
        );
        columns["totalMatEarningsAmount"].title = "Maternity Amount";
        columns["totalMatEarningsAmount"].minWidth = 180;
        columns["totalMatEarningsHours"].title = "Maternity Hours";
        columns["totalMatEarningsHours"].minWidth = 162;
        columns["totalLTDEarningsAmount"].title = "LTD Amount";
        columns["totalLTDEarningsAmount"].minWidth = 134;
        columns["totalLTDEarningsHours"].title = "LTD Hours";
        columns["totalLTDEarningsHours"].minWidth = 120;
        columns["totalSelfEarningsAmount"].title = "Self Amount";
        columns["totalSelfEarningsAmount"].minWidth = 198;
        columns["totalSelfEarningsHours"].title = "Self Hours";
        columns["totalSelfEarningsHours"].minWidth = 185;
        columns["creditedService"].minWidth = 170;
        columns["ytdCreditedService"].minWidth = 202;
        columns["period"].minWidth = 100;
        columns["earnings.deductable"].title =
            "Total Earnings (excludes deemed)";
        columns["earnings.deductable"].minWidth = 162;
        columns["earnings.deductableHours"].title =
            "Total Hours (excludes deemed)";
        columns["earnings.deductableHours"].minWidth = 142;
    } else if (isCreditedService) {
        columns = new Table.Headers(
            RemittanceDetail,
            "period, earnings.hours, earnings.regularHours, earnings.overtimeHours, earnings.deemedHours, earnings.deductableHours, earnings.deductableHours, creditedService, ytdCreditedService, workSchedule.monthlySchedule, workedDays, activeDays"
        );
        columns.list.filter(header => String(header.name).startsWith('earnings')).forEach(header => columns[header.name].format = renderContributions)
        columns["earnings.hours"].title = "Total Hours";
        columns["earnings.regularHours"].title = "Regular Hours";
        columns["earnings.overtimeHours"].title = "Overtime Hours";
        columns["earnings.deemedHours"].title = "Deemed Hours";
        columns["earnings.deductableHours"].title = "Deductable Hours";
        columns["period"].minWidth = 90;
        columns["earnings.hours"].minWidth = 126;
        columns["earnings.regularHours"].minWidth = 150;
        columns["earnings.overtimeHours"].minWidth = 160;
        columns["earnings.deemedHours"].minWidth = 150;
        columns["earnings.deductableHours"].minWidth = 175;
        columns["creditedService"].minWidth = 164;
        columns["ytdCreditedService"].minWidth = 204;
        columns["workSchedule.monthlySchedule"].minWidth = 222;
        columns["workedDays"].title = "Progressive Return Days";
        columns["workedDays"].minWidth = 224;
        columns["activeDays"].minWidth = 224;
    }

    const handleDownloadData = () => {
        const excelHeaders = new Excel.Headers(
            RemittanceDetail, 
            getColumnString(),
        );
        const excel = new Excel(
            `Employee-${employment.person.lastName}-${employment.person.firstName}-${tabName}-${year}.xlsx`
        );
        if (isContributions) {
            excelHeaders["ytdContributions.total"].format = renderExcelYMPEIcon;
        } else if (isEarnings) {
            excelHeaders["ytdEarnings.pensionable"].format =
                renderExcelYMPEIcon;
            excelHeaders["sumEarnings.total"].title = "Total";
            excelHeaders["ytdEarnings.regular"].title = "YTD Regular";
            excelHeaders["ytdEarnings.overtime"].title = "YTD  Overtime";
            excelHeaders["ytdEarnings.oneTime"].title = "YTD One Time";
            excelHeaders["ytdEarnings.deemed"].title = "YTD Deemed";
            excelHeaders["ytdEarnings.pensionable"].title = "YTD Pensionable";
            excelHeaders["ytdEarnings.total"].title = "YTD Total";
        } else if (isDeemedEarnings) {
            excelHeaders["totalMatEarningsAmount"].title = "Maternity Amount";
            excelHeaders["totalMatEarningsHours"].title = "Maternity Hours";
            excelHeaders["totalLTDEarningsAmount"].title = "LTD Amount";
            excelHeaders["totalLTDEarningsHours"].title = "LTD Hours";
            excelHeaders["totalSelfEarningsAmount"].title = "Self Amount";
            excelHeaders["totalSelfEarningsHours"].title = "Self Hours";
        }
        excel.addSheet(
            excelHeaders.list,
            year !== "all"
                ? remittanceDetails.filter((rem) =>
                      rem.periodText.includes(year)
                  )
                : remittanceDetails.all
        );
        excel.download();
    };

    const handleTerminationReport = async () => {
        const headers = YearEndService.initHeader(
            YearEndService.displayTypes.TERM,
            Excel,
            EarningType.splitEarningsTypesByCategory(
                employment.employer.getActiveEarningTypes()
            )
        );
        const excel = new Excel(`Employee-${employment.person.lastName}${employment.person.firstName}-TerminationReport.xlsx`);
        const terminatedYear = (terminationEvent?.effMoment ?? moment()).year() - 1;

        var details = [];
        for (var year = terminatedYear; year < terminatedYear + 3; year++) {
            var results = await YearEndService.getData({ employment, year: year.toString(), allowNoYearEnds: true });
            
            if (results?.details && results.details.length > 0) {
                details = [...details, ...results.details];
            } else {
                details.push({}); //we want an empty column
            }
        }

        excel.transpose(excel.addSheet(headers, details));
        excel.download();
    };

    const terminationEvent = employment.getTerminationEvent();

    return (
        <>
            {isLoading && <Loading />}
            {remittanceDetails && !isLoading && (
                <>
                    <Row>
                        <Col>
                            <CustomDropdown
                                buttonText={`Year: ${year}`}
                                options={yearOptions}
                                onSelect={(value) => setYear(value)}
                            />
                        </Col>
                        <Col right>
                            <Button
                                type="link"
                                className="text-primary"
                                onClick={handleDownloadData}
                            >
                                Export table
                            </Button>
                            <Button
                                type="link"
                                className="text-primary"
                                onClick={handleTerminationReport}
                            >
                                {terminationEvent
                                    ? "Export Termination Report"
                                    : "Financial Summary Export"}
                            </Button>
                        </Col>
                    </Row>
                    <Table
                        id="employee-remittances-details"
                        data={
                            year !== "all"
                                ? remittanceDetails.filter((rem) =>
                                      rem.periodText.includes(year)
                                  )
                                : remittanceDetails.all
                        }
                        entity={RemittanceDetail}
                        columns={columns}
                    />
                </>
            )}
        </>
    );
};

export default EmploymentFinancialTable;
