import React from 'react'
import { Component } from '../../framework/components'
import PersonForm from '../person/PersonForm'

export default class MemberPersonalInfoForm extends Component {
	
	view() {
		const { person, readOnly, hideTitle } = this.props
		
		return <PersonForm hideTitle={hideTitle} person={person} readOnly={readOnly} notify={this.props.notify} onCancel={this.handleCancel.bind(this)} onSave={this.handleSave.bind(this)} />
	}
	
	handleCancel() { if (this.props.onCancel) this.props.onCancel() }
	handleSave() { if (this.props.onSave) { this.props.onSave()} }
	handleSwitchPerson(selectedPerson) {
		if (this.props.onSwitch) this.props.onSwitch(selectedPerson)
    }

}
