import React from 'react'
import { Form } from '../../framework/controls'
import { Component, EInput, Question, Message } from '../../framework/components'
import { Card, Row, Title, } from '../../framework/containers'
import AddressDetails from '../address/AddressDetails'
import CommunicationDetails from '../address/CommunicationDetails'

import { PersonService } from '../../services'
import { Person } from '../../entities'
import { isValidSIN } from '../../framework/utils/helper'

export default class PersonForm extends Component {
    load() { 
        if (this.props.personId) return PersonService.get(this.props.personId, {refresh: true}).then(person => ({person}))
        else return {person: this.props.person}
    }

    view() {
        const { readOnly, hideTitle } = this.props
        const { errorMessage,  existingSin, person } = this.state

        return <>{!hideTitle && <Title title={person.isNew() ? 'New Person' : person.desc} onHide={this.handleCancel.bind(this)} />}
            <Form id='PersonForm' className='h-100' data={person} onSave={!readOnly && this.handleSave.bind(this)} onCancel={!readOnly && this.handleCancel.bind(this)} onChange={this.handleChange.bind(this)} ref={(ref) => this._form = ref}>
                {errorMessage && <Message message={{ text: errorMessage, severity: 'danger' }} />}
                <Card className='pl-3'>
                    <Row className="spacing-3">
                        <EInput name='firstName' instance={person} readOnly={readOnly} />
                        <EInput name='middleName' instance={person} readOnly={readOnly} />
                        <EInput name='lastName' instance={person} readOnly={readOnly} />
                        <EInput name='sin' instance={person} readOnly={readOnly} onChange={this.handleChange.bind(this)}/>
                    </Row>
                    <Row className='spacing-3'>
                        <EInput name='gender' instance={person} readOnly={readOnly} onChange={this.handleChange.bind(this)}/>
                        <EInput name='lng' instance={person} readOnly={readOnly} onChange={this.handleChange.bind(this)}/>
                        <EInput name='dob' instance={person} readOnly={readOnly} min={'1900-01-01'}/>
                        <EInput name='dod' instance={person} readOnly={readOnly}/>
                    </Row>
                    <Row className='spacing-3'>
                        <EInput name='maidenName' instance={person} readOnly={readOnly} cn='col-3'/>
                    </Row>
                </Card>
                <AddressDetails label='Address' className='mt-2' address={person.address} onChange={this.handleChange.bind(this)} readOnly={readOnly}/>
                <CommunicationDetails className='mt-2'instance={person} readOnly={readOnly}/>
                <Row className='mt-3'>
				    <EInput name='cmt' label={'Comment – Cite changes made to the above data'} instance={person} variant='textarea' cn='mt-1 col-7' readOnly={readOnly}/>
			    </Row>
                {existingSin && <Question title='A person with the same same SIN already exist, do you wish to switch to existing person?' onAnswer={this.handleSwitchExistingPerson.bind(this)} />}
            </Form>
        </>
    }

    handleSave(person) {
        const promise = () => person.isNew() && person.sin ?  PersonService.getPersonBySin(this.props.person.sin) : Promise.resolve(null)
        return promise().then(existPerson => {
            if (existPerson) {
                this.setState({existingSin: true})
            } else if (this.validatePerson()) {
                return PersonService.update(person).then(() => {
                    if (this.props.notify) {this.props.notify('Information was successfully saved', 'success')}
                    if (this.props.onSave) this.props.onSave(person)
                })
            }
            return person
        })

	}
    
    validatePerson() {
        const { validation } = this.props
        const { person } = this.state

        //field testing //TODO should be set in field itself
        if (person.sin && !isValidSIN(person.sin)) return this.setState({ errorMessage: 'Invalid SIN number' })

        //validate flow fields
       const messages = validation && validation.fields ? Object.getOwnPropertyNames(validation.fields).reduce((msgs, fieldName) => {
            const msg = validation.fields[fieldName].msg
            if (!person[fieldName]) msgs[msg.key] = msg
            return msgs
        }, {}) : {}

        const errorMsg = Object.values(messages).reduce((txt, msg) => txt += msg.text, '')
        if (errorMsg !== this.state.errorMessage) {
            this.setState({ errorMessage: errorMsg, messages: messages })
        }
        return !errorMsg
    }

    handleSwitchExistingPerson(answer) {
        const person = this.state.person
		this.setState({existingSin: false})
		if(answer && this.props.onSave) {
            PersonService.getPersonBySin(person.sin).then(person => {
                if (this.props.onSwitch) this.props.onSwitch(person)
            })
		}
	}

    handleCancel() { this.props.onCancel && this.props.onCancel() }
    handleChange() { this._form.forceDirty() }
}