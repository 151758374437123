
import React from "react"
import { Component } from '../../../framework/components'
import { Ref } from '../../../framework/infra'
import { Table, Button } from '../../../framework/controls'
import { Row, Col } from '../../../framework/containers'
import { Excel } from '../../../framework/utils'
import { moment } from '../../../framework/utils/helper'

import { Employer, Employment, RemittanceDetail } from '../../../entities'
import { EmployerService, YearEndService } from '../../../services'


export default class TerminationReport extends Component {
    load() {
        return EmployerService.getEmployers().then(employers => {
            return {report:  YearEndService.getYEMercerReportData(employers, this.props.year).details.filter(rec => rec.ee.status.isPermanentTerminated() && moment(rec.ee.status.effDt).format('YYYY') === this.props.year) }
        })
    }

    view() {
        const headers = new Table.Headers(Report, ['er.code', 'ee.sin', 'ee.name'])
        return <> 
            <Row><Col right><Button key="download" onClick={this.handleDownload.bind(this, this.state.report)}>Download Report</Button></Col></Row>
            <Table id='termination-report-table' data={this.state.report } columns={headers} />
        </>
    }

    handleDownload() { 
		const headers = YearEndService.getHeaders([])
		const excel = new Excel('Termination-Report-' + this.props.year + '.xlsx')
		excel.addSheet(headers, this.state.report, 'Termination Report')
        excel.download()
  }
}


class Report extends Ref {}
Report.definitions = {
    er: { ref: Employer, text: 'Personal Information' },
    // ee: { ref: LegacyEmployee, text: 'Personal Information' },
    emp: { ref: Employment, text: 'Personal Information' },
    mem: { ref: RemittanceDetail, text: 'Status' },
    det: { ref: RemittanceDetail, text: 'Total Pensionable Earnings' },
    toc: { ref: RemittanceDetail, text: 'Total Contributions' },
    tcs: { ref: RemittanceDetail, text: 'Total Credited Service' },
    pa: { ref: RemittanceDetail, text: 'Pension Adjustment' },
    lyd: { ref: RemittanceDetail, text: 'AnualizedEarnings' },
    adj: { ref: RemittanceDetail, text: 'Adjustments' },
    cmt: { ref: RemittanceDetail, text: 'Comments' },
    ercmt: { ref: RemittanceDetail, text: 'Comments' }, 
    
}