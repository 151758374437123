import { RefTask } from '../../framework/infra'
import { EmploymentEvent } from './EmploymentEvent'
import { EmploymentTaskConfig } from './EmploymentTaskConfig'

export default class EmploymentTask extends RefTask {
    get config() { return { ...EmploymentTaskConfig['default'], ...EmploymentTaskConfig[this.code]}}
    get form() {return EmploymentTaskConfig[this.code]?.form || EmploymentTaskConfig['default'].form }
    hasFlow() {
        return EmploymentTaskConfig[this.code]?.form;
    }

    static messages = [
        ['baseMonthlyEarnings', 'Submit the base monthly earnings', 'Submit the base monthly earnings', 't'],
        ['selfContrib', 'Confirm if the member will make self-contributions for the leave with effective date: {effectiveDate}', 'Confirm if the member will make self-contributions', 't'],
        ['onLeaveOverOneYear', 'Member has been on leave for over one year and must be terminated from the plan. Confirm data to process termination calculation and ensure final payout was made.', 'Member has been on leave for over one year - membership to be terminated', 't'],
        ['isStdOver15weeks', 'Member has been on Short-Term Disability for over 15 weeks. Notify A.S. Hayes if member has had LTD claim approved or is in the process of applying.', 'Member has been on Short-Term Disability for over 15 weeks', 't'],
        ['isMaternityOver18weeks', 'Maternity ended on {maternityEndDate}, place on Parental leave and confirm if the member will self contribute.', 'Member has been on Maternity for over 15 weeks', 't'],
        ['signPenOpt', 'Pension due to start {retiredDate}. Please have the member sign their Pension Options.', 'Member needs to sign their Pension Options', 't'],
        ['signTermOpt', 'Terminated on {firedQuitDate}. Please have the member sign & return their Termination Options.', 'Member needs to sign & return their Termination Options', 't'],
        ['hasOutstandingPackageFed', 'On {outstandingEndDate} date, the member has had an outstanding package for over 6 months. Please assist us to have the member sign their Termination Options.', 'Member has had an outstanding package for over 6 months', 't'],
        ['hasOutstandingPackageQue', 'On {outstandingEndDate} the Options have been outstanding for over 180 days. Please have the member sign their Termination Options.', 'Member has had an outstanding package for over 6 months', 't'],
        ['hasUnclaimedBenefitQue', 'On {unclaimedBenefitDate}, the member\'s benefit becomes "unclaimed" and will shortly be transferred to the government. Please assist us in finding this member so that a refund can be issued.', 'Member\'s benefit becomes "unclaimed" and will shortly be transferred to the government', 't'],
        ['confirmFrstDay', 'Confirm first day of work with employer', 'Eligibility', 't'],
        ['confirmHrdDate', 'New employment created through remittance upload. Confirm hire date.', '', 't'],
        ['deemedCheck', 'Actual deemed amounts do not match expected deemed amounts. Verify deemed calculations.', '', 't'],
        ['memStask', 'Send Membership sustained with new employer notice to employee and employer', '', 't'],
        ['infDed','Inform employer and employee that automatic deductions will take place', 'Automatic Deduction','t'],
    ]

    static definitions = {
        form: {abstract: true}
    }
    
    static validationTask() {
        return Object.values(this.messages).filter(task => EmploymentTaskConfig[task.key])
    }

    static events = EmploymentEvent.messages
}
