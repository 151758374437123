import moment from 'moment'
import React from 'react'
import { Form } from 'react-bootstrap'
import { Period } from '../../../utils'
import { DATE_FORMAT } from '../../../utils/helper'

export default class Date extends React.Component {
    handleChange(event) {
        this.props.instance[this.props.realName] = event.target.value
        this.setState({touch:true })
    }

    handleCheck(event) {
        this.props.instance.guessed = event.target.checked
    }

    handleBlur(event) {
        this.props.instance[this.props.realName] = event.target.value
        if(this.props.onChange) this.props.onChange(event)
        this.setState({touch:true })
    }

    render() {
        const { name, required,
            className, instance,
            realName, readOnly,
             min, max,
             disabled, guessable,
             /** flag to set the component as controlled (set its value) or uncontrolled. Default is true. */
            isControlled 
        } = this.props //TODO - BUG - displays wrong date like february 31 but value is empty
        const minimumDate = (min && min !== "") ? min : Period.getEpochPeriod().date;
        const minDateInvalid = instance[realName] !== "" && instance[realName] < minimumDate
        const maxDateInvalid = max && instance[realName] > max
        const date = instance[realName] ? moment(instance[realName]).format(DATE_FORMAT) : '';
        
        return (
            <Form.Group>
                <Form.Control 
                    className={className}
                    type='date'
                    required={required}
                    name={name}
                    key={name}
                    value={isControlled !== false ? date : undefined}
                    onChange={this.handleChange.bind(this)}
                    onBlur={this.handleBlur.bind(this)}
                    readOnly={readOnly}
                    disabled={disabled}
                    min={minimumDate}
                    max={max}
                    isInvalid={!readOnly && (minDateInvalid || maxDateInvalid)}
                />
                {guessable ? 
                    <Form.Check
                        className={className}
                        type = 'checkbox'
                        name = 'guessed'
                        key = 'guessed'
                        label = 'Is guessed date'
                        defaultChecked = {instance.guessed}
                        onChange={this.handleCheck.bind(this)}
                        readOnly={readOnly}
                        disabled={disabled}
                    /> : null 
                }
                <Form.Control.Feedback type="invalid">Date is invalid{minDateInvalid ? ", select a date after " + minimumDate.toString() : ""}{maxDateInvalid ? ", select a date before " + max.toString(): ""}</Form.Control.Feedback>
            </Form.Group>
        )
    }
}