import React from 'react';
import { StyleSheet } from '@react-pdf/renderer';
import { Page, Text, Separator, getText } from '../../../framework/utils/pdf' 
import { texts } from './textPDF/textPDFAddendum';
import PDFHeader from './PDFHeader';
import PDFAdjustment from './PDFAdjustment';
import { AdjustmentService } from "../../../services";

const PDFAddendum = ({ config, invoice, remittance, statementNo, employerAdjustments }) => {
    const missingCredits = AdjustmentService.getMissingStartCreditsForAllTypes(remittance.period, employerAdjustments);
    const adjustments = [...remittance.adjustments.all, ...missingCredits].sort((a,b) => {
        const isCreditA = a.type.config.isCredit;
        const isStartCreditA = a.type.config.isStartCredit;
        const isMECHB = b.type.config.isMandatoryCredit;
        return isCreditA && isStartCreditA ? (isMECHB ? 1 : -1) : 1;
    });
    const remittancePeriod = remittance.period.yearEnd === true 
        ? getText(texts['year-end-adjs'], '', {year: remittance.period.value}) 
        : remittance.period.desc;
    const addendumTitle = invoice 
        ? getText(texts['addendum-invoice-title'], '', {remittancePeriod}) 
        : getText(texts['addendum-statement-title'], '', {statementNo});
    const styles = StyleSheet.create({
        header: { width: '100%', textAlign: 'center', fontFamily: 'Roboto-Bold', fontSize: '13', marginTop: '30'},
        subtitle: {textAlign: 'center', fontFamily: 'Roboto-Bold', fontSize: '11', marginTop: '13', marginBottom: '13'},
        label: { fontSize: '11', fontFamily: 'Roboto-Bold' }
    })

    return (
        <Page>
            <PDFHeader config={config} />  
            <Separator className='mt-2'/>
            <Text style={styles.header}>{addendumTitle}</Text>
            <Text text={texts['addendum-subtitle']} style={styles.subtitle} />
            {adjustments
                .filter(adj => adj.category === "CONT")
                .map(adjustment => <PDFAdjustment adjustment={adjustment} employerAdjustments={employerAdjustments} />)
            }
         </Page>
    );
}

export default PDFAddendum;

