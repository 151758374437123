import React from 'react'

export default class Icon extends React.Component {
	render() {
		const { className, onClick, icon, tooltip, style } = this.props
		const family = this.props.family || 'fas'
		const size = this.props.large ? 's-icon-large' : ''
		const tooltipPosition = this.props['tooltip-left'] ? 's-tooltip-left' : ''
		
		return <i className={ [family || 'fas', 'fa-' + icon, 's-icon', size, className].join(' ') } style={style} onClick={ onClick }>
			{tooltip && <div className={["s-tooltip", tooltipPosition ].join(' ')}>{ tooltip }</div>}
		</i>
	}
}
