import React from 'react'
import _ from 'lodash'
import { Component, EInput, Wait } from '../../../framework/components'
import { Row, Title } from '../../../framework/containers'
import { Form, Select } from '../../../framework/controls'
import { add, round, sort, sum } from '../../../framework/utils/helper'
import Period from '../../../framework/utils/Period'
import { Tabs } from "../../../framework/containers";

import { AdjustmentService, EmploymentService, RemittanceService } from '../../../services'
import { Distribution, EarningType, Employment } from '../../../entities'
import DistributionForm from './DistributionForm'
import { AdjustmentType } from '../../../entities';
import { group as AdjustmentCatgories } from '../../../entities/pension/adjustment/AdjustmentConfigs';
import moment from 'moment/moment'

export default class AdjustmentForm extends Component {
    load() {
        var { employer, adjustment } = this.props;
        let adjustmentToCreateOrEdit = adjustment.clone();
        adjustmentToCreateOrEdit.employment = adjustment.employment;

        const distributions = adjustmentToCreateOrEdit.distributionContribution.isEmpty() ? [{}] : adjustment.distributionContribution.all;
        const earnings = adjustmentToCreateOrEdit.distributionEarning.isEmpty ? [{}] : adjustment.distributionEarning.all;

        if (!employer && adjustment.remittance.employer.id) {
            employer = adjustment.remittance.employer;
        } else if (!employer && adjustment.employment.employer.id) {
            employer = adjustment.employment.employer.id;
        }

        if (adjustmentToCreateOrEdit.isNew() && employer?.id) {
            adjustmentToCreateOrEdit.employerId = employer.id;
        } else if (adjustmentToCreateOrEdit?.remittance?.employer?.id) {
            adjustmentToCreateOrEdit.employerId = adjustmentToCreateOrEdit.remittance.employer.id;
        }

        return employer 
            ? Promise.all([
                EmploymentService.getEmployerEmployments(employer.id),
                RemittanceService.getClosedPeriods(employer.id),
            ]).then(([employments, closedPeriods]) => ({
                employments,
                distributions,
                earnings,
                closedPeriods,
                adjustmentToCreateOrEdit
            })) 
            : { distributions, adjustmentToCreateOrEdit, earnings };
    }

    view() {
        const { variant, employers } = this.props;
        const { 
            adjustmentToCreateOrEdit, 
            closedPeriods, 
            distributions, 
            employments, 
            earnings, 
            period, 
            validationError, 
            warningMessage, 
        } = this.state;
        const isNew = adjustmentToCreateOrEdit.isNew();
        const isEarningsAdjustment =
            adjustmentToCreateOrEdit.category === AdjustmentCatgories.EARN;
        const isClosedPeriod = !isNew && closedPeriods?.includes(adjustmentToCreateOrEdit.period.value);
        const activeTab = !isNew ? (adjustmentToCreateOrEdit.category === 'EARN'? 'earning':'contribution') : this.state.activeTab ?? 'contribution'; 
        const category = activeTab === 'contribution' ? AdjustmentCatgories.CONT : AdjustmentCatgories.EARN;
        if (!adjustmentToCreateOrEdit.category) {
            adjustmentToCreateOrEdit.category = category;
        }

        var employerOptions = [];
        if (employers) employerOptions = sort(employers.all, 'code')
            .map(emp => ({ key: emp.id, text: emp.code + '  -  ' + emp.name, value: emp }));

        const employeeOptions = employments 
            ? sort(employments.all, 'person.lastName')
            .map(ee => ({ key: ee.keyValue, text: ee.person.searchDesc + ' - PP#'+ ee.participation.no + ' Join Date: ' + ee.participation.joinDt, value: ee }))
            : [];

        var adjustmentTypeOptions = []; 
        Object.entries(AdjustmentType.types).filter(type => 
            !type?.[1]?.config.isHidden
                || (type?.[1]?.config.isHidden && !isNew)
        ).forEach(entry => {
            const [key, value] = entry;
            if (value.config.group.includes(category)) {
                adjustmentTypeOptions.push({key: key, text: value.desc, value: value.key})
            }
        });

        const selectedEmpEarning = adjustmentToCreateOrEdit.employerId && employers ? EarningType.splitEarningsTypesByCategory(employers[adjustmentToCreateOrEdit.employerId].earningTypes) : [];
        return <>
            <Title title='Adjustment' onHide={this.handleCancel.bind(this)}/>
            <Form 
                className='h-100' 
                data={adjustmentToCreateOrEdit} 
                deleteTooltip='Cannot delete an adjustment for a closed period' 
                isNew={isNew} 
                isReadOnly={(!isEarningsAdjustment && isClosedPeriod)} 
                onSave={this.handleSave.bind(this)} 
                onCancel={this.handleCancel.bind(this)}
                onDelete={this.handleDelete.bind(this)} 
                ref={(ref) => this._form = ref}
                warningMessage={warningMessage}
                errorMessage={validationError}
            >
                <Row>
                    {isNew ? 
                        <Tabs initial={activeTab} onChange={(tab) => this.handleCategorySelect(tab)} className="h-100">
                            <Tabs.Tab name='contribution' title='Contribution'/> 
                            <Tabs.Tab name='earning' title="Earnings/Hours"/>
                        </Tabs> : null
                    }
                </Row>
                <Row className='input-spacing-2'>
                    {variant !== 'monoEmployer' && 
                        <EInput className="col-6" name='employerId' label='Employer' instance={adjustmentToCreateOrEdit} showRequiredLabelMarker>
                            <Select options={employerOptions} value={adjustmentToCreateOrEdit.employerId} readOnly={isClosedPeriod} onChange={this.handleEmployerSelect.bind(this)} />
                        </EInput> 
                    }
                    <EInput className="col-6" name='employment' valueProp={adjustmentToCreateOrEdit.employment?.keyValue} label='Employee' instance={adjustmentToCreateOrEdit} nullable={true} options={employeeOptions} readOnly={isClosedPeriod} onChange={this.handleEmployeeSelect.bind(this)} />
                </Row>
                <Row className='input-spacing-2'>
                    <EInput name='period' instance={adjustmentToCreateOrEdit} includeYearEnd options={!isClosedPeriod ? this.getOpenPeriodOptions() : undefined} readOnly={isClosedPeriod} onChange={this.handlePeriodSelect.bind(this)} valueProp={period ?? adjustmentToCreateOrEdit.period} showRequiredLabelMarker />
                    <EInput name='type' instance={adjustmentToCreateOrEdit} showRequiredLabelMarker>
                        <Select options={adjustmentTypeOptions} value={adjustmentToCreateOrEdit.type.key} readOnly={isClosedPeriod} onChange={this.handleTypeSelect.bind(this, adjustmentToCreateOrEdit)}/>
                    </EInput> 
                </Row>
                <Row className='input-spacing-2'>
                    <EInput name='effDate' instance={adjustmentToCreateOrEdit} readOnly={isClosedPeriod} showRequiredLabelMarker onChange={this.handleEffectiveDate.bind(this)} max={adjustmentToCreateOrEdit.endEffDate}/>
                    <EInput name='endEffDate' instance={adjustmentToCreateOrEdit} readOnly={isClosedPeriod} showRequiredLabelMarker onChange={this.handleEffectiveDate.bind(this)} min={adjustmentToCreateOrEdit.effDate}/>
                </Row>             
                <DistributionForm activeTab={activeTab} selectedEmpEarning={selectedEmpEarning} adjustment={adjustmentToCreateOrEdit} distributions={distributions} earnings={earnings} handleChange={this.handleDistChange} handleAdd={this.handleAddDistribution} handleDelete={this.handleDeleteDistribution}/>
                <Row className='mt-3'>
                    <EInput name='total' instance={adjustmentToCreateOrEdit} cn='col-3 mr-2' readOnly={true} valueProp={this.calculateTotal()} />
                    {adjustmentToCreateOrEdit.category === "EARN" && 
                        <EInput name='totalHours' instance={adjustmentToCreateOrEdit} cn='col-3 mr-2' readOnly={true} valueProp={this.calculateTotalHours() ?? ''} />
                    }
                    <EInput name='cmt' instance={adjustmentToCreateOrEdit} readOnly={isClosedPeriod} />
                </Row>
            </Form>
            {this.state.processing && <Wait/>}
        </>
    }

    calculateTotalHours = () =>{
        return this.state.earnings.reduce((previousValue, currentValue) => (
            currentValue.hours ? add(previousValue, Number(currentValue.hours)) : previousValue
        ), 0)}


    calculateTotal = () => this.state.adjustmentToCreateOrEdit.category === "EARN"
        ? this.state.earnings.reduce((previousValue, currentValue) => (
            currentValue.amount && typeof currentValue.amount === "number" 
                ? add(previousValue, currentValue.amount)
                : previousValue
            ), 0) :
            this.state.distributions.reduce((previousValue, currentValue) => (
                currentValue.am && typeof currentValue.am === "number" 
                    ? add(previousValue, currentValue.am)
                    : previousValue
            ), 0);
    


    getOpenPeriodOptions = () => {
        const periods = Period.getPeriods(undefined, undefined, true)
        return this.state.adjustmentToCreateOrEdit.category === "EARN" ? periods : periods.filter(period => !this.state.closedPeriods?.includes(period.value));
    }
    
    getDistOptions = () => Distribution.getDistributionOptions().filter(option => (
        !this.state.distributions.map(distribution => distribution.ta).includes(option.key)
    ));

    handleEffectiveDate(event){
        if(event.target.name === 'endEffDate') {
            this.setState(prevState => ({
                ...prevState,
                warningMessage: moment(event.target.value).year() < this.state.adjustmentToCreateOrEdit.period.year ? 'Warning: Employer Factor automatically populated at Current Year Rate. If prior year, manually adjust the Employer Factor' : '', 
            }));
        };
        this.setState({touched : true})
    }

    handleEmployerSelect (employerId) {
        const { adjustmentToCreateOrEdit } = this.state;
        adjustmentToCreateOrEdit.employerId = employerId;
        adjustmentToCreateOrEdit.employeeId = null;
        adjustmentToCreateOrEdit.employment = new Employment();
        return this.processing('getEmployerEmployments', () => Promise.all([
            EmploymentService.getEmployerEmployments(employerId),
            RemittanceService.getClosedPeriods(employerId)
        ]).then(([employments, closedPeriods]) => {
            this.setState({ employments: employments, closedPeriods });
            this._form.forceDirty();
        }));
    }

    handleTypeSelect (adjustment, type) {
        adjustment.type = new AdjustmentType(type);
        this.setState({adjustmentToCreateOrEdit : adjustment});
        this.setState(prevState => ({
            ...prevState,
            warningMessage: adjustment.type.config.warningMessage,
        }));
        this._form.forceDirty();
    }

    handleCategorySelect (category) {
        const { adjustmentToCreateOrEdit } = this.state;
        adjustmentToCreateOrEdit.category = category === 'contribution' ? 'CONT' : 'EARN';
        this.setState({adjustmentToCreateOrEdit : adjustmentToCreateOrEdit, activeTab: category})
        this._form.forceDirty();
    }

    handleEmployeeSelect (employment) {
        let updatedAdjustment = this.state.adjustmentToCreateOrEdit;
        updatedAdjustment.employment = employment;
        this.setState({ dirty: true, adjustmentToCreateOrEdit: updatedAdjustment });
        this._form.forceDirty();
    }

    handlePeriodSelect = (period) => {
        this.setState(prevState => ({
            ...prevState,
            period
        }))
        this._form.forceDirty();
    }

    automateEmployerAdjusment(newContributions){
        const employeeContrib = newContributions.filter(dist => Distribution.isEmployee(dist));
        const rate = this.props.employer.plan.historicRates.getRatesAtPeriod(this.state.period ?? this.state.adjustmentToCreateOrEdit.period).employerContribution;
        const amount = round(sum(employeeContrib, 'am') * rate);
        const employerContribIndex = newContributions.findIndex(dist => dist.ta === 'r')
        if(employerContribIndex < 0){
            return [...newContributions, {ta: 'r', am: amount, autoEEContrib: true}]
        }else{
            return newContributions.map((distribution, i) => (employerContribIndex === i) ?  { ...distribution, am: amount } : distribution);
        }
        
    }

    handleContribChange = (value, event) => {
        const { index, field } = value;
        const newValue = field === 'am' && event.target ? event.target.value : event;
        var prevContributions = this.state.distributions;
        var newContributions = prevContributions.map((distribution, i) => (index === i) ?  { ...distribution, [field]: newValue } : distribution);
        if(Distribution.isEmployee(newContributions[index])) newContributions = this.automateEmployerAdjusment(newContributions, prevContributions)
        this.setState({distributions: newContributions});
        this._form.forceDirty();
        this.redraw();
    }

    handleEarnChange = (value, event) => {
        const { index, field } = value;
        const newValue = (["amount", "hours"].includes(field) && event.target) ? event.target.value : event;
        var prevEarnings = this.state.earnings;
        var newEarnings = prevEarnings.map((earning, i) => (index === i) ? { ...earning, [field]: newValue } : earning);
        this.setState({ earnings: newEarnings });
        this._form.forceDirty();
        this.redraw();
    }

    handleDistChange = (value, event) => { 
        this.state.adjustmentToCreateOrEdit.category === "EARN" ? 
            this.handleEarnChange(value, event) :
            this.handleContribChange(value, event)
    };
    
    handleAddDistribution = () => {
        if(this.state.adjustmentToCreateOrEdit.category === "EARN") {
            this.setState(!this.state.earnings ? { earnings: [{}] }: prevState => ({...prevState, earnings: [...prevState.earnings, {}]})) 
        } else {
            this.setState(!this.state.distributions ? { distributions: [{}] } : prevState => ({ ...prevState, distributions: [...prevState.distributions, {}]}))
        }
    }

    handleDeleteDistribution = (index) => {
        if(this.state.adjustmentToCreateOrEdit.category === "EARN"){
            index === 'all' ? this.setState({earnings: [{}]}) :
            this.setState(prevState => ({
                ...prevState,
                earnings: prevState.earnings.filter((earnings, i) => (index !== i))
            }))
        }else{
            index === 'all' ? this.setState({distributions: [{}]}) :
            this.setState(prevState => ({
                ...prevState,
                distributions: prevState.distributions.filter((distribution, i) => (index !== i))
            }))
        }
    }


    formatAdjustment = (adjustment) => {
        const { employments, distributions, earnings, period } = this.state;

        adjustment.remittance.employer = adjustment.employerId;
        adjustment.distributionContribution._list = Object.entries(_.groupBy(distributions.filter(distribution => 
            distribution !== {} && distribution.ta && distribution.am
        ), 'ta')).map(adjustment => ({
            ta: adjustment[0],
            am: adjustment[1].reduce((prev, current) => add(prev, current.am), 0)
        }));
        
        adjustment.distributionEarning._list = Object.entries(_.groupBy(earnings.filter(earning => 
            earning !== {} && earning.earningType && (earning.amount || earning.hours)
        ), 'earningType.code')).map(earns => ({
                earningType: earns[1][0].earningType,
                code: earns[1][0].earningType.code,
                amount: earns[1].reduce((prev, current) => add(prev, current.amount), 0),
                hours: earns[1][0].hours ? earns[1].reduce((prev, current) => prev + Number(current.hours), 0) : undefined,
        }));
        
        if (adjustment.employment && adjustment.employment.person.id) {
            adjustment.participation.no = adjustment.employment.participation.no;
            adjustment.participation.membership = adjustment.employment.person.id;
        } else {
            adjustment.participation.no = '';
            adjustment.participation.membership = '';
        }

        if (period) {
            adjustment.period = period;
            adjustment.remittance.period = period;
        }
    }

    validateRequiredFields = (adjustment) => {
        let isValid = true;
        if ((!this.props.employer && !adjustment.employerId)
            || !adjustment.remittance.period 
            || !this.isValidType(adjustment.type, adjustment.category)
            || !adjustment.effDate
            || !adjustment.endEffDate
            || (adjustment.distributionContribution.isEmpty() && !adjustment.distributionEarning.hasAtLeastOneAmount))
        { isValid = false }

        this.setState({validationError: !isValid ? 'Please fill out all required fields.' : ''})

        return isValid;
    }

    isValidType = (type, category) => {
        var keys = Object.entries(AdjustmentType.types).map(entry => {
            const [key, value] = entry;
            if(value.config.group.includes(category)) return value.key;
        });

        if(keys.includes(type?.key))
        return true;
    }

    verifyEmployeeChange = (adjustmentToSave) => {
        const { adjustment } = this.props;
        let hasEmploymentChanged = false;

        if (adjustment.employment && adjustmentToSave.employment) {
            hasEmploymentChanged = adjustment.employment.keyValue !== adjustmentToSave.employment.keyValue;
        } else if (!adjustment.employment && !adjustmentToSave.employment) {
            hasEmploymentChanged = false;
        } else {
            hasEmploymentChanged = true;
        }

        return hasEmploymentChanged;
    }

    handleSave (adjustment) {
        this.formatAdjustment(adjustment);
        const isValid = this.validateRequiredFields(adjustment);
        const isEditAdjustmentKeyValue = !this.props.adjustment.isNew() && (!this.props.adjustment.period.isSame(adjustment.period) 
            || this.verifyEmployeeChange(adjustment)
            || this.props.adjustment.remittance?.employer?.id !== adjustment.employerId);
        const isEditTotal = !this.props.adjustment.isNew() && this.props.adjustment.total !== adjustment.total;
        
        if (isValid) {
            this.busy(() => AdjustmentService.update(
                adjustment,
                '',
                isEditAdjustmentKeyValue || isEditTotal
                    ? { previousAdjustmentKey: this.props.adjustment.keyValue, 
                        totalAdjustments: !this.props.adjustment.distributionContribution.isEmpty() 
                            ? this.props.adjustment.distributionContribution.all.reduce((previousValue, currentValue) => (
                                currentValue.am && typeof currentValue.am === "number" 
                                    ? add(previousValue, currentValue.am)
                                    : previousValue
                                ), 0) 
                            : 0,
                    }
                    : undefined,
            ).then((updatedAdjustment) => {
                if(this.props.onSave) {
                    this.props.onSave(updatedAdjustment);
                }
            }));
        }
    }

    handleDelete () {
        return this.busy(() => AdjustmentService.delete(this.props.adjustment).then(deletedAdjustment => {
            if(this.props.onDelete) this.props.onDelete(deletedAdjustment, true)
        }));
    }

    handleCancel () {
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }
}