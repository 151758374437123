import { Business } from "../framework/infra"
import { add, round, sum } from '../framework/utils/helper'
import { Period } from '../framework/utils'

import RemittanceDetailBusiness from "./RemittanceDetailBusiness"
import { Adjustment, AdjustmentType, Adjustments, Earning, RemittanceStatus } from "../entities"
import { AdjustmentService, RemittanceService } from "../services"
import { adjustmentsTypeConfigs, group } from "../entities/pension/adjustment/AdjustmentConfigs"

export default class RemittanceBusiness extends Business {
    static calculate(remittance) {
        remittance.details.forEach(detail => RemittanceDetailBusiness.calculate(detail));

        // update remittance status if error
        if(remittance.details.find(det => det.onError())) remittance.status = RemittanceStatus.ERROR;
        else remittance.status = RemittanceStatus.STARTED;

        if(!remittance.validated) this.calculateSummary(remittance);
        return remittance;
    }

    static calculateSummary(remittance) {
        remittance.contributions = remittance.details.contributionsTotals;
        remittance.erContribs = round(remittance.contributions.deductions * remittance.rates.employerContribution);
        remittance.solvency = !remittance.period.yearEnd && remittance.employer.solvencies.getSolvencyAtPeriod(remittance.period) 
            ? remittance.employer.solvencies.getSolvencyAtPeriod(remittance.period).amount 
            : 0;

        return remittance;
    }

    static calculateCredit = async (remittance, remittances, shouldSaveAdjustment) => {
        remittance.creditUsed = 0;
        remittance.totalNegativeCredit = 0; 

        // api call get all employer credit adjustments
        const adjustments = await AdjustmentService.getAdjustmentsForEmployer(remittance.employer.id);
        const prevCreditAdjustments = adjustments.filter(adj => adj.type.config.isCredit && 
            (adj.type.config.isStartCredit && adj.period.isSameOrBefore(remittance.period) 
            || (!adj.type.config.isStartCredit && adj.period.isBefore(remittance.period))));

        // remove all used credit adjs in selected period before re-calc
        remittance.adjustments = remittance.adjustments.filter(adj => !(adj.type.config?.isCredit && !adj.type.config?.isStartCredit));

        // apply credits
        await this.applyAllCredit(prevCreditAdjustments, remittance, adjustments, remittances, shouldSaveAdjustment);
        remittance.totalAdjustments = remittance.adjustments.reduce((total, adj) => add(adj.total, total), 0);
        
        return remittance;
    }

    static applyAllCredit = async (creditAdjs, remittance, allAdjs, allRemittances, shouldSaveAdjustment) => { 
        let adjsToCreate = [];
        const groupedTypes = AdjustmentType.getCreditTypes()
            .filter(key => adjustmentsTypeConfigs[key]?.isCredit && adjustmentsTypeConfigs[key]?.isStartCredit)
            .map(key => new AdjustmentType(key))
            .sort((a, b) => a.config?.priority < b.config?.priority ? -1 : 1);

        for(const [index, type] of groupedTypes.entries()) {
            let unusedCredit = 0;
            const startCredits = creditAdjs.filter(adj => adj.type.key === type.key && adj.leftOverCredit !== 0);
            const usedCredits = creditAdjs.filter(adj => adj.type.key === `${type.key}R`);
            const newestAdjOfType = new Adjustments([...startCredits, ...usedCredits]).sortNewestToOldest().last;
            const startCreditTotal = startCredits.reduce((total, adj) => add(total, adj.total), 0);
            const usedCreditTotal = usedCredits.reduce((total, adj) => add(total, adj.total), 0);
            const creditLeft = add(startCreditTotal, usedCreditTotal);
            const prevAdj = adjsToCreate[index-1];
            const containsNewBucket = type?.config.targetAccounts.find(account => !prevAdj?.type?.config?.targetAccounts.includes(account));

            remittance.totalNegativeCredit += creditLeft;

            if (newestAdjOfType) {
                if (!prevAdj || ((prevAdj && prevAdj.leftOverCredit === 0) || containsNewBucket)) {
                    unusedCredit = this.applyCredit(remittance, creditLeft, type);
                } else {
                    unusedCredit = creditLeft;
                }
    
                // create used adjustment on current remittance for type
                const adj = await this.createCreditAdjustmentOnCurrentRemittance(remittance, newestAdjOfType, creditLeft, unusedCredit, allAdjs, shouldSaveAdjustment);
                adjsToCreate.push(adj);

                // check for future used credits of same type, flag remittance as shouldRecalculate
                if (shouldSaveAdjustment) await this.checkFutureUsedCreditsForType(allAdjs, allRemittances, type, remittance.period);
            }
        }
    }

    static applyCredit = (remittance, availableCredit, type) => {
        let unusedCredit = availableCredit;
        let totalBucketAmounts = 0;

        if (availableCredit < 0) {
            totalBucketAmounts = type.config?.targetAccounts?.reduce((total, currentAccount) => {
                return this.getAdjustedAmountWithoutCredit(remittance, currentAccount) + total;
            }, 0);
            totalBucketAmounts -= remittance.creditUsed;
            if (availableCredit !== 0) {
                unusedCredit = availableCredit + totalBucketAmounts;
                if (availableCredit < 0 && unusedCredit > 0) {
                    unusedCredit = 0;
                    remittance.creditUsed -= availableCredit;
                } else {
                    remittance.creditUsed += totalBucketAmounts;
                }
            } 
        }

        return unusedCredit;
    }

    static createCreditAdjustmentOnCurrentRemittance = async (remittance, adjustment, availableCredit, unusedCredit, allAdjs, shouldSaveAdjustment) => {
        const adjTypeKey = `${adjustment?.type.key}${adjustment?.type.config.isStartCredit ? 'R' : ''}`;
        const hasCreditTypeInPeriod = allAdjs.find(adj => adj.type.key === adjTypeKey && adj.period.isSame(remittance.period));
        const usedCredit = round(unusedCredit - availableCredit);
        let usedCreditAdj = (hasCreditTypeInPeriod ?? adjustment).clone();
        
        if (hasCreditTypeInPeriod) {
            usedCreditAdj.distributionContribution._list = [{ta: adjustment.type.config?.targetAccounts?.[0], am: usedCredit}];
            usedCreditAdj.leftOverCredit = unusedCredit;
        } else {
            usedCreditAdj = new Adjustment({
                employer: adjustment.employer,
                effDate: adjustment.effDate,
                endEffDate: adjustment.endEffDate,
                type: AdjustmentType.types[adjTypeKey],
                distributionContribution: [{ta: adjustment.type.config?.targetAccounts?.[0], am: usedCredit}],
                cmt: adjustment.cmt,
                remittance: remittance.keyValue,
                participation: '',
                category: group.CONT,
                leftOverCredit: unusedCredit,
            });
        }
        
        // if no credit used and adjustment already exists, delete it
        if (usedCredit === 0 && hasCreditTypeInPeriod && shouldSaveAdjustment) await AdjustmentService.delete(hasCreditTypeInPeriod); 

        // if credit used, update adjustment
        if (usedCredit !== 0) {
            remittance.adjustments.push(usedCreditAdj);
            if (shouldSaveAdjustment) await AdjustmentService.update(usedCreditAdj, '',
                hasCreditTypeInPeriod ? { previousAdjustmentKey: hasCreditTypeInPeriod.keyValue } : undefined);
        }
        return usedCreditAdj;
    }

    static checkFutureUsedCreditsForType = async (adjustments, remittances, type, period) => {
        let remsToFlag = [];

        // check if adjs in future months have same used credit type
        const futureCreditsSameType = adjustments.filter(adj => adj.type.key === `${type.key}R` && adj.period.isAfter(period));

        // if so, set shouldRecalculate flag to true for those remittance periods
        for(let adj of futureCreditsSameType) {
            const rem = remittances.find(r => r.period.isSame(adj.period));
            if (rem) {
                rem.shouldRecalculate = true;
                if (!remsToFlag.find(r => r.period.isSame(rem.period))) remsToFlag.push(rem);
            }
        }

        // save those remittances
        await RemittanceService.updateAll(remsToFlag);
    }

    static getAdjustedAmountWithoutCredit(remittance, code) {
        const adjustedAmount = this.getAdjustedAttributeByCode(remittance, code);
        let account = 'employerContribution';
        
        if (code === 's') {
            account = 'solvency';  
        } else if (code === 'i') {
            account = 'interest';
        }

        const creditsForBucket = sum(
            remittance.adjustments.filter(adjustment => {
                return adjustment.type.config?.isCredit
                    && adjustment.distributionContribution?.[code]
                    && adjustment.type.config?.targetAccounts.includes(code)
            }), 
            account
        );

        const overPaymentsInBucket = sum(
            remittance.adjustments.filter(adjustment => adjustment.type.config.isOverpayment), 
            account)

        const adjustedWithoutCredit = adjustedAmount - creditsForBucket - overPaymentsInBucket; 
        return adjustedWithoutCredit;
    }

    static getAdjustedAttributeByCode = (remittance, code) => {
        let amount = remittance.erAdjustedContribs;
        if (code === 's') {
            amount = remittance.solAdjusted;  
        } else if (code === 'i') {
            amount = remittance.intAdjusted;
        }
        return amount;
    }

    static calculateRetroAdjustments(remittance) {
        const calculatedRetroAdjs = []
        const ercAdj = remittance.adjustments.find(adj => adj.type.key === 'ERC')
        if (ercAdj) {
            const effPeriod = Period.fromDate(ercAdj.effDate)
            const periodIntFactor = 1 + (remittance.rates.erContribRetroChangeInt / 12)
            const diff = round(remittance.eeAdjustedContribs * (remittance.historicRates.getRatesAtPeriod(effPeriod).employerContribution - remittance.rates.employerContribution))
            ercAdj.distributionMap['r'].am = diff
            ercAdj.distributionMap['i'].am = round(diff * (Math.pow(periodIntFactor, effPeriod.intValue - remittance.period.intValue - 1) - 1))
            calculatedRetroAdjs.push(ercAdj)
        }
        return calculatedRetroAdjs
    }

    static calculateYEEarningDifferences = (earningTypes, detail, uploadedEarning, yeEarningDifferences) => {
        earningTypes.forEach(earningType => {
            const existingYTDEarningType = detail?.ytdEarnings.all.find(earning => earning.code === earningType.code);
            const uploadedYTDEarningType = uploadedEarning.earnings.all.find(earning => earning.code === earningType.code);

            let earning = new Earning({ 
                code: earningType.code,
                earningType: earningType,
                amount: 0,
                hours: 0,
            });

            if (existingYTDEarningType && uploadedYTDEarningType) {
                earning.amount = uploadedYTDEarningType.amount - existingYTDEarningType.amount;
                earning.hours = uploadedYTDEarningType.hours - existingYTDEarningType.hours;
            } else if (existingYTDEarningType && !uploadedYTDEarningType) {
                earning.amount = -existingYTDEarningType.amount;
                earning.hours = -existingYTDEarningType.hours;
            } else if (!existingYTDEarningType && uploadedYTDEarningType) {
                earning.amount = uploadedYTDEarningType.amount;
                earning.hours = uploadedYTDEarningType.hours;
            }
            yeEarningDifferences.push(earning);
        });
        const earningsByCategory = yeEarningDifferences.group('earningType.category.key');
        Object.getOwnPropertyNames(earningsByCategory).forEach(earningType => {
            const adjEarnType = earningsByCategory[earningType].find(earn => earn.earningType.alias === earn.earningType.getHayesCode())?.earningType;
            const adjEarn = earningsByCategory[earningType].find(earn => earn.earningType === adjEarnType);
            if(!adjEarn.isEmpty()){
                const differenceAmount = sum(earningsByCategory[earningType].filter(earn => earn.earningType !== adjEarnType), 'amount') + adjEarn.amount;
                const differenceHours = sum(earningsByCategory[earningType].filter(earn => earn.earningType !== adjEarnType), 'hours') + adjEarn.hours;
                earningsByCategory[earningType].forEach(earn => {
                    if(earn.code === adjEarnType.code){
                        yeEarningDifferences[earn.code].amount = differenceAmount;
                        yeEarningDifferences[earn.code].hours = differenceHours;
                    } else{
                        yeEarningDifferences[earn.code].amount = 0;
                        yeEarningDifferences[earn.code].hours = 0;                       
                    }
                })
            }
        })
    }

    static refreshBalances(remittances) {
        remittances.distributePayments();
        remittances.reduce((prev, rem) => {
            if (prev) {
                rem.prevTotalOwing = prev.totalOwing;
                rem.prevBalance = prev.balance;

                if (!rem.validated) {
                    rem.interest = !rem.period.yearEnd && prev.totalOwing > 0 && rem.rates && rem.rates.monthlyInterestRate > 0
                        ? round(prev.totalOwing * rem.rates.monthlyInterestRate)
                        : 0;
                }
            } else {
                rem.prevTotalOwing = rem.prevBalance;
            }
            return rem;
        }, null)
        return remittances;
    }

}
