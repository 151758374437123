import { RefList } from '../../framework/infra'
import { round } from '../../framework/utils/helper'

import Remittance from './Remittance'
import RemittanceDistribution from './RemittanceDistribution'


export default class Remittances extends RefList {
    get openRemittances() { return new Remittances(this.filter(rem => rem.isOpen())) }
    get nonValidatedRemittances() { return new Remittances(this.filter(rem => !rem.validated)) }

    get lastClosed() { return this.findLast(rem => rem.isClose()) }
    get lastValidated() { return this.findLast(rem => rem.validated) }

    firstOpenPeriod() { return this.openRemittances.first }

    assignEarningTypes(earningTypes) {
        this.forEach(rem => rem.assignEarningTypes(earningTypes))
    }

    assignRates(historicRates) {
        this.forEach(rem => {
            rem.rates = historicRates.getRatesAtPeriod(rem.period)
            rem.historicRates = historicRates
        })
    }
   
    assignEmployees(employees) {
        console.log('DEPRECATED - should not be called, uses legacy Employee')
        this.forEach(rem => rem.details.forEach(detail => {
            detail.employee = employees[detail.employeeId]
            if (!detail.employee) {
                console.log(`Data integrity Warning! employee: '${detail.employeeId}' is not valid for employee for  ${rem.id} period ${rem.period.value}`)
                //detail.employee = new LegacyEmployee({id: detail.employeeId})
            }
        }))
    }

    distributePayments() {
        this.all.reduce((prev, rem) => {
            rem.startDistrBalance = prev ? prev.clone() : RemittanceDistribution.create({ period: rem.period.value, er: rem.prevBalance})
            rem.currentDistrBalance = RemittanceDistribution.create({ period: rem.period.value, ee: rem.eeAdjustedContribs, er: rem.erAdjustedContribs + rem.intAdjusted - rem.creditUsed, vol: rem.volAdjustedContribs, sol: rem.solAdjusted, int: 0}).setBalance(rem.startDistrBalance)
            rem.endDistrBalance = RemittanceDistribution.create( {period: rem.period.value} ).setBalance(rem.currentDistrBalance)
            rem.finalDistrBalance = RemittanceDistribution.create( {period: rem.period.value} ).setBalance(rem.currentDistrBalance)
            rem.paidDateContribs = rem.paidDateSolvency = ''

            this.all.filter(r => r.period.isSameOrBefore(rem.period)).forEach(r => r.payments.nonRejectedPayments.forEach(pmt => {
                if (rem.period.isSame(pmt.period)) {
                    const pmtRemDistr = RemittanceDistribution.createFromAccDistr( pmt.distribution.getDistribution(rem.period), rem.endDistrBalance)
                    if (!pmtRemDistr.isEmpty()) {
                        rem.endDistrBalance.setBalance(pmtRemDistr)
                        rem.finalDistrBalance.setBalance(pmtRemDistr)
                        if (!rem.paidDateContribs && rem.finalDistrBalance.areContribsPaid(rem.currentDistrBalance['er'])) rem.paidDateContribs = pmt.rcvDate
                        if (!rem.paidDateSolvency && rem.finalDistrBalance.isSolvencyPaid()) rem.paidDateSolvency = pmt.rcvDate
                    }
                }
            }))
            
            rem.finalDistrBalance = RemittanceDistribution.create().setBalance(rem.endDistrBalance);

            if(rem.lockedBalance.isLocked) {
                rem.finalDistrBalance.isLocked = true;
                rem.finalDistrBalance.setBalance(rem.lockedBalance);
            }
            return rem.finalDistrBalance
        }, null)
        return this
    }

    recalculateAllPaymentDistributions() {
        this.all.forEach(rem => rem.payments.nonRejectedPayments.forEach(pmt => this.calculatePaymentDistributions(pmt)))
    }

    calculatePaymentDistributions(payment) {
        const distrTypes = ['EE', 'VOL', 'SOL', 'ER']
        payment.distribution.reset()
        var currentAmountToDistribute = payment.amount

        const remsUntilPaymentDuePeriod = this.all.filter(r => r.period.isSameOrBefore(payment.period))
        remsUntilPaymentDuePeriod.reduce((prev, rem) => {
            const start = prev ? prev.clone() : RemittanceDistribution.create({ period: rem.period.value, er: rem.prevBalance})
            const current = RemittanceDistribution.create({ period: rem.period.value, ee: rem.eeAdjustedContribs, er: rem.erAdjustedContribs + rem.intAdjusted, vol: rem.volAdjustedContribs, sol: rem.solAdjusted, int: 0}).setBalance(start)
            const final = RemittanceDistribution.create({period: rem.period.value}).setBalance(current)
            remsUntilPaymentDuePeriod.find(r => r.payments.nonRejectedPayments.find(pmt => {
                if (pmt === payment) return true
                const pmtRemDistr = RemittanceDistribution.createFromAccDistr( pmt.distribution.getDistributionForPeriod(rem.period), final)
                if (!pmtRemDistr.isEmpty()) final.setBalance(pmtRemDistr)
                return false
            }))

            if(final.hasOutstandingBalance()) {
                distrTypes.forEach(dType => {
                    const oweAmount = final[dType.toLowerCase() + 'Bal']
                    if (oweAmount > 0) {
                        const amountToDistribute = Math.min(oweAmount, currentAmountToDistribute)
                        payment.distribution.pushNew({acc: dType, period: rem.period.value, amount: amountToDistribute})
                        final[dType.toLowerCase() + 'Bal'] = round(final[dType.toLowerCase() + 'Bal'] - amountToDistribute)
                        currentAmountToDistribute = round(currentAmountToDistribute - amountToDistribute)
                    }
                })
            }
            if(rem.period.isSame(payment.period)) {
                if (!payment.distribution['ER-' + payment.period.value]) payment.distribution.pushNew({acc: 'ER', period: rem.period.value, amount: currentAmountToDistribute})
                else payment.distribution['ER-' + payment.period.value].amount = round(payment.distribution['ER-' + payment.period.value].amount + currentAmountToDistribute)
                final['erBal'] = round(final['erBal'] - currentAmountToDistribute)
                currentAmountToDistribute = 0
            } 

            return final
        }, null)
    }

    setAdjustmentsOnRemittanceList = (adjustments) => {
        this._list = this.map((rem) => {
            rem.adjustments._list = adjustments.filter((adj) =>
                rem.period.isSame(adj.period)
            );
            return rem;
        });
    }
    

    //Model attributes
    static definitions = {
        openRemittances: { abstract: true, ref: Remittances, text: 'open remittances'},
        lastValidated: { abstract: true, ref: Remittance, text: 'last validated'},
        lastClosed: { abstract: true, ref: Remittance, text: 'last closed'},
    }
    static ref = Remittance
}

