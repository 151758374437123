import moment from "moment";
import TaskPage from "../../../framework/components/page/TaskPage";
import CalendarValidation from "../../../framework/components/taskValidation/CalendarValidation";
import MultipleChoiceValidation from "../../../framework/components/taskValidation/MultipleChoiceValidation";
import YesNoQuestion from "../../../framework/components/taskValidation/YesNoValidation";
import { toTimestamp } from "../../../framework/utils/helper";
import config from "../../../utils/config";
import { OnboardingTask } from "./TaskConfig/OnboardingTask";
import { Active60Task } from "./TaskConfig/Acitve60Task";

export const ParticipationTaskConfig = {
    default: {
        form: <TaskPage />
    },
    over60Def: {
        params: (participation) => { return [{key: 'sixtiethBirthday', value: moment(participation.person.sixtiethBirthday()).format('DD MMM YYYY')}]},
        validation: (participation) => {
            const participationJurisdiction = participation.employments.last?.employer.plan.jurisdiction;
            const actJurPP = participation.membership.participations.find(pp => pp.keyValue !== participation.keyValue && pp.status.isActive() && pp.employments.last?.employer.plan.jurisdiction === participationJurisdiction);
            const a60sdfEvent = participation.events.last?.code === 'a60sdf';
            const retDefEvent = participation.events.find(ev => ev.code === 'retDef')
            return participation.isDeferredOverAge60() && !a60sdfEvent && !actJurPP && !retDefEvent
        },
        form: <YesNoQuestion onYes={(participation, cmt) => participation.addEvent({code: 'retDef', cmt: cmt})} message={'Have pension documents been requested to employee?'}/> 
    },
    age60Def: {
        params: (participation) => [{key: 'sixtiethBirthday', value: moment(participation.person.sixtiethBirthday()).format('DD MMM YYYY')}],
        validation: (participation) => {
            return participation.person.isTurning60inNext3Months() && participation.status.isDeferred()
        },
        nextTask : 'rfdtDoc',
        form: <YesNoQuestion onYes={(participation, cmt) => participation.addEvent({code: 'retDef', cmt: cmt})} message={'Have pension documents been requested to employee?'}/>

    },
    over60Open: {
        params: (participation) => {return [{key: 'sixtiethBirthday', value: moment(participation.person.sixtiethBirthday()).format('DD MMM YYYY')}]},
        validation: (participation) => {
            const actPenEvent = participation.employments.last?.events.find(ev => ev.code === 'actPen');
            const sdfEvent = participation.membership.participations.find(pp => pp.status.isDeferred() && pp.events.find(ev => ev.code === 'a60sdf'));
            return participation.isOpenOverAge60() && !actPenEvent && !sdfEvent
        },
    },
    rfdtDoc: {
        validation: (participation) => {return participation.events.find(ev => ev.code === 'retDef') && participation.status.isDeferred()},
        nextTask: 'rfdPenDt',
        form: <YesNoQuestion onYes={(participation, cmt) => participation.addEvent({code: 'rfdDoc', cmt: cmt})} message={'Have pension documents been received?'}/>
    },
    rfdPenDt: {
        params: (participation) => {return [{key: 'startDate', value: participation.person.isOverAge60() ? participation.person.sixtiethBirthday(): ''}]},
        validation: (participation) => {return participation.events.find(ev => ev.code === 'rfdDoc')},
        nextTask: 'rfdSend',
        form: <CalendarValidation 
            dateLabel='Pension Start Date' 
            taskSave={(participation, date, comment) => participation.addEvent({code: 'penDt', ets: toTimestamp(date), cmt: comment})} 
            message='Confirm the Pension Start date'
            isWarning
            definedDate='startDate'
        />    
    },
    rfdSend: {
        validation: (participation) => participation.events.find(ev => ev.code === 'penDt'),
        removeValidation: (participation) => { return participation.events.find(ev => ev.code === 'penOnp')},
        form: <YesNoQuestion onYes={(participation, cmt, date) => participation.addEvent({code: 'penOnp', cmt: cmt, ets: toTimestamp(date)})} message={'Has calculation request been sent to actuaries'} isWarning datePicker/>
    },
    terminated:{
        validation: (participation) => {
            return participation.employments.last?.eventStatuses.last.status.isFiredQuit() && !participation.lastStatusEvent.status.isClose() && participation.events.find(ev => ev.code === 'penTrm')},
        removeValidation: (participation) => {return !participation.employments.last?.eventStatuses.last.status.isFiredQuit() || (participation.employments.last?.eventStatuses.last.status.isFiredQuit() && participation.lastStatusEvent.status.isClose())}
    },
    retired: {
        validation : (participation) => {return participation.isPendingClose() && participation.events.find(ev => ev.code === 'penPnr')},
    },
    deceased: {
        validation : (participation) => {return participation.isPendingClose() && participation.events.find(ev => ev.code === 'penDea')},
    },
    rhrdPen: {
        validation: (participation) => {
            const emp = participation.employments.last
            if(emp) {
                const sameJur = participation.membership.isReceivingPension(emp.hiredDate, emp.employer.plan.jurisdiction);
                const formEvent = participation.events.find(ev => ['rPenFrms', 'penEmp'].includes(ev.code));    
                return sameJur && !formEvent && participation.status.isOnlyPotential();
            }
        },
        alwaysShow: true,
        form: <YesNoQuestion onYes={(participation, cmt) => participation.addEvent({code: 'rPenFrms', cmt: cmt})} message={'Has the Member Declaration: Rehiring a Pensioner form been sent to the employee?'}/> 
    },
    wPenFrm: {
        validation: (participation) => {
            return participation.events.find(ev => ev.code === 'rPenFrms') && !participation.events.find(ev => ['frzPenExp', 'susPenEli', 'susPenNEG'].includes(ev.code))
        },
        alwaysShow: true,
        form: <MultipleChoiceValidation 
            choice={[
                {desc: 'Option to freeze pension expired (more than 30 days)',event: {code: 'frzPenExp'}},
                {desc: 'Declaration received, member will suspend pension', event: {code: 'susPenEli', cmt: 'Advise employer to start deductions. Verify if adjustment is required.'}}, 
                {desc: 'Declaration received, member will NOT suspend pension', event: {code: 'susPenNEG'}}]} 
            header={'Rehiring a pensioner'}
            onYes={(participation, selectEvent) => participation.addEvent(selectEvent)}
            />,
    },
    penExpInf: {
        validation: (participation) => {
            return participation.events.find(ev => ev.code === 'frzPenExp') && !participation.events.find(ev => ev.code === 'rPenExpl')
        },
        alwaysShow: true,
        form: <YesNoQuestion onYes={(participation, cmt) => participation.addEvent({code: 'rPenExpl', cmt: cmt})} message={'Has the Rehiring a Pensioner expiration letter been sent to the employee?'}/> 
    },
    onbrd: OnboardingTask,
    // act60: Active60Task, TBD
}