import { Definition } from '../../../../framework/infra'
import { Period } from '../../../../framework/utils'
import { AdjustmentService, RemittanceService } from '../../../../services'
import { Remittance } from '../../../../entities'
import ReportLayout from '../../../../framework/components/page/ReportLayout'
import { RemittanceBusiness } from '../../../../business'

export default class RemittancesSummaryReport extends ReportLayout {
    reportDesc = 'Remittances'
    entity= Remittance
    headers = [
        'period', 'employer.code', 'employer.plan.jurisdiction', 
        'importedDate', 'rates.employerContribution', 'regular', 
        'maternity', 'longTerm', 'self', 'deductions', 'erContribs', 
        'voluntary', 'solvency',  'eeAdjustments', 'erAdjustments', 
        'intAdjustments', 'solAdjustments', 'totalAdjustments', 
        'creditUsed', 'interest', 'total', 'prevBalance',
        'totalPayments', 'balance', 'payments.desc', 'payments.cmt'
    ]
    headerProps = {         
        'deductions': {width: 188},
        'erContribs': {width: 186},
        'eeAdjustments': {width: 188},
        'erAdjustments': {width: 182},
        'intAdjustments': {width: 170},
        'solAdjustments': {width: 176},
        'period': {minWidth: 92},
        'employer.code': {minWidth: 78},
        'employer.plan.jurisdiction': {minWidth: 84},
        'importedDate': {minWidth: 152},
        'rates.employerContribution': {minWidth: 72},
        'payments.desc': {title: 'Payments (Ref # | Receive Date | Amount)', minWidth: 400},
        'payments.cmt': {minWidth: 400},
    }
    tableSort = 'employer.code'
    filters = {
        'employer.plan.jurisdiction': {
            nullable: true, 
            initialValue: this.props.params && this.props.params.employer.plan.jurisdiction
        },
        'employer': {
            sortBy: 'code', 
            display: 'longDesc', 
            nullable: true, initialValue: this.props.params && this.props.params.employer
        },
    }
    params = {
        from: {
            definition: {
                type: Definition.types.PERIOD, 
                text: "From Period", 
                options: Period.getPeriods(
                    Period.getLaunchPeriod().dec(1), 
                    Period.getCurrentPeriod(), 
                    true
                ),
            }
        },
        to: {
            definition: {
                type: Definition.types.PERIOD, 
                text: "To Period", 
                options: Period.getPeriods(
                    Period.getLaunchPeriod().dec(1), 
                    Period.getCurrentPeriod(), 
                    true
                )
            }
        }
    }

    async execQuery(queryParams) {
        RemittanceService.invalidateCache();
        AdjustmentService.invalidateCache();
        
        const remittances = await RemittanceService.getAll();
        const adjustments = await AdjustmentService.getAll();

        const remittancesWithAdjustments = remittances.map(remittance => {
            const adjustmentsForRemittance = adjustments.filter(adjustment => 
                adjustment.remittance.keyValue === remittance.keyValue   
            );
            remittance.adjustments = adjustmentsForRemittance;
            remittance.totalAdjustments = (Math.round(adjustmentsForRemittance.reduce((prevAmount, currentAmount) => 
                prevAmount += currentAmount.total, 0) * 100) / 100
            );
            return remittance;
        }).map((remittance, index, remittanceList) => {
            const employerRemittances = remittanceList.filter(rem => 
                rem.employer.keyValue === remittance.employer.keyValue
            );
            if (!remittance.validated) {
                RemittanceBusiness.refreshBalances(employerRemittances);
            }
            return remittance;
        });  

        return remittancesWithAdjustments.filter(rem => 
            rem.period.isSameOrBefore(queryParams.to) && rem.period.isSameOrAfter(queryParams.from)
        ).sort((a,b)=> a.period.isAfter(b.period) ? 1 : -1);
    }
}