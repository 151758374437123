import React from 'react'
import { Definition } from '../../../framework/infra'
import { Modal } from '../../../framework/containers'
import { renderDate } from '../../../framework/utils/renders'
import { moment } from '../../../framework/utils/helper'
import { EmploymentService } from '../../../services'
import { Employment } from '../../../entities'
import ReportLayout from '../../../framework/components/page/ReportLayout'
import MemberDetails from '../MemberDetails'

export default class EmployerAge60Report extends ReportLayout {
    reportDesc = `Members Turning 60`
    entity = Employment
    headers = ['person.sin', 'person.name', 'person.dob', 'participation.ppNo', 'participation.statusDesc', 'participation.lastStatusEvent.effDt', 'event.desc', 'event.effDt']
    headerProps = {
        'event.desc': {title: 'Employment Status'},
        'participation.lastStatusEvent.effDt': {title: 'Effective Date', format: renderDate, width: 150},
        'participation.statusDesc': {title: 'PP Status', width: 150},
        'participation.ppNo': {title: 'PP #', width: 100},
        'person.sin': {width: 150},
        'person.dob': {width: 150},
        'event.effDt': {width: 150}
    }
    tableSort = 'person.name'
    filters = {}
    params = {
        from: {definition: {type: Definition.types.DATE, text: "From", default: moment().format('YYYY-MM-DD')}},
        to: {definition: { type: Definition.types.DATE, text: "To", default: moment().add(3, 'months').format('YYYY-MM-DD')}}
    }
   
    execQuery(queryParams) {
        return EmploymentService.getEmployerEmployments(this.props.params.employer.id).then(employments => {
            return employments.filter(employment => {
                const bDay60 = employment.person.getBirthdayForAge(60)
                return bDay60 >= queryParams.from && bDay60 <= queryParams.to 
            })
        })
    }
    handleRowSelect(row, showDetails) {
        return (<Modal className='h-100 w-80 modal-bg-color'>
            <MemberDetails personId={row.person.id} activeTab={showDetails} onCancel={this.handleCancel.bind(this)}/>
        </Modal>)
    }
}
