import { Definition, Ref, RefDated } from '../../framework/infra'
import Person from './Person'
import SpouseHistory from './SpouseHistory'
const { DATE, CHOICE, STRING, BOOLEAN } = Definition.types

export default class Spouse extends RefDated {
    get maritalStatus() { return Spouse.getDefinition('mSts')?.getOptionText(this.mSts) ?? '' }
    get taxStatus() { return Spouse.getDefinition('taxationStatus')?.getOptionText(this.taxationStatus) ?? '' }
    
    get isSingle() { return !this.mSts || this.mSts === 'single' };
    get isMarried() { return this.mSts === 'married' };
    get isCommonLaw() { return this.mSts === 'commonLaw' };
    get isSeparated() { return this.mSts === 'separated' };
    get isDivorced() { return this.mSts === 'divorced' };
    get isWidowed() { return this.mSts === 'widow' };

    get name() { return this.noSin ? this.unknownPerson.name : this.person.name }
    get dob() { return this.noSin ? this.unknownPerson.dob : this.person.dob }

    static definitions = {
        person: { ref: require('./Person') },
        unknownPerson: { ref: require('./UnknownPerson') },
        noSin: { type: BOOLEAN },
        name: { abstract: true, type: STRING, text: 'Name' },
        dob: { abstract: true, type: DATE, text: 'Date of Birth' },
        marriageDate: { type: DATE, text: 'Marriage Date' },
        maritalStatusDeclarationReceived: { type: DATE, text: 'Judgement Received' },
        maritalBreakDownDate: { type: DATE, text: 'Breakdown Date' },
        maritalBreakDownOption: { type: CHOICE, text: 'Marital Breakdown', options: [
            { key: '', text: '', text_fr: ''},
            { key: 'settled', text: 'Yes - Settled', flag: { color: '#B00'}},
            { key: 'Pending', text: 'Yes - Pending', flag: { color: '#B00'}},
            { key: 'maybe', text: 'Data Conflict', flag: { color: '#ffc107'}},
        ]},
        judgementOrderReceivedOption: { type: CHOICE, text: 'Reallocation of Assets', options: [
            { key: '', text: '', text_fr: ''},
            { key: 'noAssigned', text: 'Nothing Assigned'},
            { key: 'assignedSettled', text: 'Portion Assigned-Payment Settled'},
            { key: 'assignedNoSettled', text: 'Portion Assigned-Payment Not Settled'},
        ]},
        judgementOrderReceived: { type: STRING, text: 'Judgement/Order Received' },
        taxationStatus: { type: CHOICE, text: 'Taxation Status', options: [
            { key: 'taxExempt', text: 'Tax-Exempt'},
            { key: 'taxable', text: 'Taxable', text_fr: 'Yes-Pending'},
        ]},
        taxStatus: { abstract: true, type: STRING, text: "Spouse Taxation Status" },
        jurisdiction: { type: CHOICE, text: 'Jurisdiction', options: [
            { key: 'fed', text: 'Federal'},
            { key: 'que', text: 'Quebec'},
            { key: 'both', text: 'Federal & Quebec'},
        ]},
        maritalStatus: { abstract: true, type: Definition.types.STRING, text: 'Marital Status' },
        mSts: { type: Definition.types.CHOICE, text: 'Marital Status', text_fr: 'Marital Status', options: [
            { key: 'single', text: 'Single', text_fr: 'Celibatere'},
            { key: 'married', text: 'Married', text_fr: 'Marrié/e'},
            { key: 'commonLaw', text: 'Common Law', text_fr: 'Conjoint'},
            { key: 'separated', text: 'Separated', text_fr: 'Separé/é'},
            { key: 'divorced', text: 'Divorced', text_fr: 'divorcé/é'},
            { key: 'widow', text: 'Widowed', text_fr: 'Veuve/Veuf'}
        ]},
        id: { type: Definition.types.STRING },
        parent: { type: Definition.types.STRING },
    }

    static key = 'id'
}


