import moment from "moment"
import MultipleResolution from "../../../../framework/components/taskValidation/MultipleResolution"
import { FLOW } from "../../../flow/flow";

const getCategory = (participation) => {
    const prior2008 = moment(participation.joinDt).isBefore(moment('01-01-2008'));
    if(prior2008) return FLOW.ACT60_Eligible;
    if(participation.membership.participations.find(pp => pp.status.isDeferred())) return FLOW.ACT60_Eligible;
    return FLOW.ACT60_NOT_Eligible;
}

export const Active60Task = {
    desc: 'Age60',
    isMultipleResolution: true,
    validation: (participation) => {return participation.person.isTurning60inNext3Months() && participation.status.isActive() },
    removeValidation: (participation) => {
        const act60Event = participation.events.find(ev => ev.code === 'age60Flow');
        return act60Event && act60Event.config.canShow({instance: participation, event: act60Event});
    },
    form: <MultipleResolution category={getCategory} code='age60Flow'/>
}