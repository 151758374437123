import { Definition, Entity } from '../../framework/infra'
import { ParticipationEvent } from './participation'
import ParticipationEvents from './participation/ParticipationEvents'
export default class MemberSummary extends Entity {
    
    get ppEvent() {
        return this.lastPPEvents?.statusEvents?.last;
    }

    get ppJnDt() {
        return this.lastPPEvents?.statusEvents?.find(x=>x.status.isActive())?.effDt ?? '';
    }

    static definitions = {
        sin: { type: Definition.types.SIN, text: 'SIN', text_fr: 'NAS' },
        name:  { type: Definition.types.STRING, text: 'Name' },
        dob: { type: Definition.types.DATE, text: 'Date of Birth' },
        phone: { ref: require('../address/Phone'), text: 'Phone', text_fr: 'Telephone' },
        email: { ref: require('../address/Email'), text: 'Email' },
        cmt:  { type: Definition.types.STRING, text: 'Comment' },
        ppJnDt: { abstract: true, type: Definition.types.DATE, text: 'Join Date' },
        ppEr: { type: Definition.types.STRING, text: 'Code' },
        ppEvent: { abstract: true, ref: ParticipationEvent, text: 'Status'},
        lastPPEvents: {ref: ParticipationEvents, text: 'Status'},
        spouseName: { type: Definition.types.STRING, text: 'Spouse' },
        contactOf: { type: Definition.types.STRING, text: 'Contact For' },
        benefOf: { type: Definition.types.STRING, text: 'Beneficiary For' },
        isSpouse: { type: Definition.types.BOOLEAN },
        isMember: { type: Definition.types.BOOLEAN },
        isContact: { type: Definition.types.BOOLEAN },
        isBeneficiary: { type: Definition.types.BOOLEAN },
    }
}
