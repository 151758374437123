import Definition from './Definition';
import RefMessage from './RefMessage'
import RefPointer from './RefPointer';

export default class RefEvent extends RefMessage {
    constructor(data) {
        super(data);
        if (data) {
            this.mts = data.mts ?? data.ets;
        }
    }
    
    static key = ['key', 'ets']

    static compareEvents = (eventA, eventB) => {
        let result = 0;
        if(eventA.config?.priority > eventB.config?.priority){
            result = 1;
        }else if(eventA.config?.priority < eventB.config?.priority){
            result = -1;
        }else if (eventA?.mts < eventB?.mts) {
            result = -1;
        } else if (eventA?.mts > eventB?.mts) {
            result = 1;
        } else { 
            result = eventA?.rts < eventB?.rts ? -1 : (eventA?.rts === eventB?.rts ? 0 : 1);
        }
        return result;
    }

    static sortByEffectiveDate = (a, b) => {
        let result = 0;

        if (a.effDt === b.effDt) {
            result = this.compareEvents(a, b);
        } else if (a.effDt < b.effDt) {
            result = -1;
        } else {
            result = 1;
        }
        return result;
    }

    static getSingleEventsInHiddenGroup = (eventParam, instance, ppConfigList) => {
        const hiddenGroupEvents =
            eventParam.config?.hiddenGroup &&
            Object.entries(ppConfigList)
                .map(([key, config]) => ({ key, config }))
                .filter(
                    (configItem) =>
                        configItem.config?.hiddenGroup ===
                        eventParam.config?.hiddenGroup
                );
        return (
            eventParam.config?.singleEventInGroup &&
            instance.events.filter((ev) =>
                hiddenGroupEvents.map((e) => e.key).includes(ev.code)
            )
        );
    }

    get eventDesc() { 
        //multiple resolutions may have various completion messages
        if (this.subEvent?.all.length > 0) {
            let lastSubEvent = this.subEvent.last;
            if (lastSubEvent.config.completeMessage) {
                return this.config.showTask.desc + ' - ' + lastSubEvent.config.completeMessage;
            }
        }

        if (this.config.dynamicDesc) return this.config.dynamicDesc(this);

        return this.desc 
    }

    static definitions = {
        metadata: { text: 'Extra metadata' },
        pointers: { list: true, ref: RefPointer, text: 'Pointers' },
    }
}
