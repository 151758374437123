import { useState } from "react";
import { Button } from "react-bootstrap";
import { EInput, Question } from "../../framework/components";
import { FieldSet, Row, Title } from "../../framework/containers";
import PersonSummary from '../person/PersonSummary'
import UnknownPersonForm from "../person/UnknownPersonForm";
import { Check } from "../../framework/inputs";
import { Select } from "../../framework/controls";
import { BeneficiaryService } from "../../services";
import { Person } from "../../entities";

const DELETE_CONFIRMATION = 'Are you sure you want to delete this beneficiary?';
const TARGET_ALL = 'vested';

const BeneficiaryForm = ({beneficiary, membership, onClose, onSave, onAdd, onDelete}) => { 
    const isNew = beneficiary?.isNew();
	
    let hasVested = membership.vestedParticipations.length > 0;
    let originalParticipation = membership.participations.find(x=>x.no === beneficiary.participationNo)?.no ?? (hasVested ? TARGET_ALL :  membership.participations.last.no);
    let clone = beneficiary.clone();

    clone.participationNo = originalParticipation;

    const [question, setQuestion] = useState();
 	const [draft, _setDraft] = useState(clone);
	const [errors, setErrors] = useState({});

    const handleParticipationSelect = (value) => {
        draft.participationNo = value;
        render();
    }
	const setDraft = (draft) => _setDraft(draft.clone());
	const handleCancel = () => { if (onClose) onClose() }	
	const render = () => setDraft(draft);

	const handlePersonChange = (person) => {
		draft.person = person;
		setDraft(draft);
	}

    const handleHasSINToggle = () => {
		draft.noSin = !draft.noSin;
        if (draft.person.id === '') draft.person = new Person();
		setDraft(draft);
	}

    const handleDelete = (answer) => {
        if(answer) { 
            onDelete(beneficiary)
            onClose() 
        }
        setQuestion();
    }

    const handleDeleteQuestion = () => {
        setQuestion(<Question title={DELETE_CONFIRMATION} onAnswer={(answer) => handleDelete(answer)} />);
    }

    const handleSave = (fn) => {
		if (isValid()) {
			if (fn) fn(BeneficiaryService.preSave(draft));
			onClose()
		}
	}

    const isValid =  () => {
        const errors = BeneficiaryService.validate(draft);
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return false;
        }
        return true;
    }

    const check = <Check name='sin' value={draft.noSin} label='No SIN' lcn='checkbox-inline' onChange={handleHasSINToggle} />

    const participationOptions = [
        ...membership.participations.all.map(participation => ({
            key: participation.no,
            text: 'PP#'+ participation.no + ' Join Date: ' + participation.joinDt, 
            value: participation.no,
        }))
    ];
    if (isNew && hasVested) participationOptions.push({ key: TARGET_ALL, text: 'All vested participations', value: membership })

    return <><Title title='Beneficiary Form' onHide={onClose} />
    <FieldSet title='Beneficiary Information' className='mt-1 mb-1' variant='field'>
        <div className="grid-ellipsis">
            <EInput name='participationNo' label='Participation' instance={draft} showRequiredLabelMarker>
                <Select options={participationOptions} value={draft.participationNo} onChange={handleParticipationSelect} />
            </EInput> 
        </div>
        <div className="grid-ellipsis mt-2">
            {!draft.isLegacy && <> 
                <EInput error={errors.relationship} name='relationship' showRequiredLabelMarker instance={draft} />
                <EInput error={errors.shareOfProceeds} name='shareOfProceeds' showRequiredLabelMarker instance={draft} /> 
            </> }
            <EInput error={errors.designationReceivedDate} name='effDt' showRequiredLabelMarker instance={draft}/>
        </div>
        {draft.isLegacy && <div className="line g10">
            <EInput variant="textarea" name='legacy' instance={draft} readOnly={true}/>
        </div>}
    </FieldSet>

    { (!draft.noSin && !draft.isLegacy && draft.person) && <PersonSummary errors={errors} 
        title={<Row> Person {check}</Row>} 
        allowPersonChange={true} person={draft.person} onSwitchPerson={handlePersonChange} onNewPerson={handlePersonChange}/> 
    }
    { (draft.noSin && !draft.isLegacy)  && <UnknownPersonForm errors={errors} person={draft.unknownPerson} 
        title={<Row> Person {check}</Row>} 
        /> 
    }
    <div className="line g10 mt-2">
        <EInput variant="textarea" name='cmt' instance={draft}/>
    </div>
    <Row className='justify-content-end button-row mt20'>
        {!isNew && <Button onClick={handleDeleteQuestion} className='btn-secondary'> Delete </Button>}
        <Button onClick={() => handleSave(isNew ? onAdd : onSave)}> {isNew ? 'Add':'Save'} </Button>
        <Button onClick={handleCancel}> Cancel </Button>
    </Row>
    { question }
</>

}
export default BeneficiaryForm;