import React from "react"
import { Component } from '../../framework/components'
import { Choice } from '../../framework/controls'
import { sort } from '../../framework/utils/helper'

import employerService from '../../services/employer/EmployerService'

export default class EmployeeSelection extends Component {
    load() {
        return employerService.getAll().then(employers => ({employers}))
    }

    view() {
        const { employerId, className, selected, onSelect } = this.props 
        const employer = this.state.employers.find(er => er.id === employerId)
        const employees = employer ? [{ key: 'empty', text: '-' }].concat(sort(employer.employees, 'searchDesc').map(ee => ({ key: ee.id, text: ee.searchDesc }))) : []
        return <Choice className={className} options={employees} value={selected || 'empty'} onChange={onSelect} label={this.props.label} ></Choice>
    }
}
