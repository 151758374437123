
import React from 'react'
import { Control, Select } from '../../controls'
import { getSafe } from '../../utils/helper'

export default class ESelect extends React.Component {
    render() {
        var { name, value, entity, instance, definition, options, nullable, readOnly } = this.props
        definition = definition || getSafe(instance, 'constructor.definitions.' + name)
        entity = entity || (definition && definition.getRef()) || (value && value.constructor)

        var key = value || instance[name]
        if (!options) {
            key = value ? (value.keyValue || value.key || value) : (entity && entity.default && entity.default.key)
            options = options || (entity && entity.types && Object.values(entity.types)) || []
            options = options.map(opt => ({key: (opt.keyValue), text: (opt.desc || opt.text), value: opt}))
        } else {
            options = options && options.all ? options.all : options
        }

        return <Select className= "col" name={name} value={key} options={options} onChange={this.handleChange.bind(this)} nullable={nullable} readOnly={readOnly}/>
    }

    handleChange(key, value) {
        if(this.props.onChange) this.props.onChange(value)
    }
}
