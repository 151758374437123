import { RefTask } from '../../../framework/infra'
import ParticipationEvent from './ParticipationEvent'
import { ParticipationTaskConfig } from './ParticipationTaskConfig'

export default class ParticipationTask extends RefTask {
    get config() { return { ...ParticipationTaskConfig['default'], ...ParticipationTaskConfig[this.code]}}
    get form() {return ParticipationTaskConfig[this.code]?.form || ParticipationTaskConfig['default'].form }
    hasFlow() {
        return ParticipationTaskConfig[this.code]?.form;
    }

    static messages = [
        ['age60Def', 'Member will be age 60 on {sixtiethBirthday} date. Request Pension documents to commence pension.', 'Members turning 60 years in the next three months. Status: Deferred', 't'],
        ['over60Open','Member is 60 years old on {sixtiethBirthday} date. Confirm pension data with the employer and request pension documents.', 'Active & Over 60', 't'],
        ['over60Def','Member is 60 years old on {sixtiethBirthday} date. Request pension documents to commence pension.', 'Deferred Over 60', 't'],
        ['age60Open', 'Member will be age 60 on {sixtiethBirthday} date. Confirm Age60 data & validate if eligible to Age60 Declaration.', 'Active & Age 60', 't'],
        ['age71Open', 'Member will be age 71 on {seventyfirstBirthday} date. Confirm Age71 data & validate if eligible to Age71 Declaration.', 'Members turning 71 years in the next three months. Participation: Open', 't'],
        ['procPay', '[Pay Auth] Process and send payment authorization to RBC', 'Pay Auth', 't', {possibleEvents: ['paySent']}],
        ['reqEnF', 'Request Enrollment Form', 'Request Enrollment Form', 't'],
        ['terminated', 'Employee is terminated - Confirm the data to process termination calculation and ensure final payout was made.', 'Pending - Terminated', 't'],
        ['deceased', 'Employee is deceased - Confirm the data to process death benefit calculation and ensure final payout was made.', 'Pending - Deceased', 't'],
        ['retired', 'Employee is retired - Confirm the data to process retirement calculation and ensure final payout was made.', 'Pending - Pensioner', 't'],
        ['rfdtDoc', 'RFD - Pension documents requested - Waiting for employee to submit documents','RFD - Waiting for pension documents', 't'],
        ['rfdSend','Send calculation request to actuaries', '', 't'],
        ['rfdPenDt', 'Confirm the Pension Start date', '', 't'],
        
        
        ['ACT-SNP', '[ACT/SNP] Member is Eligible to unreduced pension while continuing to work. Age60 Declaration sent to employee & employer', 'ACT-SNP', 't'],
        ['ACT-OSO', '[ACT/OSO] Member elected to receive pension while continuing to work. Calculation request sent to Mercer.', 'ACT-OSO', 't'],
        ['ACT-TRF', '[ACT/TRF] Possible transfer to other jurisdiction.', 'ACT-TRF', 't'],
        ['LTD-SNP', '[LTD/SNP] Member on LTD & turned 60 on {dd-mmm-yy}. Emailed {ER} to determine whether member will continue accruing benefits or take an unreduced pension', 'LTD-SNP', 't'],
        ['LTD-SXT', '[LTD/SXT] Member on LTD & chose to commence pension at age 60. Calculation request sent to Mercer', 'LTD-SXT', 't'],
        ['LTD-OSO', '[LTD/OSO] Options package mailed by Mercer. Waiting for member to submit Election form', 'LTD-OSO', 't'],
        ['S-TNP', '[S/TNP] Last day at {ER}. Awaiting Termination Data from {ER}. Member must decide whether to transfer or terminate', 'S-TNP', 't'],
        ['S-OTF', '[S-OTF] Outstanding Transfer to {Other Juris} plan. Waiting for member election', 'S-OTF', 't'],
        ['T-ONP', '[T/ONP] Calculation request sent to Mercer', 'T-ONP', 't'],
        ['P-ONP', '[P/ONP] Calculation request sent to Mercer', 'P-ONP', 't'],
        ['P-OPO', '[P/OPO] Options package mailed by Mercer. Waiting for member to submit Election form', 'P-OPO', 't'],
        ['D-ODO', '[D/ODO] Options package mailed by Mercer. Waiting for member’s spouse/beneficiary(ies) to submit Election form', 'D-ODO', 't'],

        ['rhrdPen', 'Send Member Declaration: Rehiring a Pensioner form', 'Send Member Declaration', 't'],
        ['wPenFrm', 'Waiting for Member Declaration: Rehiring a Pensioner form from employee','Rehiring Pensioner waiting', 't'],
        ['penExpInf', 'Deadline for rehiring a pensioner expired - Inform employee that pension will not be suspended', 'Rehiring Pensioner expired', 't'],
        ['infEmpDed', 'Inform employee of automatic deductions', '', 't'],

        ['onbrd', 'Onboarding - In progress', '', 't'],
        ['onbrdDffJur', 'Send Membership sustained with new employer {Important change of Jurisdiction} notice to employee and employer.', '', 't'],
        ['onbrdSmJur', 'Send Membership sustained with new employer notice to employee and employer.', '', 't'],
        ['trfNotElg', 'Send Membership Transfer {Not Eligible} to employee and employer', '', 't'],
        ['act60', 'Age60 - In progress', '', 't']
    ]

    static definitions = {
        form: {abstract: true}
    }

    static validationTask(){
        return Object.values(this.messages).filter(task => ParticipationTaskConfig[task.key])
    }

    static alwaysShownTask(){
        return Object.values(this.messages).filter(task => ParticipationTaskConfig[task.key]?.alwaysShow)
    }


    static events = ParticipationEvent.messages
}
