import React, { useState } from "react";
import { Title } from "../../containers";
import { Button } from "react-bootstrap";
import CollapsibleCard from "../../../components/containers/CollapsibleCard";
import { SubEventConfig as configs  } from "../../../entities/flow/SubEventConfig";
import Question from "../Question";
import moment from "moment";

const DEFAULT_TITLE = "Resolution Task";

const MultipleResolution = ({ code, instance, onClose, onSave, title = DEFAULT_TITLE }) => {

    let flowEvent = instance.events.findOrCreate('code', { code });

    const canReset = false;
    const [flowEventDraft, setFlowEventDraft] = useState(flowEvent.clone());
    const [loadingTask, setLoadingTask] = useState([]);
    const [isDirty, setIsDirty] = useState();
    const [canceling, setCanceling] = useState(false);

    const isTaskLoading = (key) => { return loadingTask.find(x=>x === key) }
      
    const tasks = flowEventDraft.subEvent.constructor.ref.getTaskList(instance, flowEventDraft);;

    //display is different if there is only one task
    const oneTask = tasks.length === 1 && !tasks[0].validated;

    const handleSave = (event) => {
        flowEventDraft.subEvent = flowEventDraft.subEvent.getFiltered(e => e.code !== event.code);
        flowEventDraft.subEvent.pushEvent(event);
        flowEventDraft.subEvent = flowEventDraft.subEvent.sortBy('rts');
        setFlowEventDraft(flowEventDraft);
        setLoadingTask(loadingTask.filter(x => x !== event.code));
        handleChange();
        
        if (oneTask) handleFinalize() //trigger save because only one task
    }

    const handleSkip = (event) => {
        event.metadata = { skipped: true };
        handleSave(event);
    }

    const handleChange = () => {
        if (!isDirty) {
            setFlowEventDraft(flowEventDraft.clone());
            setIsDirty(true);
        }
    } 

    const handleClose = () => {
        if (isDirty) setCanceling(true) 
        else onClose()
    }

    const handleReset = () => {
        flowEventDraft.subEvent.reset();
        setFlowEventDraft(flowEventDraft);
        handleChange();
    }

    const onAnswer = (answer) => {
        if (answer) onClose();
        else setCanceling(false); 
    }

    const handleFinalize = () => {
        flowEvent.setData(flowEventDraft);
        if (flowEvent.subEvent.last) flowEvent.ets = flowEvent.subEvent.last?.ets;
        if (!instance.events.find(e => e.code === code)) instance.events.pushEvent(flowEventDraft);
        instance.events = instance.events.getFiltered(e => e.code !== flowEvent.code);
        instance.addEvent(flowEvent);

        onSave(instance);
    }

    const handleUndo = (key) => {
        setLoadingTask([...loadingTask, key]);
        flowEventDraft.subEvent = flowEventDraft.subEvent.getFiltered(e => e.code !== key);
        setFlowEventDraft(flowEventDraft);
        setLoadingTask(loadingTask.filter(x => x !== key))
        handleChange();
    }

    const createOrEditTask = (key) => {
        let existingEventIndex = flowEventDraft.subEvent.findIndex(x=>x.code === key);
        let isNew = existingEventIndex === -1 ? true : false;
        let isOrphan = false;
        let task = tasks.find(x=>x.key === key);
        let event = flowEventDraft.subEvent.findOrCreate('code', {code: key})

        if (!isNew) {
            let children = task.config.children ?? [];
            if (!flowEventDraft.subEvent.find(x=>children.includes(x.code))) isOrphan = true;
        } 

        return React.cloneElement(task.config.form, { 
            flowEvent: flowEventDraft,
            event: event,
            instance: instance, 
            validated: task.validated,
            resolution: task.resolution,
            saveLabel: task.config.saveLabel,
            canUndo: isOrphan && !isNew,
            isDirty: isDirty,
            onSkip: handleSkip,
            onUndo: handleUndo,
            onSave: (e) => { setLoadingTask([...loadingTask, key]); handleSave(e) },
            onChange: !isNew ? handleChange : null, //detect input changes editing existing event fields
            onFinalize: handleFinalize,
        })
    } 

    const TaskComponent = ({taskKey}) => {
        let task = tasks.find(x=>x.key == taskKey);
        return tasks.validated && task.config.resolutionMessage ? task.config.resolutionMessage : createOrEditTask(taskKey);   
    }

    return <div>
                <Title title={title} onHide={handleClose} />
                { oneTask ? <TaskComponent taskKey={tasks[0].key}/>
                : <><div className='transfer-detail'>
                    { tasks.map((task) =>  {
                        return (!isTaskLoading(task.key)) && <CollapsibleCard key={task.key} disabled={task.disabled} validated={task.validated} title={task.config.task} >
                            <div className="transfer-cols">
                                <div className="transfer-card-col w-100">
                                    <TaskComponent taskKey={task.key}/>
                                </div>
                            </div>
                        </CollapsibleCard>})
                    }
                    </div> 
                    <div className='footer-buttons mt15 button-row g15'>
                        <Button key='close' className='btn-secondary' onClick={handleClose}>Close</Button>
                        { canReset && <Button key='reset' className='btn-secondary' onClick={handleReset}>Reset</Button>}
                        <Button key='save' className={'btn-primary ' + (!isDirty ? 'disabled':'')} onClick={handleFinalize}>Save</Button>
                    </div></>
                }
                {canceling && <Question title='You have unsaved changes, are you sure you want to cancel?' onAnswer={onAnswer} />}

            </div>;
}

export default MultipleResolution;