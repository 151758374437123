import React from 'react'
import { Component } from '../../../../framework/components'
import { Row } from '../../../../framework/containers'
import { Date, Text } from '../../../../framework/controls'

import { EmployerService, AdjustmentService } from '../../../../services'
import EmployerSelection from '../../../employer/EmployerSelection'
import EmployeeSelection from '../../../employee/EmployeeSelection'

export default class EmployeeStatusChange extends Component {
    load() {
        return EmployerService.getAll().then(employers => {
            const params = {
                employers: employers,
                employerId: this.props.employerId,
                employeeId: this.props.employeeId,
                effDate: this.props.effDate,
                comment: this.props.comment,
            }
            this.props.onChange(this.createAdjustments(params))
            return params
        })
    }
    
    view() {
        const { employerId, employeeId, effDate, comment } = this.state
        return <Row className={this.props.classname}>
            <EmployerSelection onSelect={this.handleChange.bind(this, 'employerId')} className='col' label='Employer' selected={employerId}/>
            <EmployeeSelection onSelect={this.handleChange.bind(this, 'employeeId')} className='col ml-3' selected={employeeId} employerId={employerId} label='Employee'/>
            <Date name='effDate' onChange={this.handleChange.bind(this, 'effDate')} value={effDate} label='Effective Date' className='ml-3 ' />   
            <Text name='comment' onChange={this.handleChange.bind(this, 'comment')} value={comment} label='Comment' className='pl-3 stretch' />
        </Row>
    }

    handleChange(field, value) {
        const params = Object.assign({}, this.state, {[field]: value})
        if (field === 'employerId') params.employeeId = null
        this.setState(params)
        this.props.onChange(this.createAdjustments(params))
    }

    createAdjustments(params) {
        const { employers, employerId, employeeId, effDate, comment } = params
        const employer = employers.find(er => er.id === employerId)
        const employee = employer && employer.employees.find(ee => ee.id === employeeId)
        if (!employer || !employee || !effDate) return []
        return AdjustmentService.createEmployeeStatusChangeAdjustments(employer, employee, effDate, comment)
    }
}