import React from 'react'
import { Component } from '../../../../framework/components'
import { PeriodSelect, Text } from '../../../../framework/controls'
import { Row } from '../../../../framework/containers'
import { EmployerService, SolvencyService, AdjustmentService } from '../../../../services'

export default class Covid19Relief extends Component {
    load() {
        return Promise.all([
            EmployerService.getAll(),
            SolvencyService.getSolvencies()
        ]).then(([employers, solvencies]) => {
            const params = {
                employers: employers,
                solvencies: solvencies,
                period: this.props.period,
                comment: this.props.comment
            }
            this.createAdjustments(params)
            return params
        })
    }
    
    view() {
        const { period, comment } = this.state
        return <Row className={this.props.classname}>
            <PeriodSelect name='period' onChange={this.handleChange.bind(this, 'period')} value={period} label='Period'/>
            <Text name='comment' onChange={this.handleChange.bind(this, 'comment')} value={comment} className='pl-3 stretch' label='Message'/>
        </Row>
    }
    
    handleChange(field, value) {
        this.setState({[field]: value})
        this.props.onChange(this.createAdjustments(Object.assign({}, this.state, {[field]: value})))
    }

    createAdjustments(params) {
        const { employers, solvencies, period, comment } = params
        if (!period) return []
        return AdjustmentService.createCovid19Adjustments(employers, solvencies, period, comment)
    }
}