import React, {useState, useEffect} from 'react';

import PersonSummary from '../person/PersonSummary';
import EmploymentHistoryList from './EmploymentHistoryList';
import EmploymentFinancialTable from "./EmploymentFinancialTable";
import HistoricalList from './HistoricalList';
import MemberParticipations from '../member/MemberParticipations';
import Button from '../../framework/controls/Button';
import EmploymentBusiness from '../../business/EmploymentBusiness';
import EventPage from '../../framework/components/page/EventPage';
import useNotification from '../../hooks/useNotification';

import { Form, Icon } from '../../framework/controls';
import { ParticipationEvent } from '../../entities';
import { EmploymentEvent } from '../../entities/employment';
import { getSafe, sort } from '../../framework/utils/helper';
import { EInput, Question } from '../../framework/components';
import { Row, Tabs, Modal, Title, FieldSet } from '../../framework/containers';
import { AdjustmentService, EmployerService, EmploymentService, MembershipService, RemittanceDetailService } from '../../services';
import Loading from '../../components/containers/Loading';
import MemberDetails from '../member/MemberDetails';
import { EMPLOYMENT_SOURCE } from '../../entities/employment/Employment';
import SuperAdminGuard from '../../guards/SuperAdminGuard';
import RemittanceDetailsAdmin from '../financial/remittance/RemittanceDetailsAdmin';
import moment from 'moment/moment';
import { ParticipationBusiness } from '../../business';

const EmployerDropdown = ({employment, checkExistingEmployment, validateField}) => {
	const [employers, setEmployers] = useState();

	useEffect(() => {
        let isMounted = true;

		EmployerService.getAll().then(employers =>  {
			if (isMounted) setEmployers(employers);
		}) 

		return () => { isMounted = false };
	}, []);

	const handleEmployerChange = () => {
		const employer = employment.employer;
		const person = employment.participation.membership.person;
		checkExistingEmployment(person, employer);
		validateField()
	}

	const employerOptions = employers ? sort(employers.activeEmployers.all, 'code').map(emp => ({ key: emp.keyValue, text: emp.code + '  -  ' + emp.name, value: emp })) : [];
	return <EInput cn='col-6' name="employer" instance={employment} options={employerOptions} onChange={handleEmployerChange}/>;
}

const EmploymentForm = ({employer, membership, ee, readOnly, className, onCancel, onDeleteEmployment, onSave }) => {

	if(ee.isNew()) { //if we're adding a new emp, assign params passed
		if (membership) ee.participation.membership = membership;
		if (employer) ee.employer = employer;
	}

	const [adminRem, setAdminRem] = useState(false);
	const [warning, setWarning] = useState();
	const [newEvent, setNewEvent] = useState();
	const [isDeletable, setIsDeletable] = useState(false);
	const [isDirty, setIsDirty] = useState(false);
	const [employment, _setEmployment] = useState(ee);
	const [isLoading, setIsLoading] = useState(true);
	const [selectedHistItem, setSelectedHistItem] = useState();
	const [existingEmployment, setExistingEmployment] = useState();
	const [displayEmpExistMsg, setDisplayEmpExistMsg] = useState();
	const [activeTab, setActiveTab] = useState("employment");
	const { addMessage } = useNotification();
	//forces re-renders when rems change 
	const [uniqueKey, setUniqueKey] = useState(0); 

	const person = employment.participation.membership.person;
	const validation = { fields: EmploymentBusiness.getMandatoryFields('person') };
	const modalTitle = employment.isNew() ? {title:'New Employment'} : {
			title: <>{person.desc} { employment.source ? 
				<Icon tooltip={employment.sourceText} 
					  icon='user-circle' 
					  className='text-primary' 
					  tooltip-right 
					  large/> 
				: null}</>, 
			midtitle: employment.employer.code, 
			subtitle: employment.participation.statusDesc,
		}

	useEffect(() => {
        let isMounted = true;
		const fetch = async () => {
			if (!ee.isNew()) {
				let employmentDetails = await RemittanceDetailService.getEmploymentRemittancesDetails(employment);
				const ppHasMultipleERs = employment.participation.isMER;
				const hasNoRemittanceDetails = !employmentDetails.hasFinancialInfo();
				if (onDeleteEmployment) {
					setIsDeletable(ppHasMultipleERs && hasNoRemittanceDetails);
				}
				setIsLoading(false);
			};
		}
		fetch().then(() => {if(isMounted) setIsLoading(false);});
		return () => { isMounted = false };
	}, []);

	const setEmployment = (newEmployment) => {
		var clone = newEmployment.clone();
		//replace duplicated employment in partcipation employments array
		if (!clone.isNew()) EmploymentBusiness.validate(clone);
		if (!clone.isNew()) ParticipationBusiness.validate(clone.participation);
		clone.participation.employments.push(clone.clone());

		_setEmployment(clone);
	}

	const handleAddEvent = async (eventToAdd) => {
		handleSave();
		setNewEvent(null);		
	}

	const handleSave = async () => {
		//Historical fields trigger this function but we don't want to finalize save if new
		if(employment.isNew()) { 
			if(!handleValidate()) {
				setIsDirty(true);
			}
			setEmployment(employment);
			return;
		}

		setIsLoading(true);

		let emp = await EmploymentService.updateEmployment(employment);
		let mem = await MembershipService.update(employment.participation.membership);

		const ppToFind = employment.participation.membership.participations.find(pp => pp.no === emp.participation.no);
		const employmentToFind = ppToFind.employments.find(e => e.keyValue === emp.keyValue);
		
		ppToFind.events = emp.participation.events;
		employmentToFind.events = emp.events;
		
		finalizeSave();
	}
	
	const handleCreate = () => {
		setIsLoading(true);
		EmploymentService.createEmployment(employment, EMPLOYMENT_SOURCE.MANUAL).then((response) => {
			employment.touch();
			setEmployment(response.employment);
			setIsLoading(false);
			setWarning(response.warning);
		});
	}

	const handlePersonFound = (person) => {
		employment.participation.membership.person = person
		setIsLoading(true);
		checkExistingEmployment(person, employment.employer);
	}

	const checkExistingEmployment = (person, employer) => {
		EmploymentService.existCurrentEmployment(person.id, employer.id).then(existingEmployment => {
				setExistingEmployment(existingEmployment)
				setDisplayEmpExistMsg(!!existingEmployment);
				setIsLoading(false);
		});
	}

	const handleConfirmFound = (confirmed) => {
		if (confirmed) {
			setEmployment(existingEmployment);
		} 
		else onCancel()
		setDisplayEmpExistMsg(false);
		setExistingEmployment(false);
	}

	const handlePersonCreated = (person) => {
		employment.participation.membership.person = person;
	}
	
	const handleValidate = () => {
		const fields = EmploymentBusiness.getMandatoryFields()
        //validate flow fields
        const messages = Object.getOwnPropertyNames(fields).reduce((msgs, fieldName) => {
            const msg = fields[fieldName].msg
            if (!getSafe(employment, fieldName)) msgs[msg.key] = msg
            return msgs
        }, {})
		if (employment.person.isNew()) messages['personNotExist'] = EmploymentBusiness.messages.messages['personNotExist']
		if (existingEmployment) messages['empExist'] = EmploymentBusiness.messages.messages['empExist']

		const errorMsg = Object.values(messages).reduce((txt, msg) => txt += msg.text, '')

		return errorMsg
	}

	const handleCancel = () => {
		if (onCancel) onCancel() 
	}

	const finalizeSave = () => {
		setIsLoading(false);
		setEmployment(employment)
		addMessage(`Employment edit saved`, 'success');
	}

	const handleCloseWarning = () => {
		setWarning(null)
		if(onSave) onSave();
	}

	const handleClose = () => {
		if(onSave) onSave();
		handleCancel();
	}

	const handleDeleteEmployment = (employment) => {
		EmploymentService.deleteEmptyEmployment(employment.keyValue)
            .then((response) => {
				if (response.code === "103") {
                    addMessage(`Unable to delete employment`, "danger");
                } else {
                    onDeleteEmployment(employment);
                    addMessage("Employment deleted successfully", "success");
					if (onSave()) onSave();
                }
            });
	}

	return isLoading ? <Loading /> : 
		<>
			<Tabs initial={activeTab} onChange={(tab) => setActiveTab(tab)}>
				<Tabs.Tab name="employment" title="Employment" >
					<>
						<Form 
							className={['h-100', className].join(' ')} 
							data={employment} 
							deleteMessage="Are you sure you want to delete this employment?" 
							onDelete={isDeletable && handleDeleteEmployment}
						>
							{!readOnly && <Title {...modalTitle} onHide={handleClose}/>}
							<PersonSummary 
								person={person} 
								readOnly={readOnly} 
								allowPersonChange={employment.isNew() && person.isNew()} 
								validation={validation} 
								onSwitchPerson={handlePersonFound} 
								onNewPerson={handlePersonCreated}/>
							<FieldSet className="spacing-4 mt-2">
								{employment.isNew() && !employer && 
									<Row className='mb15'>
										<EmployerDropdown employment={employment} checkExistingEmployment={checkExistingEmployment} validateField={() => {if(!handleValidate()) setIsDirty(true)} }/>
									</Row>
								}
								<Row>
									{employment.isNew() && <EInput name="hiredDate" instance={employment} readOnly={false}
									isControlled={false}
										onChange={(eventOrValue, event) => {
											// eventOrValue.target.value example: "2024-01-15"
											const eventToAddOrUpdate = {code: 'hrd', ets: moment(eventOrValue.target.value).valueOf()};
											const existingHiredEvent = employment.getHiredEvent();
											if(existingHiredEvent) {
												employment.updateEvent(existingHiredEvent, eventToAddOrUpdate);
											} else {
												employment.addEvent(eventToAddOrUpdate);
											}
											const validateResult = handleValidate();
											if(!validateResult) setIsDirty(true);
										}}
									/>}
									<EInput name="noEmp" instance={employment} readOnly={true}/>
									{!employment.isNew() && <EInput name="joinDt" instance={employment.participation} readOnly={true}/>}
									<EInput name="firstEligibility" instance={employment.participation} readOnly={true}/>
								</Row>
								<Row>
									<EInput onChange={setEmployment} readOnlyDisplay={true} name="isN" instance={employment} onClick={() => setSelectedHistItem('isN')} readOnly={readOnly}/>
									<EInput onChange={setEmployment} readOnlyDisplay={true} name="isTP" instance={employment} onClick={() => setSelectedHistItem('isTP')} readOnly={readOnly}/>
									<EInput onChange={setEmployment} readOnlyDisplay={true} name="isCQ" instance={employment} onClick={() => setSelectedHistItem('isCQ')} readOnly={readOnly}/>
									<EInput onChange={setEmployment} readOnlyDisplay={true} name="employmentType" instance={employment} onClick={() => setSelectedHistItem('employmentType')} readOnly={readOnly}/>
									<EInput onChange={setEmployment} readOnlyDisplay={true} name="baseEarnings" instance={employment} onClick={() => setSelectedHistItem('baseEarnings')} readOnly={readOnly}/>
									<EInput onChange={setEmployment} readOnlyDisplay={true} name="workSch" instance={employment} onClick={() => setSelectedHistItem('workSch')} readOnly={readOnly}/>
								</Row>
								{!employment.isNew() &&
								<Row className='justify-content-end button-row mt20'>
									<SuperAdminGuard>
										<Button onClick={()=>setAdminRem(true)} className='btn-secondary' cn='col-4'>
											Manage Remittances
										</Button>
										{adminRem && <Modal className='modal-bg-color w-80'>
											<Title title={'Manage Remittance Details'} onHide={() => setAdminRem(false)}/>
											<RemittanceDetailsAdmin employment={employment} handleClose={() =>{ setAdminRem(false); setUniqueKey(uniqueKey+1);}}/>
										</Modal>}
									</SuperAdminGuard>
									<Button className='btn-secondary' cn='col-4' onClick={() => setNewEvent(new EmploymentEvent())}>
										Add Employment Event
									</Button>
									<Button className='btn-secondary' cn='col-4' onClick={() => setNewEvent(new ParticipationEvent())}>
										Add Participation Event
									</Button>
									{newEvent && <Modal className='w-60 modal-bg-color'>
										<EventPage event={newEvent} employment={employment} participation={employment.participation} onSave={handleAddEvent} onCancel={() =>setNewEvent(null)} eventParameters={{openEmployment: employment}}/>
									</Modal>}
								</Row>}
							</FieldSet>
							<div className='mt-3'>
								<Tabs>
									<Tabs.Tab name='ppHistory' title='Participation History'>
										<MemberParticipations 
											key={employment.isNew() ? null : employment.keyValue}
											participation={employment.participation} 
											openEmployment={employment}
											membership={employment.participation.membership} 
											onSave={finalizeSave}
											loadingContext={{get: isLoading, set: setIsLoading}}
										/>
									</Tabs.Tab>
									<Tabs.Tab name='history' title='Employment History'>
										<EmploymentHistoryList 
											key={employment.isNew() ? null: employment.keyValue}
											readOnly={readOnly} 
											employment={employment} 
											onChange={handleSave}
										/>
									</Tabs.Tab>
									<Tabs.Tab name="contributions" title="Contributions" >
										<EmploymentFinancialTable key={'contr' + uniqueKey} employment={employment} tabName="contributions" />
									</Tabs.Tab>
									<Tabs.Tab name="earnings" title="Earnings" >
										<EmploymentFinancialTable key={'earn' + uniqueKey} employment={employment} tabName="earnings" />
									</Tabs.Tab>
									<Tabs.Tab name="deemedEarnings" title="Deemed Earnings" >
										<EmploymentFinancialTable employment={employment} tabName="deemedEarnings" />
									</Tabs.Tab>
									<Tabs.Tab name='creditedService' title='Credited Service'>			
										<EmploymentFinancialTable employment={employment} tabName="creditedService" />
									</Tabs.Tab>
								</Tabs>

							</div>
						</Form>
						{employment.isNew() && 
							<div className="modal-bottom">
								<Button disabled={!isDirty} key="new-employment" onClick={handleCreate} >Save</Button>
							</div>
						}
						{selectedHistItem && <Modal id='historicalList' className='w-60 h-60 modal-bg-color'>
							<HistoricalList 
								instance={employment} 
								name={selectedHistItem} 
								onClose={()=>setSelectedHistItem(null)} 
								onCancel={handleCancel} 
								onUpdate={handleSave} 
								readOnly={readOnly}
							/>
						</Modal>}
						{displayEmpExistMsg && <Question title='This employment already exist, do you want to edit the existing employment?' onAnswer={handleConfirmFound} />}
						{warning && <Modal className='modal-bg-color'>
							<Title onClick={() => handleCloseWarning()}/>
							<div className='modal-content-body centered'>{warning}</div>
							<Row className="justify-content-center"><Button onClick={() => handleCloseWarning()}>Ok</Button></Row>
						</Modal>}
					</>
				</Tabs.Tab> 
				{ employment?.person?.id ? <Tabs.Tab name='membership' title='Membership' >
					<MemberDetails 
						personId={employment?.person?.id} 
						onCancel={onCancel} 
					/>
				</Tabs.Tab> : null }
			</Tabs>
		</>
}
export default EmploymentForm;
