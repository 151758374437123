
import { useEffect, useState } from 'react';
import { Distribution, EarningType } from '../../../entities';
import { Component, EInput } from '../../../framework/components'
import { Tabs } from "../../../framework/containers";
import { Row, FieldSet, Col } from '../../../framework/containers';
import { Button, Icon, Select } from '../../../framework/controls';
import { EmployerService } from '../../../services';


const DistributionForm = (props) => {
    
    const activeTab = props.activeTab || 'contribution';
    return activeTab === 'contribution' ? 
        <ContDistribution props={props} /> : <EarnContribution props={props} />
}

export default DistributionForm;

const DeleteButton = (props) => {
    return (
        <Button className='btn btn-sm px-2 align-self-end m-0 mr-3 bg-danger text-white' type='button' onClick={props.handleDelete.bind(this, 0)} disabled={props.disabled}>
            <Icon icon='trash' large tooltip='Remove this contribution' />
        </Button>);
}

const AddButton = (props) => {
    return(
        <Row className='d-flex justify-content-end'>
            <Button className='btn btn-sm py-0 px-2 mt-3' type='button' onClick={props.handleAdd.bind(this)}>
                <Icon icon='plus' large className="v-bottom" tooltip='Add new contribution' tooltip-left />
            </Button>
        </Row>
    );
}

const ContDistribution = ({props}) => {

    var distContOptions = Distribution.getDistributionOptions()
        .filter(option =>  (props.adjustment.type.config?.isCredit 
                && props.adjustment.type.config?.targetAccounts?.[0] === option.key) 
                || !props.adjustment.type.config?.isCredit
        );

    return (<FieldSet title='Distribution - Contribution'>
        <Row className='input-spacing-2 mt-2 d-flex align-content-center'>
            <DeleteButton handleDelete={props.handleDelete} disabled={props.distributions.length <= 1}/>
            <EInput name='ta' valueProp={props.distributions[0]?.ta ?? ''} label='Target Account' instance={props.adjustment.distributionContribution} isCustomOnChange options={distContOptions} onChange={props.handleChange.bind(this, { index: 0, field: 'ta'})} onDelete={props.handleChange.bind(this)} ccn='p-2 framed'/>
            <EInput name='am' valueProp={props.distributions[0]?.am ?? ''} label='Amount' instance={props.adjustment.distributionContribution} isCustomOnChange onChange={props.handleChange.bind(this, { index: 0, field: 'am'})} onDelete={props.handleChange.bind(this)} ccn='p-2 framed'/>
        </Row>
        {props.distributions && props.distributions.map((distribution, index) => index !== 0 ? (
            <Row key={`distribution-row-${index}`} className='input-spacing-2 mt-2 d-flex align-content-center'>
                <DeleteButton handleDelete={props.handleDelete.bind(this, index)}/>
                <EInput name='ta' noLabel valueProp={distribution.ta} instance={props.adjustment.distributionContribution} isCustomOnChange options={distContOptions} onChange={props.handleChange.bind(this, { index: index, field: 'ta'})} onDelete={props.handleChange.bind(this)} ccn='p-2 framed'/>
                <EInput name='am' noLabel valueProp={distribution.am} instance={props.adjustment.distributionContribution} isCustomOnChange onChange={props.handleChange.bind(this, { index:  index, field: 'am'})} onDelete={props.handleChange.bind(this)} ccn='p-2 framed'/>
            </Row>
        ) : <></>)}
        <AddButton handleAdd={props.handleAdd} />
    </FieldSet>)
};

const EarnContribution = ({props}) => {

    const [earningsOption, setEarningsOption] = useState([]);

    const deemedAccountOptions = [
        {key:"l", text:"LTD earnings"},
        {key:"m", text:"Maternity earnings"},
        {key:"s", text:"Self earnings"},
    ]

    const earningAccountOptions = [
        {key:"r", text:"Regular Earnings"}, 
        {key:"t", text:"One-time earnings"},
        {key:"o", text:"Overtime earnings"},
        {key:"n", text:"Non-pensionable earnings"},
        ...deemedAccountOptions
    ];

    useEffect(() => {
        let isMounted = true;

        EmployerService.get(props.adjustment.employerId).then((employer) => {
            let types = employer.earningTypes.clone();
            deemedAccountOptions.forEach(({key, text}) => types.push(new EarningType({code: key})))
            if (isMounted) setEarningsOption(EarningType.splitEarningsTypesByCategory(types));
        })
        
        return () => { isMounted = false };
    }, [props.adjustment.employerId]);

    const isInvalid = !earningsOption || earningsOption['REG']?.earningTypes?.length === 0;

    const handleCategoryChange = (value, event) => {
        const code = EarningType.categories[event].key
        const earningsType = earningsOption[code].earningTypes.length > 0 ? earningsOption[code] : undefined;
        if(earningsType){
            const earningType = earningsType.earningTypes.find(type => 
                type.isDeemedEarningType() || type.alias === type.getHayesCode()
            );
            if (earningType.isDeemedEarningType() && earningType.code === event) {
                earningType.code = `${earningType.code}1`;
            }
            props.handleChange({index: value.index, field: 'earningType'}, earningType)
        }
    }

    return (
        <FieldSet title='Distribution - Earning'>
            <Row className='input-spacing-2 mt-2 d-flex align-content-center'>
                <DeleteButton handleDelete={props.handleDelete} disabled={props.earnings.length <= 1}/>
                <Col> 
                    <Row>
                        <EInput name='earningType.category' label='Target Account' instance={props.adjustment.distributionEarning} ccn='p-2 framed'>
                            <Select options={earningAccountOptions} value={props.earnings[0].earningType?.category.codePrefix ?? ""} onChange={handleCategoryChange.bind(this, {index: 0})}/>
                            {isInvalid && <div className="text-danger">Select an employer</div>}
                        </EInput>
                        <EInput name='label' label='Label' instance={props.earnings[0].earningType ?? ''} ccn='p-2 framed' readOnly/>
                    </Row>
                    <Row>
                        <EInput name='amount' label='Amount' valueProp={props.earnings[0].amount ?? ''} instance={props.adjustment.distributionEarning} isCustomOnChange onChange={props.handleChange.bind(this, { index:  0, field: 'amount'})} onDelete={props.handleChange.bind(this)} ccn='p-2 framed'/>
                        {props.earnings[0].earningType && props.earnings[0].earningType.category.isHourly() && <EInput name='hours' label='Hours' valueProp={props.earnings[0].hours ?? ''} instance={props.adjustment.distributionEarning} isCustomOnChange onChange={props.handleChange.bind(this, { index:  0, field: 'hours'})} onDelete={props.handleChange.bind(this)} ccn='p-2 framed'/>}
                    </Row>
                </Col>
            </Row>
            {props.earnings && props.earnings.filter((earning, index) => index !== 0).map((earn, index) => (
                <Row key={`earning-row-${index}`} className='input-spacing-2 mt-2 d-flex align-content-center'>
                    <DeleteButton handleDelete={props.handleDelete}/>
                    <Col>
                        <Row>
                            <EInput name='earningType.category' label='Target Account' instance={props.adjustment.distributionEarning} ccn='p-2 framed'>
                                <Select options={earningAccountOptions} value={earn.earningType?.category.codePrefix ?? ""} onChange={handleCategoryChange.bind(this, {index: index+1})}/>
                                {isInvalid && <div className="text-danger">Select an employer</div>}
                            </EInput>
                            <EInput name='label' instance={earn.earningType ?? ""} readOnly label='Label' ccn='p-2 framed'/>
                        </Row>
                        <Row>
                            <EInput name='amount'  valueProp={earn.amount} instance={props.adjustment.distributionEarning} isCustomOnChange onChange={props.handleChange.bind(this, { index:  index+1, field: 'amount'})} ccn='p-2 framed' onDelete={props.handleChange.bind(this)}/>
                            {earn.earningType && earn.earningType.category.isHourly() && <EInput name='hours' label='Hours'  valueProp={earn.hours ?? ''} instance={props.adjustment.distributionEarning} isCustomOnChange onChange={props.handleChange.bind(this, { index:  index+1, field: 'hours'})} ccn='p-2 framed' onDelete={props.handleChange.bind(this)}/>}
                        </Row>
                    </Col>
                </Row>
                ))}
            <AddButton handleAdd={props.handleAdd} />
        </FieldSet>
    )
}


