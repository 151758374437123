import { Definition } from '../../../../framework/infra'
import { Period } from '../../../../framework/utils'
import { RemittanceService } from '../../../../services'
import { Remittance } from '../../../../entities'
import ReportLayout from '../../../../framework/components/page/ReportLayout'

export default class EmployerRemittancesSummaryReport extends ReportLayout {
    reportDesc = 'Remittances'
    entity= Remittance
    headers = [
        'period', 'employer.code', 'employer.plan.jurisdiction', 
        'importedDate', 'rates.employerContribution', 'regular', 
        'maternity', 'longTerm', 'self', 'deductions', 'erContribs', 
        'voluntary', 'solvency',  'eeAdjustments', 'erAdjustments', 
        'intAdjustments', 'solAdjustments', 'totalAdjustments', 
        'creditUsed', 'interest', 'total', 'prevBalance',
        'totalPayments', 'balance', 'payments.desc', 'payments.cmt'
    ]
    headerProps = {         
        'deductions': {width: 188},
        'erContribs': {width: 186},
        'eeAdjustments': {width: 188},
        'erAdjustments': {width: 182},
        'intAdjustments': {width: 170},
        'solAdjustments': {width: 176},
        'period': {minWidth: 92},
        'employer.code': {minWidth: 78},
        'employer.plan.jurisdiction': {minWidth: 84},
        'importedDate': {minWidth: 152},
        'rates.employerContribution': {minWidth: 72},
        'payments.desc': {title: 'Payments (Ref # | Receive Date | Amount)', minWidth: 400},
        'payments.cmt': {minWidth: 400},
    }
    filters = {}
    params = {
        from: {definition: {type: Definition.types.PERIOD, text: "From Period", options: Period.getPeriods(Period.getLaunchPeriod().dec(1), Period.getCurrentPeriod(), true)}},
        to: {definition: {type: Definition.types.PERIOD, text: "To Period", options: Period.getPeriods(Period.getLaunchPeriod().dec(1), Period.getCurrentPeriod(), true)}}
    }

    execQuery(queryParams) {
        return RemittanceService.getEmployerRemittances(this.props.params.employer.id).then(remittances => {
            const filteredRemittances = remittances.filter(rem => rem.period.isSameOrBefore(queryParams.to) && rem.period.isSameOrAfter(queryParams.from))
            return filteredRemittances.sort((a,b)=> a.period.isAfter(b.period) ? 1 : -1)
        })
    }
}