import React, { useState } from 'react';
import { Row, Title } from '../../containers';
import { ParticipationEvent } from '../../../entities';
import { EmploymentEvent } from '../../../entities/employment';
import { Button, EInput, Question } from '../../components';
import { Select } from '../../controls';
import { RefEvent } from '../../infra';
import { participationEventConfigs } from '../../../entities/membership/participation/ParticipationConfig';
import useUser from '../../../hooks/useUser';
import TextareaAutosize from "react-textarea-autosize";

const EventPage = (props) => {

    var { 
        event,
        participation, 
        employment, 
        onCancel,
        onSave,
        eventParameters, 
    } = props;
    
    const [isDeleting, setDeleting] = useState(false);
    const [draft, _setDraft] = useState(event.clone());
    const [commentValue, setCommentValue] = useState(draft?.cmt ?? "");
    const [saveWarning, setSaveWarning] = useState();
    const [isInvalid, setIsInvalid] = useState(false);
    const user = useUser();

    const isPPEvent = event instanceof ParticipationEvent;
    const instance = isPPEvent ? participation : employment;
    const isNew = event.isNew();
    const isPermanent = (event.config.isPermanent || isNew) && !(event.config.canSuperDelete && user?.isSuperAdmin); 
    const groups = participation ? ParticipationEvent.groupedMessages(participation) : [];
    const groupOptions = Object.getOwnPropertyNames(groups).sort((a,b) => a > b ? 1 : -1).map(opt => ({key: opt, text: opt, value: opt}))
    const hasDuplicateSingleEventsFromHiddenGroup = RefEvent.getSingleEventsInHiddenGroup(event, instance, participationEventConfigs)?.length > 1;

    const Event = isPPEvent ? ParticipationEvent: EmploymentEvent;
    const statusOptions = Object.values(Event.messages).map(opt => ({key: opt.key, text: opt.text, value: opt})).filter((option => {
        
        const eventOption = new Event({code: option.key});
        const isCurrentEvent = event.code === eventOption.code;
        const singleAndExists = eventOption.config.singleEvent && instance.events.find(x => x.code === eventOption.code);
        const inGroup = (draft.groupName ? eventOption.config?.groups?.includes(draft.groupName) : true );
        const isSingleEventFromHiddenGroupAndExists = RefEvent.getSingleEventsInHiddenGroup(eventOption, instance, participationEventConfigs)?.length > 0;

        return ( (!eventOption.config.isHidden && !singleAndExists && inGroup && !isSingleEventFromHiddenGroupAndExists) || isCurrentEvent );
    })).sort((a,b)=> {
        if(a.text < b.text) { return -1; }
        if(a.text > b.text) { return 1; }
        return 0;
    })
    
    //need to clone objects so that context refreshes
    const setDraft = () => {
        var copy = draft.clone();
        _setDraft(copy);
    }

    const handleCancel = () => { if (onCancel) onCancel(); }
    
    const handleConfirmDelete = (answer) => {
        if(answer) {
            instance.deleteEvent(event, eventParameters);
            if (onSave) onSave(event);
        }
        setDeleting(false);
	}

    const handlePreSave = async () => {
        var saveWarning = null;
        if (event.config.saveWarning) {
            saveWarning = await event.config.saveWarning({ oldEvent: event, newEvent: draft, instance});
        } 

        setDraft(draft);
        if(validate(draft)) {
            if (saveWarning) { 
                setSaveWarning(saveWarning);
            }
            else await handleSave();
        }
    }

    const handleSave = async (answer = true) => { 
        if(answer) {
            const isSameCode = draft.code === event.code
            draft.cmt = commentValue;
            draft.mts = Date.now();

            if(!isSameCode || isNew){
                instance.replaceEvent(event, draft, eventParameters);
            }else{
                instance.updateEvent(event, draft, eventParameters);
            }
        
            if (onSave) onSave(draft);
        } else {
            handleCancel();
        }
    }

    const handleCodeChange = (code) => {
        draft.code = code;
        setDraft(draft);
    }

    const handleGroupChange = (newGroup) => {
        if(draft.groupName !== newGroup) {
            draft.code = null;
            draft['groupName'] = newGroup;
            setDraft(draft);
        }
    }

    const validate = (ev) => {
        var invalid = false;
        if(ev.code === '' || !ev.ets) invalid = true;
        setIsInvalid(invalid)
        return !invalid;
    }

    return <>
        <Title title={(isNew ?'New ':'') + (isPPEvent ? 'Participation':'Employment') +' Event'} onHide={handleCancel}/>
        <div>
            <Row className='input-spacing-2'>
                { isNew || !isPermanent ? <>
                        {isPPEvent ? 
                            <EInput name='groupName' label="Event Type" instance={draft} cn='col-3'>
                                <Select options={groupOptions} value={draft.groupName} onChange={handleGroupChange} readOnly={false}/>
                            </EInput> 
                        : null}
                        <EInput showRequiredLabelMarker={true} name='code' label="Message" instance={draft}>
                            <Select value={draft.code} options={statusOptions} onChange={handleCodeChange} readOnly={false}/>
                        </EInput>
                    </>:
                    <EInput name='desc' instance={draft} readOnly={true}/>
                }
                <EInput guessable={true} name='effDt' instance={draft} cn='col-3'/>
            </Row>
            {isInvalid && <div className="text-danger">Invalid event, missing fields.</div>}
            <div className="">
                <label for="event-comment">Comment</label>
                <TextareaAutosize 
                    id="event-comment"
                    minRows={4} 
                    maxRows={20}
                    onChange={(e) => setCommentValue(e.target.value)} 
                    value={commentValue}
                    className="w-100 textarea" 
                /> 
            </div>
            <Row className='justify-content-end button-row mt20'>
                { (!isPermanent || hasDuplicateSingleEventsFromHiddenGroup) 
                    && <Button key='delete' onClick={() => setDeleting(true)}>Delete</Button> }
                { isDeleting && <Question title='Are you sure you want to delete this event?' onAnswer={handleConfirmDelete} />}
                { saveWarning && <Question title={saveWarning} onAnswer={handleSave} />}
                <Button key='save' onClick={handlePreSave}> {isNew? 'Add':'Save'} </Button>
            </Row>
        </div>
    </>
}
export default EventPage;