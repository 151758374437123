import React from 'react'
import { Modal }  from '../../../framework/containers'
import { EmploymentService } from '../../../services'
import ReportLayout from '../../../framework/components/page/ReportLayout'
import RefDescription from '../../../framework/infra/model/RefDescription'
import MemberDetails from '../MemberDetails'
import Report from '../../../entities/pension/member/Report'
import { ParticipationTask } from '../../../entities'
import { EmploymentBusiness, ParticipationBusiness } from '../../../business'

export default class EmployerMessageWarningReport extends ReportLayout {
    reportDesc = `Member Message/Warnings`
    entity = Report
    headers = ['employment.person.sin', 'employment.person.name', 'employment.person.dob', 'message.desc']
    headerProps = {
        'employment.person.name': {width: 400, headerFilter: true},
        'employment.person.sin': {width: 150, variant: 'link', cellClick: this.handleCellSelect.bind(this)},
        'employment.person.dob': {width: 150}
    }
    removeDuplicateRows = true
    tableSort = 'employment.person.name'
    filters = {
        'message._msgDesc': {definition: {ref: RefDescription, text: 'Message Type' }, display: 'name', nullable: true}
    }
    params = {}
    fieldsetClass='no-frame'

    getWarningList(emps) {
        const warningList = []
        emps.forEach(emp => {
            EmploymentBusiness.validate(emp)
            ParticipationBusiness.validate(emp.participation)
            const lastStatus = emp.participation.lastStatusEvent.status;
            const empWarningList = emp.combinedAllWarnings;
            ParticipationTask.alwaysShownTask().forEach(validTask => {
                const task = empWarningList._list.find(warn => warn.code === validTask.key);
                if(task) warningList.push({employment: emp, message: task});
            })

            if ((lastStatus.shouldShowTask()) && emp.combinedAllWarnings.length > 0) {
                emp.combinedAllWarnings.forEach(warning => warningList.push({employment: emp, message: warning}))
            }
        })
        return warningList
    }

    handleCellClick(cell) {
        const employment = cell.getData().employment
        return (<Modal className='h-100 w-80 modal-bg-color'>
            <MemberDetails personId={employment.person.id} activeTab={this.state.showDetails} onCancel={this.handleCancel.bind(this)}/>
        </Modal>)
    }
    execQuery() {
        return EmploymentService.getEmployerEmployments(this.props.params.employer.id).then(employments => {
            return Promise.all(employments.map(employment => {
                return EmploymentService.getMemberEmployments(employment.participation.membership.keyValue)
            })).then(() => this.getWarningList(employments))
        })
    } 
    
}
