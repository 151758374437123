import { Ref, Definition } from '../../framework/infra'

export default class Distribution extends Ref {
    get employeeReg() { return this.ta === 'e' ? this.am : 0 }
    get employeeVol() { return this.ta === 'ev' ? this.am : 0 }
    get employeeMat() { return this.ta === 'em' ? this.am : 0 }
    get employeeLtd() { return this.ta === 'el' ? this.am : 0 }
    get employeeSlf() { return this.ta === 'es' ? this.am : 0 }
    get solvency() { return this.ta === 's' ? this.am : 0 }
    get interest() { return this.ta === 'i' ? this.am : 0 }
    get employer() { return this.ta === 'er' ? this.am : 0 }

    static key = 'ta'
    static definitions = {
        ta:  { type: Definition.types.CHOICE, text: 'Target Account', options: [
            { key: 'e', text: 'Employee Contributions', value: 'e' },
            { key: 'ei', text: 'Employee Interest', value: 'ei' },
            { key: 'em', text: 'Employee Maternity', value: 'em' },
            { key: 'el', text: 'Employee LTD', value: 'el' },
            { key: 'es', text: 'Employee Self', value: 'es'  },
            { key: 'ev', text: 'Employee Voluntary', value: 'ev'  },
            { key: 'r', text: 'Employer', value: 'r'  },
            { key: 's', text: 'Solvency', value: 's'  },
            { key: 'i', text: 'Interest', value: 'i'  },
        ]},
        am: { type: Definition.types.AMOUNT, text_en: 'Amount'},
    }

    static createAllDistributions = function() {
        return Distribution.definitions['ta'].options.reduce((res, opt) => {
            res[opt.key] = new Distribution({ta: opt.key})
            return res
        }, {})
    }

    static getDistributionOptions = () => {
        return Distribution.definitions['ta'].options;
    }

    static isEmployee = (distribution) => { return ['e', 'em', 'el', 'es'].includes(distribution.ta)};
}