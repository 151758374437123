import React from 'react'
import _ from 'lodash'

import Tabulator from '../components/Tabulator'
import { Definition } from '../infra'
import { renderToString, renderNumber, renderAmountEmptyFor0, renderNumberEmptyFor0, renderStringByPattern, renderDate, renderTS, renderPercentage, renderComponent, renderWarning } from '../utils/renders'
import { formatAmount } from '../utils/formating'
import { isString, isObject, arrayFromCsv } from '../utils/helper'
import { Choice, Period } from '../components/eComp/inputs'

export default class Table extends React.Component {
    render() {
        const props = {...this.props}
        if (props.rowCollapseContent) {
            props.rowFormatter = (row) => {
                var holderEl = document.createElement("div");
                holderEl.innerHTML = renderToString(props.rowCollapseContent(row))
                holderEl.className = 'tabulator-row-collapse-content'
                row.getElement().appendChild(holderEl)
                row.getElement().lastElementChild.hidden = true
            }
        }
        if (props.columns instanceof Table.Headers) {
            props.columns = headersToCols(props.columns)
            function headersToCols(headers) {
                headers.list.forEach(header => {
                    if (header.headers) header.columns = headersToCols(header.headers)
                })
                return headers.list
            }
        } else if (isString(props.columns)) {
            props.columns = new Table.Headers(props.entity, props.columns)
        } else if (isObject(props.columns)){
             props.columns = Object.values(props.columns)
        }
        props.columns.forEach(column => {
            if (props.columnModifiers && props.columnModifiers[column.name]) {
                Object.assign(column, props.columnModifiers[column.name])
            }
        })
        
        return <Tabulator {...props} />
    }
}

    
Table.Headers = class Headers {
    constructor (entity, propNames = [], headerProps = {}) {
        this._propNames = arrayFromCsv(propNames)
        this._entity = entity
        this._propNames.forEach(propName => this.addHeader(propName, headerProps[propName]))
    }

    get list() {
        return Object.getOwnPropertyNames(this).reduce((headers, propName) => {
            if (!propName.startsWith('_')) headers.push(this[propName])
            return headers
        }, [])
    }
    addHeaders(headerNameList, headerProps = {}) {
        headerNameList.forEach(headerName => {
            this.addHeader(headerName, headerProps[headerName])
            this._propNames.push(headerName)
        })  
    }
    addHeader(headerName, headerProps) {
        this[headerName] = this.getPredifinedHeader(headerName, headerProps) || new Table.Header(this._entity, headerName, headerProps)
    }

    reset() {
        const originalCols = _.pick(this, this._originalProps)
        this.clear()
        Object.assign(this, originalCols)
    }
    
    clear() {
        return Object.getOwnPropertyNames(this).forEach(headerName => {
            if (!headerName.startsWith('_')) delete this[headerName]
        })
    }
    
    get namedHeadersList() {
        return this.list.reduce((namedHeaders, header) => {
            if (header.name) namedHeaders.push(header)
            if (header.headers) namedHeaders = namedHeaders.concat(header.headers.namedHeadersList)
            return namedHeaders
        }, [])
    }

    getPredifinedHeader(headerName, headerProps = {}) {
        var header = null
        if(headerName === '<collapse>') {
            var header = { 
                width: 50, 
                align:"center",
                headerSort: false, 
                collapsed: false,
                cellClick: (e, cell) => {
                    e.preventDefault()
                    e.stopPropagation()
                    toggleCollapseCell(cell)
                },
                headerClick: (e, column) => {
                    const collapsed = column.getDefinition().collapsed
                    column.getDefinition().collapsed = !collapsed
                    const elem = column.getElement().firstChild.firstChild.firstChild.firstChild
                    elem.className = collapsed ? 'icon-noun-dropdown-up' : 'icon-noun-dropdown'
                    column.getCells().forEach(cell => {
                        cell.setValue(collapsed)
                        toggleCollapseCell(cell)
                    })
                },
                format: () => renderComponent(<div className='icon-noun-dropdown-up'/>),
                titleFormatter: () => renderComponent(<div className='icon-noun-dropdown-up' />)
            }
        }
        return header
    }

}

function toggleCollapseCell(cell) {
    const cellValue = cell.getValue()
    cell.setValue(!cellValue)
    cell.getElement().firstChild.className = cellValue ? 'icon-noun-dropdown-up' : 'icon-noun-dropdown'
    cell.getRow().getElement().lastElementChild.hidden = cellValue
}

Table.Header = class Header { 
    constructor (entity, headerName, headerProps = {}) {
        this._entity = entity
        this._def = entity && entity.getDefinition(headerName)
        this.name = headerName
        Object.assign(this, headerProps)
       
        if (this._def) {
            this.title = this.title || this._def.text
            this.description = this.description || this._def.desc
            this.headerFilterFunc = filterSupportTree.bind(this, this._def, this.name, standardFilter)
        }
        if (!this.title) this.title = headerName

        this.setFormat()
    }

    setFormat(format) {
        const def = this._def
        if (def) {
            this.format = this.format || def.format
            if (def.isString()) {
                this.format = this.format || ((val) =>  { 
                    var text = (val && val.richText) ? val.richText[0]?.text : val;
                    text = String(text)
                    return text && text.replaceAll('\n', '<br/>') })
            } else if (def.isCurrency()) {
                const format = this.format || ((value) => formatAmount(value, this.blankZero === false ? '' : null))
                this.format = (value, inst, cell) => { 
                    if (value < 0) {
                        cell.getElement().className += ' negative-number'
                        cell.getElement().style.color = 'red'
                    } 
                    return format(value, inst, cell) 
                }
                this.align = 'right'
                this.minWidth = 120
            } else if (def.type === Definition.types.PERCENTAGE) {
                this.format = renderPercentage
                this.align = 'right'
            } else if (def.isNumeric()) {
                this.format = this.blankZero !== false ? renderNumberEmptyFor0 : renderNumber
                this.align = 'right'
            } else if (def.isBool()) {
                this.align = 'center'
                this.format = this.blankCross ? 'tick': 'tickCross'
            } else if (def.isDate()) {
                this.format = renderDate
            } else if (def.isTimestamp()) {
                this.format = renderTS
            } else if (def.isChoice()) {
                this.format = Choice.format.bind(this, def)
                this.sorter = Choice.sort.bind(this, def)
                this.headerFilterFunc = filterSupportTree.bind(this, def, this.name, Choice.filter)
            } else if (def.isPeriod()) {
                this.format = Period.format.bind(this, def)
                this.sorter = Period.sort.bind(this, def)
                this.headerFilterFunc = filterSupportTree.bind(this, def, this.name, Period.filter)
            } else if (def.hasMask()) {
                this.format = renderStringByPattern.bind(this, def.type.mask)
            } else if (def.isRefMessage()) {
                this.format = renderWarning
            }

            if (this.variant === 'link') {
                const format = this.format
                this.format = (value, inst, cell) => {
                    cell.getElement().className += ' tabulator-cell-link'
                    return format(value, inst, cell)
                } 
            }
        }
    }
}

Table.Search = class TableSearch extends React.Component {
    constructor(props) {
        super(props)
        this.state = { searchValue: '' }
    }

    render() {
        return <input value={this.state.searchValue} onChange={this.handleSearch.bind(this)} placeholder='Search...' className={['table-search', this.props.className].join(' ')}  />
    }
    
    handleSearch(event){
        const tabulator = this.props.table()
        if (!event.target.value) {
            tabulator.clearFilter()
        } else {
            const value = event.target.value.toLowerCase()
            tabulator.addFilter(data => {
                return tabulator.columnManager.columns.find(col => {
                    if(col.definition.formatter === 'tickCross') return false
                    const cellValue =  _.get(data, col.definition.field)
                    const formattedValue = (typeof col.definition.formatter === 'function') ? col.definition.formatter({getValue: function(){return cellValue}, "_cell": {row:{data:data}}} ) : ''
                    
                    return String(cellValue).toLowerCase().includes(value) || String(formattedValue).toLowerCase().includes(value)
                })
            })
            
        }
        this.setState({ searchValue: event.target.value })
    }
}

Table.addTopSum = function(columnsMap, propertyNames = '' ) {
    propertyNames.replace( / /g, '' ).split( ',' ).forEach(prop => {
        columnsMap[prop].topCalc = 'sum'
        columnsMap[prop].topCalcFormatter = renderAmountEmptyFor0
    })
}


function filterSupportTree(def, propValue, filter, filterValue, rowValue, rowData) {
    var match = filter(def, filterValue, rowValue, rowData)
    if (!match && rowData._children) {
        match = rowData._children.find(child => filterSupportTree(def, propValue, filter, filterValue, child[propValue], child) )
    }
    return match
}

function standardFilter(def, filterValue, rowValue) {
    return String(rowValue).toLowerCase().includes(String(filterValue).toLowerCase())
}
