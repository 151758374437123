import { Ref, Definition } from '../../../framework/infra'
import { moment, round } from '../../../framework/utils/helper'

import { RefundBreakdowns, RefundDistributions } from '../../pension/refund'

export default class Payout extends Ref {
    constructor (data) {
        super(data)
        this.assignElapsedDays(this.elapsedDays)
    }
    get elapsedDays() { return moment(this.issueDate).diff(moment(this.calculationDate), 'days') } 
    get overITA() { return round(this.breakdown.liTotal - this.maxLIPortion)}

    get calculationDate() { return this.__calculationDate }
    set calculationDate(date) { 
        this.__calculationDate = date 
        this.assignElapsedDays(this.elapsedDays)
    }

    get issueDate() { return this.__issueDate }
    set issueDate(date) { 
        this.__issueDate = date 
        this.assignElapsedDays(this.elapsedDays)
    }
    
   
    assignElapsedDays() { if (this.breakdown) this.breakdown.assignElapsedDays(this.elapsedDays) }

    distribute(accounts) {
        this.distribution.reset()
    
        const lockedInAccount = accounts.find(account => account.accountType.lockedIn)
        const notLockedInAccount = accounts.find(account => !account.accountType.lockedIn)
        
        if (!this.lockedIn && accounts.length > 0) {
            if (notLockedInAccount.accountType.key === 'RRSP' && accounts.length === 1) {
                if(this.breakdown.liTotal > this.maxLIPortion) {
                    this.distribution.pushNew({type: 'UTR', amount: this.maxLIPortion, account: notLockedInAccount})
                    this.distribution.pushNew({type: 'OTR', amount: this.overITA,  account: notLockedInAccount})
                } else {
                    this.distribution.pushNew({type: 'UTR', amount: this.breakdown.liTotal, account: notLockedInAccount})
                }
            }
            else this.distribution.pushNew({type: 'CR', amount: this.breakdown.liTotal, account: notLockedInAccount})
        } else if(this.breakdown.liTotal > this.maxLIPortion) {
            this.distribution.pushNew({type: 'MITA', amount: this.maxLIPortion, account: lockedInAccount})
            this.distribution.pushNew({type: 'OITA', amount: this.overITA,  account: notLockedInAccount})
        } else {
            this.distribution.pushNew({type: 'LI', amount: this.breakdown.liTotal, account: lockedInAccount})
        } 
        this.breakdown.filter(breakdown => !breakdown.type.lockedIn).forEach(breakdown => this.distribution.pushNew({type: breakdown.type.key, amount: breakdown.total, account: notLockedInAccount}))
    }
    
    static definitions = {
        breakdown: { ref: RefundBreakdowns, text: 'Breakdown' },
        distribution: { ref: RefundDistributions, text: 'Distribution' },
        issueDate: { type: Definition.types.DATE, text: 'Issue Date', text_fr: '', default: new Date().toISOString().slice(0, 10) },
        calculationDate: { type: Definition.types.DATE, text: 'Calculation Date', text_fr: '' },
        expireDate: { type: Definition.types.DATE, text: 'Expire Date', text_fr: '' },
        maxLIPortion: { type: Definition.types.AMOUNT, text: 'Max Amount as per ITA 8517', text_fr: '' },
        lockedIn: { type: Definition.types.BOOLEAN, text: 'Locked-In', text_fr: '', default: true },

        elapsedDays: { abstract: true, type: Definition.types.NUMBER, text: 'Elapsed Days' }
    }
}
// type: {type: CHOICE, text: 'Refund Types', options: [
//     { key: 'refund', text: 'Refund' },
//     { key: 'vrefund', text: 'Voluntary Refund' },
//     { key: 'drefund', text: 'Death Refund' },
//     { key: 'benefit', text: 'Additional Benefit' },
//     { key: 'litransfer', text: 'Locked-In Transfer' },
//     { key: 'dlitranfer', text: 'Death LI Transfer' },
//     { key: 'rtdc', text: 'RT DC Pension Plan' },
//     { key: 'rtdb', text: 'RT DB Pension Plan' }
// ]},