import Auth from './Auth'
import { pick, moment, isArray } from '../utils/helper'
import { Timer } from '../utils'
import pLimit from 'p-limit'
import { RefList } from './model'
import { setAPIError } from '../../hooks/useAPIError'

export default class Persistence {
    constructor(entity) {
        this.entity = entity
    }
    
    get(serviceName, id) {
        return this.callApi(serviceName, { id: id })
            .then((data) => new this.entity(data))
            .catch((err) => {
                setAPIError(err);
                console.log(err);
                throw err;
            });
    }
    getAll(serviceName) {
        return this.time(serviceName, "getAll", () =>
            this.callApi(serviceName).then((data) => {
                return data.map((item) => new this.entity(item));
            })
        ).catch((err) => {
            setAPIError(err);
            console.log(err);
            throw err;
        });
    }
    getBy(serviceName, parameters) {
        return this.time(serviceName, "getBy", () =>
            this.callApi(serviceName, parameters).then((data) => {
                if (!data || data.error) return [];
                return data.map((item) => new this.entity(item));
            })
        ).catch((err) => {
            setAPIError(err);
            console.log(err);
            throw err;
        });
    }

    getList(serviceName, ids) {
        return this.callApi(serviceName, {}, ids)
            .then((data) => data.map((item) => new this.entity(item)))
            .catch((err) => {
                setAPIError(err);
                console.log(err);
                throw err;
            });
    }

    delete(serviceName, instance) { 
        var data = instance.getData() 
        return this.callApi(serviceName, {}, data).then(returnedData => {
            if(returnedData.message) throw returnedData.message
            return instance
        }).catch(err => {
            setAPIError(err);
            console.log(err);
            throw err
        })
    }
    deleteBy(serviceName, parameters) { 
        return this.callApi(serviceName, parameters).then(() => {
            return null
        }).catch(err => {
            setAPIError(err);
            console.log(err);
            throw err
        })
    }

    /**
     * 
     * @param {*} serviceName 
     * @param {*} instance instance to update
     * @param {*} attributesToUpdate specific attributes to update
     * @param {*} additionalContentToSend additional content to send to backend (ex: old adjustment key for updateAdjustment)
     */
    update(serviceName, instance, attributesToUpdate = '', additionalContentToSend) { 
        var data = instance.getData()

        if (attributesToUpdate) data = pick(data, ['_del'].concat(this.entity.keys).concat(attributesToUpdate.split(','))) 
        data = simplifyPayload(data);
        
        if (additionalContentToSend) {
            data.additionalContentToSend = additionalContentToSend;
        }
        let params = attributesToUpdate ? { attributesToUpdate } : null;
        return this.callApi(serviceName, params, data).then(updatedData => {
            if(updatedData.message) throw updatedData.message
            if(updatedData === '') return instance;

            instance.setData(updatedData)
            if (!instance.id) instance.id = updatedData?.Attributes?.id
            instance.cts = updatedData?.Attributes?.cts ?? instance.cts;
            instance.mts = updatedData?.Attributes?.mts ?? instance.mts;
            instance.apply()
            return instance
        }).catch(err => {
            setAPIError(err);
            console.log(err);
            throw err
        });
    }

    /**
     * 
     * @param {*} serviceName 
     * @param {*} instance instance to add
     */
     add(serviceName, instance) { 
        var data = instance.getData()
        data = simplifyPayload(data);
        
        return this.callApi(serviceName, {} ,data).then(updatedData => {
            if (!instance.id) instance.id = updatedData?.Attributes?.id
            instance.apply()
            return instance
        }).catch(err => {
            setAPIError(err);
            console.log(err);
            throw err
        });
    }

    
    updateAll(serviceName, instances, attributesToUpdate = '') { 
        const limit = pLimit(50);
        return Promise.all(
            instances.map((instance) =>
                limit(() =>
                    this.update(serviceName, instance, attributesToUpdate)
                )
            )
        ).catch((err) => {
            setAPIError(err);
            console.log(err);
            throw err;
        });
    }


    callApi(serviceName, parameters = {}, content){
        var url = process.env.REACT_APP_API_INVOKE_URL + '/' + serviceName
        const params = {
            method: 'GET',
            headers: {
                Accept: '*/*',
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate'
            }
        }

        if (content) {
            params.method = 'POST'
            params.body = JSON.stringify(content)
        }
        
        return Auth.getUserToken().then(token => {
            params.headers.Authorization = token
            return fetch(`${url}?${objToQueryString(parameters)}`, params)
        }).then(response => {
            return response.json().then(content => {
                if(content.error) throw content.error
                return content
            })
            
        }).catch(error => {
            setAPIError(error);
            console.log(error);
            throw error;
        })
    }

    date(date) {
        if (!date) return moment().format('YYYY-MM-DD')
        else return moment(date).format('YYYY-MM-DD')
    }
    
    time(serviceName, methodName, promise) {
        const timer = Timer.start(serviceName, methodName)
        return promise().finally(res => {
            timer.stop()
            return res 
        })
    }
}

//recursively check on all request payload properties for a payload simplification option
function simplifyPayload(data) {
    var instance = data.getPayloadInclusions ? pick(data, [...data.getPayloadInclusions()]) : data;
    Object.keys(instance).forEach((key) => {
        if(isArray(instance[key])){
            var newObjs = []
            for (var arrayObj of instance[key]) {
                newObjs.push(arrayObj.getPayloadInclusions ? simplifyPayload(arrayObj) : arrayObj);
            }
            instance[key] = newObjs;
        }else {
            instance[key] = instance[key]?.getPayloadInclusions ? simplifyPayload(instance[key]) : instance[key];
        }
    });
    return instance;
}

function objToQueryString(parameters) {
    const keyValuePairs = [];
    for (const key in parameters) {
      keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(parameters[key]));
    }
    return keyValuePairs.join('&');
}
