import formatStringByPattern from  'format-string-by-pattern'
import moment from 'moment'
import { round } from './helper'

function formatAmount(amount, valIfZero = '0', local = 'en-CA') {
    var val = round(amount, 2)
    if (val === 0 && valIfZero !== '0') return valIfZero
    var str = val.toLocaleString(local, {currency: 'CAD', style: 'currency'})
    if (val < 0) str = '(' + str.replace('-', '') + ')'
    return str
}

function formatPercentage(value, valIfZero = '0', local = 'en-CA') {
    var val = Number(value) * 100
    return val.toLocaleString(local)
}

function formatNumber(value, precision = -1, local = 'en-CA', valIfZero = '0', valIfNaN = '') {
    var val = Number(value)
    if (isNaN(val)) return valIfNaN
    if (val === 0 && valIfZero !== '0') return valIfZero
    if (precision > -1 && val % 1 !== 0) val = val.toFixed(precision)
    return val.toLocaleString(local)
}

function formatDate(date, local = 'en-CA') {
    const dt = moment(date)
    if (!date || !dt.isValid()) return '' 
    return dt.format('DD MMM YYYY')
}

export {
    formatStringByPattern,
    formatAmount,
    formatNumber,
    formatDate,
    formatPercentage
}
