import React from 'react'
import MaskedFormControl from 'react-bootstrap-maskedinput'

export default class Masked extends React.Component {
    handleChange(event){
        this.props.instance[this.props.realName] = event.target.value
        if(this.props.onChange) this.props.onChange(event)
        this.setState({touch:true })
    }

    render() {
        const { name, required, className, placeholder, instance, value, realName, readOnly, disabled, mask } = this.props;
        return (
            <MaskedFormControl 
                key={name}
                name={name}
                value={value ?? instance[realName]}
                required={required}
                className={className}
                mask={mask}
                readOnly={readOnly}
                placeholder={placeholder}
                disabled={disabled}
                onChange={this.handleChange.bind(this)}
            />
        )
    }
}
