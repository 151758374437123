import React from 'react'
import Control from './Control'
import Select from 'react-select'

export default class MySelect extends Control {
    render() {
        const { name, value, options, searcheable, nullable, readOnly} = this.props
        const selectionOptions = options.map(opt => ({value: opt.key, label: opt.text, retValue: opt.value}))
        var selected = selectionOptions.find(opt => opt.value === value || opt.retValue === value || opt.value === value?.keyValue) || null //we return null since undefined doesn't remove previous selection

        return this.wrapper(<Select 
            className={'s-select ' + (readOnly ? ' readOnly' : '')} 
            name={name} options={selectionOptions} 
            value={selected} 
            onChange={this.handleChange.bind(this)} 
            isClearable={nullable} 
            isSearchable={searcheable} 
            isDisabled={readOnly}
            styles={{
                menuPortal: provided => ({ ...provided, zIndex: 9999 }),
              }}
            menuPortalTarget={document.body}
            menuShouldBlockScroll={true}
            />)
    }

    handleChange(option) {
        if (this.props.onChange) this.props.onChange(option && option.value, option && option.retValue)
    }
}
