import React from 'react';
import { StyleSheet } from '@react-pdf/renderer';
import { Container, Text, Row, Separator, AmountDetail } from '../../../framework/utils/pdf' 
import { texts } from './textPDF/textPDFAddendum';

const PDFAdjustment = ({ adjustment, employerAdjustments }) => {
    const styles = StyleSheet.create({
        header: { width: '100%', textAlign: 'center', fontFamily: 'Roboto-Bold', fontSize: '13', marginTop: '30'},
        subtitle: {textAlign: 'center', fontFamily: 'Roboto-Bold', fontSize: '11', marginTop: '13', marginBottom: '13'},
        label: { fontSize: '11', fontFamily: 'Roboto-Bold' }
    });

    const getEmployee = () => adjustment.keysValues.participation !== '' && 
        <Row className='mb-3'>
            <Text className='w-40' text={texts['ee-name']} style={styles.label} />
            <Text className='w-40'>{adjustment.employment.person.name}</Text>
        </Row>

    const getDistributionAmounts = () => 
        Object.values(adjustment.distributionContribution.all)
            .filter(distrib => distrib.am !== 0)
            .map(distrib => 
                <Row className='mb-3'>
                    <Text 
                        className='w-40' 
                        text={texts[`${distrib.ta}${adjustment.type.config?.isCredit ? '-remaining' : ''}`]} 
                        style={styles.label} 
                    />
                    <AmountDetail 
                        amount={adjustment.type.config?.isCredit
                            ? adjustment.leftOverCredit 
                            : distrib.am
                        } 
                    />
                </Row>
        )

    const getTotal = () =>
        <Row className='mb-3'>
            <Text className='w-40' text={texts['total-amount']} style={styles.label} />
            <AmountDetail amount={adjustment.type.config?.isCredit && !adjustment.type.config?.isStartCredit 
                ? employerAdjustments._list.filter(adj => adj.period.isSameYear(adjustment.period)).find(adj => adj.type.key === adjustment.type.key.slice(0, -1))?.total
                : adjustment.total} 
            />
        </Row>

    const getCreditLayout = () => 
        <>
            <Row className='mb-3'>
                <Text className='w-40' text={texts['adjustmentType']} style={styles.label} />
                <Text className='w-40'>{adjustment.type.config.invoiceDescription ?? adjustment.type.desc}</Text>
            </Row>
            {getEmployee()}
            {getTotal()}
            <Row>
                <Text className='w-40' text={texts['adjustmentCreditUsed']} style={styles.label} />
                <AmountDetail amount={adjustment.type.config?.isCredit && !adjustment.type.config?.isStartCredit 
                    ? employerAdjustments._list.filter(adj => adj.period.isSameYear(adjustment.period)).find(adj => adj.type.key === adjustment.type.key.slice(0, -1))?.total - adjustment.leftOverCredit
                    : adjustment.total - adjustment.leftOverCredit} 
                />
            </Row>
            <Row  className='mb-3'>
                <Text className='w-40' text={texts['adjustmentCreditUsed2']} style={styles.label} />
                <AmountDetail amount={0} blankForZero />
            </Row>
            {getDistributionAmounts()}
        </>

    const getAdjustmentLayout = () => 
        <>
            {adjustment.type.config.invoiceDescription && <Row className='mb-3'>
                <Text className='w-40' text={texts['adjustmentType']} style={styles.label} />
                <Text className='w-40'>{adjustment.type.config.invoiceDescription}</Text>
            </Row>}
            {getEmployee()}
            {getDistributionAmounts()}
            {getTotal()}
        </>

    return (
        <Container className="pl-5 ml-6" key={`addendum-adj-${adjustment.keyValue}`}>
            <Separator className='mb-3 mt-3 w-80' />
            {adjustment.type.config?.isCredit ? getCreditLayout() : getAdjustmentLayout()}
            {adjustment.cmt.length !== 0 && 
                <Row className='mb-3'>
                    <Text className='w-40' text={texts['comment']} style={styles.label}/>
                    <Text className='w-50'>{adjustment.cmt}</Text>
                </Row>
            }
        </Container>
    );
}

export default PDFAdjustment;

