import RefHistorical from './RefHistorical';
import moment from 'moment';

export default class RefEvents extends RefHistorical {

    constructor(data, _constraints) {
        super(data);
        if (_constraints) this._constraints = _constraints;
    }

    deleteEvent(oldEvent, params = {}) {
        this.remove((e) => oldEvent.ets === e.ets && oldEvent.code === e.code);
        this.finalizeEventUpdate(params);
    }

    updateEvent(oldEvent, newEvent, params = {}) {
        const event = this.find((e) => oldEvent.ets === e.ets && oldEvent.code === e.code);
        event.setData(newEvent); 
        this.finalizeEventUpdate(params);
    }

    replaceEvent(oldEvent, newEvent, params = {}) {
        this.remove((e) => oldEvent.ets === e.ets && oldEvent.code === e.code);
        this.pushEvent(newEvent, params);
        this.finalizeEventUpdate(params);
    }

    pushEvent(eventData, params){
        if (!eventData.mts) eventData.mts = moment().valueOf();
        const event = this.create(eventData)
        if (!event.config.canAdd || event.config.canAdd({...params, event: event})) { 
            if (event.config.onAdd) event.config.onAdd({...params, event: event});
            if (event.code) this.pushNew(event)
            if (event.config.next) event.config.next({...params, event: event});
            
            this.finalizeEventUpdate(params);
        }
        return event
    }

    finalizeEventUpdate(params = {}) {
        this.sortEvents();
        if (this?._constraints?.apply) this._constraints.apply({...params});
    }

    sortEvents = () => { 
        this._list = this.sort(eventSort);
    }

    static definitions = {
        _constraints: { transient: true},
    }
}

function compareEvents(eventA, eventB) {
    let result = 0;
    if(eventA.config?.priority > eventB.config?.priority){
        result = 1;
    }else if(eventA.config?.priority < eventB.config?.priority){
        result = -1;
    }else if (eventA?.mts < eventB?.mts) {
        result = -1;
    } else if (eventA?.mts > eventB?.mts) {
        result = 1;
    } else { 
        result = eventA?.rts < eventB?.rts ? -1 : (eventA?.rts === eventB?.rts ? 0 : 1);
    }
    return result;
}

function eventSort(a, b) {
    let result = 0;

    if (a.effDt === b.effDt) {
        result = compareEvents(a, b);
    } else if (a.effDt < b.effDt) {
        result = -1;
    } else {
        result = 1;
    }
    return result;
}

